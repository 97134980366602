import React from "react";
import GlobalModal from "../../components/GlobalModal";
import {TitleModal, BodyModal, ContentButtons} from "../../components/ModalConfig";
import HeadModal from "../../components/modal/HeadModal";
import {Select, Input} from "../../components/FormsConfig";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import {CitiesContext} from "../../providers/CititesProvider";
import Text from "../../components/Text";

export default EditCity => {
   return (
      <CitiesContext.Consumer>
         {({toEditCity, modalEditIsOpen, closeModallEdit, updateCity, errorsForm}) => {
            return (
               <GlobalModal modalIsOpen={modalEditIsOpen} closeModal={closeModallEdit} widthCard="40%">
                  <HeadModal closeModal={closeModallEdit}>
                     <TitleModal>{toEditCity ? "Editar" : "Agregar"} ciudad</TitleModal>
                  </HeadModal>
                  {modalEditIsOpen && (
                     <BodyModal>
                        <form
                           onSubmit={e => {
                              updateCity(e);
                           }}
                        >
                           <Input type="hidden" name="id" value={toEditCity.id} />
                           <Select name="status" defaultValue={toEditCity.status}>
                              <option value={0}>No publicada</option>
                              <option value={1}>Publicada</option>
                           </Select>
                           <Select name="country_id" defaultValue={toEditCity.country_id}>
                              <option value={1}>México</option>
                              <option value={2}>Colombia</option>
                           </Select>
                           <Input type="text" placeholder="Nombre de la ciudad" name="name" defaultValue={toEditCity.name} required />
                           {errorsForm && <Text color="crimson">{errorsForm}</Text>}
                           <ContentButtons>
                              <Button color="crimson" onClick={closeModallEdit} as="div">
                                 CERRAR
                              </Button>
                              <Button text={Colors.secondary}>GUARDAR</Button>
                           </ContentButtons>
                        </form>
                     </BodyModal>
                  )}
               </GlobalModal>
            );
         }}
      </CitiesContext.Consumer>
   );
};
