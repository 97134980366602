import React, {Component} from "react";
import logo from "../../images/logo.png";
import styled from "styled-components";
import Complete from "./Complete";
import Text from "../Text";

const MenuMobile = styled.nav`
   height: 100vh;
   width: 100.08vw;
   background-color: ${props => props.theme.primary};
   padding: 1em 0;
   position: fixed;
   top: 0;
   left: -100vw;
   z-index: 100000000000000000;
   transform: ${props => (props.isOpen ? "translate(100.08vw)" : "translate(0)")};
`;
const ImageContainer = styled.div`
   user-select: none;
   width: ${props => (props.isComplete ? "140px" : "80px")};
   margin: 0 auto;
`;
const HeaderContainer = styled.div`
   position: relative;
   width: 100%;
`;
const IconContainer = styled.div`
   position: absolute;
   top: 1em;
   right: 1em;
   color: white;
   width: 1.5em;
   height: 1.5em;
   border-radius: 50%;
   background-color: rgba(250, 250, 250, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
   & ${Text} {
      margin-block-start: 0;
      margin-block-end: 0;
   }
`;

export default class MobileMenu extends Component {
   render() {
      const {isOpen, close} = this.props;
      return (
         <MenuMobile isOpen={isOpen}>
            <HeaderContainer>
               <ImageContainer isComplete={true}> 
                  <img src={logo} width="100%" alt="freeticket" />
               </ImageContainer>
               <IconContainer onClick={close}>
                  <Text>X</Text>
               </IconContainer>
            </HeaderContainer>
            <Complete iscomplete={true} />
         </MenuMobile>
      );
   }
}
