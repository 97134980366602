import React, {useEffect, useState} from "react";
import Container from "../../components/Container";
import AppLoader from "../../components/loader/AppLoader";
import {useDispatch, useSelector} from "react-redux";
import {getLocalToken} from "../../store/actions/AuthActions";
import {getMembershipsAction, deactivateMembershipAction} from "../../store/actions/MembershipsActions";
import Table from "./Table";
import AddMembership from "./addMembership";
// import {useHistory} from "react-router-dom";

export default function MembershipsView(props) {
   const dispatch = useDispatch();
   const {token} = useSelector((state) => state.auth);
   const {fetching} = useSelector((state) => state.memberships);
   const [isOpen, setOpen] = useState(false);
   // const history = useHistory();

   useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatch(getMembershipsAction());
   }, [token, dispatch]);

   useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatch(getLocalToken());
   }, [dispatch]);

   const openModal = () => {
      setOpen(true);
   };

   const deactivateAction = ({id_user}) => {
      dispatch(deactivateMembershipAction(id_user));
   };
   // if (!token) history.replace("/");

   if (fetching) return <AppLoader isVisible={true} />;
   return (
      <Container {...props}>
         <Table openModal={openModal} deactivateAction={deactivateAction} />
         <AddMembership isOpen={isOpen} closeModal={() => setOpen(false)} />
      </Container>
   );
}
