import React, {useEffect, useContext} from "react";
import Container from "../../../components/Container";
import {Content} from "../Styles";
import HeaderView from "../../../components/toViews/HeaderView";
import {ItemHeader} from "../../../components/TableComponents";
import {AddTextButton} from "../../../components/Button";
import {LiveTicketsContext} from "../../../providers/LiveTicketsProvider";
import AppLoader from "../../../components/loader/AppLoader";
import Table from "../../../components/table/Tables";

export default function UniversalTicketsView(props) {
   const {getUniversalCodes, universalList, removeNewUniversalCode} = useContext(LiveTicketsContext);

   useEffect(() => {
      getUniversalCodes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Container {...props}>
         <Content>
            <HeaderView title="Códigos univerales" />
            <ItemHeader>
               <AddTextButton title="Crear código" onClick={() => props.history.push("/live_events/ticket_global/create")} />
            </ItemHeader>
            {universalList.isLoading ? (
               <AppLoader isVisible={true} />
            ) : (
               <Table
                  data={universalList.data}
                  columns={colsTable}
                  actions={{
                     remove: (data) => {
                        removeNewUniversalCode(data.id, data.code);
                     },
                  }}
                  noDataText="No hay tickets universales"
               />
            )}
         </Content>
      </Container>
   );
}

const colsTable = [
   {title: "id", accessor: "id"},
   {title: "Código", accessor: "code"},
   {title: "Fecha de creación", accessor: "emition_date"},
   {title: "", accessor: "", actionTitle: "Borrar", action: "remove", style: {textAlign: "center", color: "crimson", width: "100%"}},
];
