const {GET_USER_LIST, GET_USER_LIST_SUCCESS, PUT_USER_LIST} = require("../types/UserTypes");

const initialState = {
   fetchingUsers: false,
   users: [],
};

function UsersReducer(state = initialState, action) {
   switch (action.type) {
      case GET_USER_LIST:
         return {...state, ...action.payload};
      case GET_USER_LIST_SUCCESS:
         return {...state, fetchingUsers: false, ...action.payload};
      case PUT_USER_LIST:
         return {...state, ...action.payload};
      default:
         return {...state};
   }
}

export default UsersReducer;
