import React from "react";
import AppLoader from "../../components/loader/AppLoader";
import styled from "styled-components";

const BackView = styled.section`
   width: 100%;
   height: 100vh;
   background-color: rgba(10, 10, 10, 0.9);
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

   & p {
      color: white;
      text-align: center;
   }
`;
const Text = styled.p`
   font-size: 1em;
   color: white;
   text-align: center;
   margin-bottom: 0.5em;
`;
const Title = styled(Text)`
   font-size: 1.3em;
   font-weight: bold;
`;

export default function ModalLoader({type}) {
   switch (type) {
      case "load":
         return <AppLoader isVisible={true} />;
      case "change":
         return <ChangedComponent />;
      case "error":
         return <ErrorComponent />;
      case "expired":
         return <ExpiredComponent />;
      case "success":
         return null;
      default:
         return <AppLoader isVisible={true} />;
   }
}

const ErrorComponent = () => {
   return (
      <BackView>
         <Title>¡Error!</Title>
         <Text>Algo salió mal intenta más tarde</Text>
      </BackView>
   );
};

const ChangedComponent = () => {
   return (
      <BackView>
         <Title>¡Éxito!</Title>
         <Text>La contraseña se actualizó correctamente</Text>
      </BackView>
   );
};
const ExpiredComponent = () => {
   return (
      <BackView>
         <Title>¡Error!</Title>
         <Text>El código de recuperación ya caducó, por favor solicta uni nuevo</Text>
      </BackView>
   );
};
