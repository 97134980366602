import Axios from "axios";
import {GET_USER_LIST_SUCCESS, GET_USER_LIST, PUT_USER_LIST} from "../types/UserTypes";
import {getLocalToken} from "./AuthActions";

export const getUsersAction = (force = false) => (dispatch, getState) => {
   const {token} = getState().auth;
   const {users} = getState().users;

   if (users.length > 0 && !force) return false;

   if (!token) {
      getLocalToken()(dispatch, getState);
   }
   dispatch({type: GET_USER_LIST, payload: {fetchingUsers: true}});
   Axios.post("user/list_users", {token}).then((resp) => {
      if (resp.status === "success") return dispatch({type: GET_USER_LIST_SUCCESS, payload: {users: resp.data}});
      dispatch({type: GET_USER_LIST, payload: {users: [], fetchingUsers: true}});
   });
};

export const updateRollAction = (id, val) => (dispatch, getState) => {
   const {token} = getState().auth;
   const {users} = getState().users;

   Axios.post("user/change_admin", {token, id, role_id: val}).then(() => {
      let nextUsers = users.map((item) => {
         if (item.id === id) {
            return {...item, role_id: val};
         }
         return item;
      });

      dispatch({type: PUT_USER_LIST, payload: {users: [...nextUsers]}});
   });
};
