export const searchByValue = (data, val) => {
   let splitedVal = val.toLowerCase().split(" ");
   return data.filter((item) => {
      let lowerItem = JSON.stringify(item).toLowerCase();
      let x = 0;
      let hasValue = true;
      do {
         hasValue = lowerItem.indexOf(splitedVal[x]) > -1;
         x++;
         // if one of val.split(" ") no exist, breack;
      } while (x - splitedVal.length && hasValue);
      return hasValue;
   });
};
