import React, {useState, useEffect} from "react";
import DataTable from "react-data-table-component";
import Text from "../../components/Text";
import {useSelector} from "react-redux";
import {AddButton} from "../../components/Button";
import {Seeker, ItemHeader, ButtonDeactivate} from "../../components/TableComponents";
import HeaderView from "../../components/toViews/HeaderView";
import {searchByValue} from "../../helpers/Filters";

export default function Table({openModal, deactivateAction}) {
   const {membershipList} = useSelector((state) => state.memberships);
   const [filteredData, setFiltered] = useState([]);

   useEffect(() => {
      setFiltered(membershipList);
   }, [membershipList]);

   const onFilter = (val) => {
      setFiltered(searchByValue(membershipList, val));
   };

   const Columns = [
      {
         name: "Id",
         selector: "id_user",
         sortable: true,
         maxWidth: "120px",
      },
      {
         name: "Nombre",
         cell: (row) => <Text>{row.first_name + " " + row.last_name}</Text>,
         sortable: true,
      },
      {
         name: "Correo",
         selector: "email",
         sortable: true,
      },
      {
         name: "Fecha de compra",
         cell: (row) => <Text>{row.date_acquired.substr(0, 10)}</Text>,
         sortable: true,
      },
      {
         name: "Renovación",
         cell: (row) => <Text>{row.date_renew.substr(0, 10)}</Text>,
         sortable: true,
      },
      {
         name: "Tipo de pago",
         selector: "payment_type",
         sortable: true,
      },
      {
         name: "",
         cell: (row) => {
            if (row.status) {
               return (
                  <Text align="center" color="green" size="0.9em">
                     Activa
                  </Text>
               );
            }
            return (
               <Text align="center" color="grey" size="0.9em">
                  Vencida
               </Text>
            );
         },
         sortable: true,
      },
      {
         name: "",
         cell: (row) => {
            if (row.status) {
               return (
                  <ButtonDeactivate color="crimson" onClick={() => deactivateAction(row)}>
                     Desactivar
                  </ButtonDeactivate>
               );
            }
            return null;
         },
         sortable: true,
      },
   ];

   return (
      <DataTable
         title="Membresía"
         header={null}
         columns={Columns}
         data={[...filteredData]}
         subHeader
         subHeaderComponent={<HeaderActions onFilter={onFilter} openModal={openModal} />}
         pagination
         paginationComponentOptions={{rowsPerPageText: "Filas por página", rangeSeparatorText: "de"}}
         fixedHeader
         noDataComponent="No hay usuarios registrados"
         style={{display: "block", height: "80%", overflowX: "initial"}}
      />
   );
}

const HeaderActions = ({onFilter, openModal}) => (
   <HeaderView title="Membresías">
      <ItemHeader>
         <AddButton onClick={openModal} />
      </ItemHeader>
      <ItemHeader>
         <Seeker onFilter={onFilter} place="Buscar por nombre o correo" width="280px" />
      </ItemHeader>
   </HeaderView>
);
