import styled from "styled-components";
import Colors from "../constants/Colors";
import Button from "./Button";

export const TitleModal = styled.h3`
   font-size: 18px;
   font-weight: bold;
   margin: 0;
   color: ${Colors.medium};
`;
export const SubtitleModal = styled.h4`
   font-size: 16px;
   margin: 0.5em 0 1em 0;
   color: ${props => props.color || "grey"};
`;
export const BodyModal = styled.div`
   width: 100%;
   padding: 1em;
   box-sizing: border-box;
   max-height: 450px;
   overflow-y: scroll;
`;
export const ContentButtons = styled.div`
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   flex-wrap: wrap-reverse;
   & ${Button} {
      width: 10%;
      min-width: 150px;
      max-width: 200px;
      text-align: center;
   }
   @media screen and (max-width: 600px) {
      & ${Button} {
         width: 50%;
      }
   }
`;
