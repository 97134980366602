import React from "react";
import styled from "styled-components";
import {ReactComponent as CloseIcon} from "../images/plus.svg";
import Colors from "../../constants/Colors";
import device from "../../constants/device";
const HeadModals = styled.div`
   width: 100%;
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 1em;
`;
const CloseBtn = styled.button`
   padding: 0.3em;
   padding-left: 0;
   border: none;
   outline: none;
   background-color: transparent;
   cursor: pointer;
   & svg {
      transform: rotate(45deg);
      fill: ${Colors.primary};
   }
   :hover svg {
      fill: ${device.isTouch ? Colors.primary : Colors.medium};
   }
   :active {
      opacity: 0.6;
      transform: scale(0.8);
   }
`;

export default ({children, closeModal}) => {
   return (
      <HeadModals>
         {children}
         <CloseBtn
            onClick={() => {
               closeModal();
            }}
         >
            <CloseIcon width="20px" height="20px" />
         </CloseBtn>
      </HeadModals>
   );
};
