import React from "react";
import styled from "styled-components";
import {Input} from "./FormsConfig";
import Colors from "../constants/Colors";
import Button, {ResetButton} from "./Button";

const TableItem = styled.div`
   font-size: 1em;
   padding: 0.2em;
   display: flex;
   align-items: center;
   height: 100%;
`;

export const TableAction = styled(Button)`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   background: white;
   border: none;
   outline: none;
   & .icon {
      fill: ${(props) => props.iconColor || Colors.primary};
   }
   margin: ${(props) => props.margin || ""};
   margin: ${(props) => props.padding || ""};
`;

export const ActionButton = styled(ResetButton)`
   margin: 0 0.3em;
   background: transparent;
   & .icon {
      fill: ${(props) => props.iconColor || "red"};
   }
`;

const InputSearch = styled(Input)`
   width: ${(props) => props.width || "200px"};
   margin-bottom: 0;
`;
export const Seeker = ({onFilter, place, width}) => (
   <InputSearch
      type="search"
      role="search"
      placeholder={place || "Buscar"}
      onChange={(e) => onFilter(e.target.value)}
      id="searchInput"
      defaultValue=""
      width={width}
   />
);
export const ItemHeader = styled.div`
   margin-left: 1em;
`;

export default TableItem;

export const ButtonDeactivate = styled.button`
   color: ${(props) => props.color};
   background-color: transparent;
   border: none;
   outline: none;
   cursor: pointer;
   border: 1px solid ${(props) => props.color};
   margin: 0;
   padding: 0.5em 1em;
   border-radius: 0.3em;
`;
