/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState, useEffect, useContext} from "react";
import {ReactComponent as CalendarIcon} from "../../../icons/calendar.svg";
import {ReactComponent as ClockIcon} from "../../../icons/clock.svg";
import Color from "../../../constants/Colors";
import {eventOnTime} from "../helpers";
import {EventStyled, EventImg, EventInfoBox, InfoDate, ActionsEvent, EventButton} from "./EventStyles";
import {LiveEventsContext} from "../../../providers/LiveEventsProvider";

export default function EventComponent({data}) {
   const {disableEvent} = useContext(LiveEventsContext);
   const [eventType, setType] = useState("");
   const ElementRef = useRef(null);

   useEffect(() => {
      setType(eventOnTime(data.date_event, data.time_start, data.time_finish, data.active));
      // eventOnTime(data.date_event, data.time_start, data.time_finish, data.active)
   }, []);

   return (
      <EventStyled ref={ElementRef} className={`${eventType} ${data.visibility ? "" : "ghost"}`}>
         <EventImg className={`${eventType}  imgEvent`}>
            <img src={"http://freeticket.trebolbit.com/" + data.preview_img} alt={data.name} />
         </EventImg>

         <EventInfoBox>
            <InfoDate>
               <div className="section">
                  <CalendarIcon fill={Color.primary} width="1em" height="1em" className="IconDate" />
                  <p className="infoDate">{data.date_event}</p>
               </div>
               <div className="section">
                  <ClockIcon fill={Color.primary} width="1em" height="1em" className="IconDate" />
                  <p className="infoDate">{data.time_start}</p>
               </div>
            </InfoDate>
            <p className="titleEvent">{data.title}</p>
            <ActionsEvent>
               {data.visibility ? (
                  <EventButton
                     color="firebrick"
                     onClick={() => {
                        disableEvent(data.id, "future");
                     }}
                     as="button"
                  >
                     Ocultar
                  </EventButton>
               ) : (
                  <EventButton
                     color="green"
                     onClick={() => {
                        disableEvent(data.id, "future");
                     }}
                     as="button"
                  >
                     Activar
                  </EventButton>
               )}
               <EventButton color="cornflowerblue" to={`/live_events/edit/${data.id}`}>
                  Editar
               </EventButton>
               <EventButton color="cornflowerblue" to={`/live_events/create_ticket/${data.id}`}>
                  Crear tickets
               </EventButton>
            </ActionsEvent>
         </EventInfoBox>
      </EventStyled>
   );
}

EventComponent.defaultProps = {
   data: {
      idEvent: 1,
      eventName: "Nombre del evento",
      description: "Nombre del evento",
      // img: require("../../assets/img/event_img.jpg"),
   },
};

/* {data.active && <div className="markersType isOnlineBar">EN VIVO</div>}
            <div className="markersType pastEvent">&#10006; Evento pasado</div>
            <div className="markersType next">Hoy</div> */
