import React from "react";
import GlobalModal from "../../components/GlobalModal";
import HeadModal from "../../components/modal/HeadModal";
import {TitleModal, BodyModal} from "../../components/ModalConfig";
import {Input} from "../../components/FormsConfig";
import Button from "../../components/Button";
import {createMembershipTicketAction} from "../../store/actions/MembershipsActions";
import {useDispatch} from "react-redux";

export default function AddCode({isOpen, closeModal}) {
   const dispatch = useDispatch();

   const onCreatePress = (e) => {
      e.preventDefault();
      let code = e.target.code.value;
      dispatch(createMembershipTicketAction(code));
      closeModal();
   };

   return (
      <GlobalModal modalIsOpen={isOpen} closeModal={closeModal} widthCard="30%">
         <HeadModal closeModal={closeModal}>
            <TitleModal>Crear código de membresía</TitleModal>
         </HeadModal>
         <BodyModal>
            <form onSubmit={onCreatePress}>
               <Input placeholder="Código" name="code" />
               <Button>Crear</Button>
            </form>
         </BodyModal>
      </GlobalModal>
   );
}
