import React, {useEffect, useState} from "react";
import Container from "../../components/Container";
import {useDispatch, useSelector} from "react-redux";
import {getMembershipCodeDetail, getMembershipTicketsAction} from "../../store/actions/MembershipsActions";
import {useParams, useHistory} from "react-router-dom";
import {getLocalToken} from "../../store/actions/AuthActions";
import Table from "./Table";

export default function MembershipCodeDetail(props) {
   const {token} = useSelector((state) => state.auth);
   const {codes} = useSelector((state) => state.memberships);
   const dispatch = useDispatch();
   const {id_ticket} = useParams();
   const [currentCode, setCode] = useState("");
   const history = useHistory();

   useEffect(() => {
      // get token from local storage
      dispatch(getLocalToken());
   }, [dispatch]);

   useEffect(() => {
      // get list of code
      dispatch(getMembershipCodeDetail(id_ticket));
   }, [id_ticket, dispatch, token]);

   useEffect(() => {
      if (codes.length >= 1) {
         // search code data
         let currentCode = codes.find((item) => Number(item.id) === Number(id_ticket));
         if (!currentCode) {
            history.replace("/membresias/codes/");
         }
         setCode(currentCode);
      } else {
         // get all codes list
         dispatch(getMembershipTicketsAction());
      }
      return () => {
         setCode("");
      };
   }, [codes, id_ticket, dispatch, history]);

   if (!token) history.replace("/");

   return (
      <Container {...props}>
         <Table codeName={currentCode?.code ?? ""} />
      </Container>
   );
}
