export const apiUrl = "https://freeticket.trebolbit.com/api/";

export const updateToken = () => {
   let promise = new Promise((resolve, reject) => {
      setTimeout(() => {
         const newToken = "Token_actualiza sdo";
         localStorage.setItem("token", newToken);
         resolve(newToken);
      }, 1000);
   });
   return promise;
};
export const getToken = () => {
   let promise = new Promise((resolve, reject) => {
      const newToken = sessionStorage.getItem("token");
      resolve(newToken);
   });
   return promise;
};

export const LoginService = data => {
   const service = `${apiUrl}user/login`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const RegisterService = data => {
   const service = `${apiUrl}user/register`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const userDataService = data => {
   const service = `${apiUrl}user/profile`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const listUserService = data => {
   const service = `${apiUrl}user/list_users`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const doAdmin = data => {
   // user/change_admin
   const service = `${apiUrl}user/change_admin`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const recoverService = data => {
   const service = `${apiUrl}user/recover_password`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

// county and city
export const getCountriesService = () => {
   const service = `${apiUrl}country/list`;
   return fetch(service, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const getCitiesService = () => {
   const service = `${apiUrl}city/list`;
   return fetch(service, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
      },
   });
};
// intereses
export const getInterestService = data => {
   const service = `${apiUrl}interest/list`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const updateInterestService = data => {
   const service = `${apiUrl}interest/update`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const deleteInterestService = data => {
   const service = `${apiUrl}interest/delete`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const postInterestService = data => {
   const service = `${apiUrl}interest/create`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

// ciudaddes

export const postCityService = data => {
   const service = `${apiUrl}city/create`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const deleteCityService = data => {
   const service = `${apiUrl}city/delete`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const updateCityService = data => {
   const service = `${apiUrl}city//update`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
// eventos
export const getEventsListService = () => {
   const service = `${apiUrl}event/list`;
   return fetch(service, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const getEventInfoService = data => {
   const service = `${apiUrl}event/info`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const postEventService = data => {
   const service = `${apiUrl}event/create`;
   const options = {
      method: "POST",
      //mode: "no-cors",
      body: data,
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };
   delete options.headers["Content-Type"];
   return fetch(service, options);
};
export const updateEventService = data => {
   const service = `${apiUrl}event/update`;
   const options = {
      method: "POST",
      //mode: "no-cors",
      body: data,
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };
   delete options.headers["Content-Type"];
   return fetch(service, options);
};

export const membershipListService = data => {
   const service = `${apiUrl}membership/list`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const findPay = data => {
   // /membership/find_payment
   const service = `${apiUrl}membership/find_payment`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const membershipInfoService = data => {
   // membership/info
   const service = `${apiUrl}membership/info`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

export const membershipUpdateService = data => {
   // membership/info
   const service = `${apiUrl}membership/info`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

export const membershipCreateService = data => {
   // membership/info
   const service = `${apiUrl}membership/create`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

// tikets
export const eventTicketsService = data => {
   // ticket/event_ticket_list
   const service = `${apiUrl}ticket/event_ticket_list`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const userTicketsService = data => {
   // /ticket/user_ticket_list
   const service = `${apiUrl}ticket/user_ticket_list`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

export const createGiftTickets = data => {
   // free ticket
   const service = `${apiUrl}event/create_gift_tickets`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const uploadCSVService = data => {
   // upload  xml csv
   const service = `${apiUrl}event/upload_csv`;
   const options = {
      method: "POST",
      //mode: "no-cors",
      body: data,
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };
   delete options.headers["Content-Type"];
   return fetch(service, options);
};
export const scandQRCode = data => {
   const service = `${apiUrl}ticket/access`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

export const sendNotification = data => {
   const service = `${apiUrl}user/send_notification`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
