import React from "react";
import {Input} from "../../../components/FormsConfig";
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import styled from "styled-components";

function FormSend({sendData}) {
   const processData = e => {
      e.preventDefault();
      const code = e.target.code.value;
      sendData(code);
   };

   return (
      <Content>
         <Text>Ingresar código manualmente: </Text>
         <Text color="grey">Debes ingresar el código que aparece bajo el QR o el que llegó al email del usuario.</Text>
         <form onSubmit={e => processData(e)}>
            <Input name="code" placeholder="Código" />
            <Button margin="0 auto">Enviar código</Button>
         </form>
      </Content>
   );
}

export default FormSend;

const Content = styled.div`
   padding: 1em 0;
`;
