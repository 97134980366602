const {GET_TOKEN} = require("../types/AuthTypes");

const initialState = {
   fetching: false,
   token: "",
};

function AuthReducer(state = initialState, action) {
   switch (action.type) {
      case GET_TOKEN:
         return {...state, ...action.payload};
      default:
         return state;
   }
}

export default AuthReducer;
