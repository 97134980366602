import React from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import "./loader.css";

const LoaderView = styled.section`
   width: 100%;
   height: 100vh;
   background-color: rgba(10, 10, 10, 0.9);
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: center;

   & p {
      color: white;
      text-align: center;
   }
`;

const AppLoader = ({isVisible}) => {
   if (!isVisible) return null;
   return (
      <LoaderView>
         <div className="preloader">
            <div className="loader__figure"></div>
         </div>
      </LoaderView>
   );
};

export default AppLoader;

AppLoader.defaultProps = {
   isVisible: false,
};
AppLoader.propTypes = {
   isVisible: propTypes.bool,
};
