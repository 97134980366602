import React, {useEffect, useState} from "react";
import styled from "styled-components";
import GlobalModal from "../../components/GlobalModal";
import HeadModal from "../../components/modal/HeadModal";
import {TitleModal, BodyModal, SubtitleModal} from "../../components/ModalConfig";
import {Input} from "../../components/FormsConfig";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const GridStyled = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
`;

const ColStyled = styled.div`
   width: ${(props) => props.width || "48%"};
   margin: 0;
`;

export default function ShowUser({isOpen, closeModal}) {
   const {users} = useSelector((state) => state.users);
   const location = useLocation();
   const userId = location.search;
   const [user, setUser] = useState({});

   useEffect(() => {
      let nUser = users.find((item) => Number(item.id) === Number(userId.split("=")[1]));
      setUser(nUser);
   }, [userId, users]);

   if (!user) {
      console.log("NOT FOUN");
      return null;
   }

   return (
      <GlobalModal closeModal={closeModal} modalIsOpen={isOpen} widthCard="45%">
         <HeadModal closeModal={closeModal}>
            <TitleModal>Datos de usuario</TitleModal>
         </HeadModal>
         <BodyModal>
            {/* Datos bàsicos */}
            <SubtitleModal>Datos generales</SubtitleModal>
            <GridStyled>
               <ColStyled width="30%">
                  <Input as="div">
                     <span>{user.first_name}</span>
                  </Input>
               </ColStyled>
               <ColStyled>
                  <Input as="div">
                     <span>{user.last_name}</span>
                  </Input>
               </ColStyled>
               <ColStyled width="13%" />
            </GridStyled>
            <GridStyled>
               <ColStyled>
                  <Input type="text" defaultValue={user.email} readOnly />
               </ColStyled>

               <ColStyled>
                  <Input type="text" defaultValue={user.phone} readOnly />
               </ColStyled>
            </GridStyled>
            <GridStyled>
               <ColStyled>
                  <Input type="text" defaultValue={user.genre} readOnly />
               </ColStyled>

               <ColStyled>
                  <Input type="text" defaultValue={user.birthdate} readOnly />
               </ColStyled>
            </GridStyled>

            <SubtitleModal>Datos de ubicación</SubtitleModal>
            <GridStyled>
               <ColStyled width="30%">
                  <Input type="text" defaultValue={user.country_name} readOnly />
               </ColStyled>
               <ColStyled width="30%">
                  <Input type="text" defaultValue={user.city_name} readOnly />
               </ColStyled>
               <ColStyled width="30%" />
            </GridStyled>
            {/* Datos de registro */}
         </BodyModal>
      </GlobalModal>
   );
}
