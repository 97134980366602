import React, {useState, useContext} from "react";
import Container from "../../../components/Container";
import {Content} from "../Styles";
import HeaderView from "../../../components/toViews/HeaderView";
import {Label, Input} from "../../../components/FormsConfig";
import Button from "../../../components/Button";
import {RowDiv, ColDiv} from "../../events/views/editComponents/EditStyled";
import {universalTicketCreateService} from "../../../services/LiveServices";
import {getToken} from "../../../helpers/Services";
import {useHistory} from "react-router-dom";
import {LiveTicketsContext} from "../../../providers/LiveTicketsProvider";

export default function UniveralTicket(props) {
   const {addNewUniversalCode} = useContext(LiveTicketsContext);
   const [successCode, setSuccess] = useState("");
   let history = useHistory();

   const createTickets = (e) => {
      e.preventDefault();
      let code = e.target.code.value;
      getToken().then((token) => {
         universalTicketCreateService({code, token})
            .then((res) => res.json())
            .then((resp) => {
               const {status, data} = resp;
               if (status === "error") throw new Error();
               addNewUniversalCode(data);
               setSuccess(`${code} se creó correctamente`);
            })
            .catch((e) => {
               console.log(e);
            });
      });
   };
   return (
      <Container {...props}>
         <Content>
            <HeaderView title="Crear tickets para todos los eventos" />
            <Label>Ingresa el código que quieres usar</Label>
            <RowDiv>
               {successCode ? (
                  <ColDiv colmd={4} colsm={4}>
                     <Label>{successCode}</Label>
                     <Button
                        onClick={() => {
                           setSuccess("");
                        }}
                     >
                        Crear nuevo
                     </Button>
                     <Button
                        onClick={() => {
                           history.goBack();
                        }}
                     >
                        Regresar
                     </Button>
                  </ColDiv>
               ) : (
                  <ColDiv colmd={4} colsm={4}>
                     <form onSubmit={createTickets}>
                        <Input type="text" placeholder="Código de ticket" name="code" />
                        <Button>Crear</Button>
                     </form>
                  </ColDiv>
               )}
            </RowDiv>
         </Content>
      </Container>
   );
}
