import React, { Component } from "react";
// import QrReader from "react-qr-reader";
import QrReader from "react-qr-scanner";
import styled from "styled-components";
import Text from "../../components/Text";
// import Colors from "../../constants/Colors";
import Container from "../../components/Container";
import FormSend from "./components/FormSend";
import Success, { Errors } from "./components/Response";
import { getToken, scandQRCode } from "../../helpers/Services";
import Button from "../../components/Button";
import Color from "../../constants/Colors";

export default class ScanView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 100,
      // result: "",
      id_event: this.props.match.params.id,
    };

    this.handleScan = this.handleScan.bind(this);
  }
  handleScan(data) {
    if (!data) return false;
    const { result } = this.state;
    if (result) return false;
    this.setState({
      result: data,
    });
    this.sendData(data);
  }
  handleError(err) {
    console.error("ee", err);
  }
  sendData = (code) => {
    this.setState({
      result: code,
    });
    getToken().then((token) => {
      const toSend = {
        code,
        token,
      };
      scandQRCode(toSend)
        .then((res) => res.json())
        .then((resp) => {
          console.log(resp);
          const { status } = resp;
          try {
            if (status === "error") throw new Error();
            this.setState({
              answered: { status: true },
            });
          } catch {
            this.setState({
              answered: { status: false },
            });
          }
        })
        .catch(() => {
          console.log("erro de red");
        });
    });
  };
  again = () => {
    this.setState({
      answered: undefined,
      result: undefined,
    });
  };
  openImageDialog() {
    this.refs.qrReader1.openImageDialog();
  }
  render() {
    const previewStyle = {
      // height: "320px",
      width: "50",
      height: 50,
      display: "flex",
      "justify-content": "center",
      opacity: 0,
    };

    const { answered, delay, id_event, result } = this.state;
    return (
      <Container {...this.props} showBack={true}>
        <Content>
          <View>
            {answered ? (
              <>
                {!answered.status ? (
                  <Errors
                    code={result}
                    id_event={id_event}
                    again={this.again}
                    goBack={() => {
                      this.props.history.replace("/checadores");
                    }}
                  />
                ) : (
                  <Success
                    code={result}
                    id_event={id_event}
                    again={this.again}
                    goBack={() => {
                      this.props.history.replace("/checadores");
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <Text>Escanear código</Text>
                <TextContainer>
                  <Text color="grey">
                    Instrucciones: Escane el código QR del ticket del usuario,
                    su celular debe tener la pantalla con el brillo máximo. En
                    caso de no poner escanear puedes ingresar el código.
                  </Text>
                </TextContainer>
                <div className="qrContainer">
                  <QrReader
                    ref="qrReader1"
                    delay={delay}
                    style={previewStyle}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    legacyMode={true}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1em",
                  }}
                >
                  <Button
                    text={Color.secondary}
                    color={Color.primary}
                    style={{ letterSpacing: "3px" }}
                    type="button"
                    onClick={this.openImageDialog.bind(this)}
                  >
                    Scanear imagen
                  </Button>
                </div>
                <FormSend sendData={this.sendData} />
              </>
            )}
          </View>
        </Content>
      </Container>
    );
  }
}

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: white;
`;
const View = styled.div`
  width: 100%;
  padding: 1em;
  background: white;

  @media screen and (min-width: 600px) {
    width: 50%;
    max-width: 400px;
    & .qrContainer {
      position: relative;
      width: 300px;
    }
  }
`;

const TextContainer = styled.div`
  margin-top: 0.5em;
  margin-bottom: 1em;
`;
