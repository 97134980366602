import React from "react";
import Logo from "../images/logo.png";
import {ReactComponent as Lock} from "../icons/lock.svg";
import Text from "../components/Text";
import Button from "../components/Button";
import Colors from "../constants/Colors";
import {Input} from "../components/FormsConfig";
import {GlobalContext} from "../providers/GlobalProvider";
import {LoginView, LoginCard, FormContainer} from "./styles";

const Login = (props) => {
   return (
      <GlobalContext.Consumer>
         {({logIn, loginError}) => (
            <LoginView>
               <LoginCard marginAction="10px 1px 3px 0" visible={true}>
                  <div className="logoContainer">
                     <img src={Logo} width="40%" alt="freeticket" />
                  </div>
                  <FormContainer
                     onSubmit={(e) => {
                        e.preventDefault()
                        logIn(e);
                     }}
                  >
                     <Input type="email" name="user" border="white" color="white" placeholder="Usuario" required />
                     <Input type="password" name="password" border="white" color="white" placeholder="Contraseña" required />
                     {loginError && (
                        <Text color="crimson" size="0.8em">
                           {loginError}
                        </Text>
                     )}
                     <div
                        className="actions"
                        onClick={() => {
                           props.history.push("recuperar");
                        }}
                     >
                        <Lock
                           width="15px"
                           height="15px"
                           fill="red"
                           className="icon"
                           style={{
                              marginRight: "0.5em",
                           }}
                        />
                        <Text color="white" size="0.8em">
                           Recuperar contraseña
                        </Text>
                     </div>
                     <Button color={Colors.secondary} text={Colors.primary}>
                        Iniciar sesión
                     </Button>
                  </FormContainer>
               </LoginCard>
            </LoginView>
         )}
      </GlobalContext.Consumer>
   );
};

export default Login;
