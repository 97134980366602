export const doubleNumber = num => {
   if (num > 9) return num;
   return `0${num}`;
};

export const sortMap = (unordered, key) => {
   unordered.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
   });
   return unordered;
};
