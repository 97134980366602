import React from "react";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import styled from "styled-components";
import Colors from "../../../constants/Colors";

export default function Success({goBack, again, code}) {
   return (
      <Content>
         <Text color="green">¡Código válido!</Text>
         <Text color="transparent" size="0.5em">
            código
         </Text>
         <Text>{`El código: ${code}, esta registrado en este evento`}</Text>
         <Button onClick={again} margin="1em 0">
            Ingresar un nuevo código
         </Button>
         <Button color={Colors.secondary} text={Colors.primary} onClick={goBack} margin="1em 0">
            Ir a eventos
         </Button>
      </Content>
   );
}
export function Errors({goBack, again, code}) {
   return (
      <Content>
         <Text color="crimson">¡Error en el código ingresado!</Text>
         <Text color="transparent" size="0.5em">
            código
         </Text>
         <Text color="crimson">{`Este evento no admite el código ${code} registrado o ya fue utilizado.`}</Text>
         <Button onClick={again} margin="1em 0">
            Intentar nuevamente
         </Button>
         <Button color={Colors.secondary} text={Colors.primary} onClick={goBack} margin="1em 0">
            Ir a eventos
         </Button>
      </Content>
   );
}

const Content = styled.div`
   display: flex;
   justify-conten: center;
   align-items: center;
   flex-direction: column;
   & p {
      text-align: center;
   }
`;
