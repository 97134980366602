import styled from "styled-components";

export const ViewTableStyled = styled.div`
   height: 100%;
   // display: grid;
   grid-template-columns: repeat(6, 1fr);
   grid-template-rows: repeat(10, 1fr);
   grid-column-gap: 0px;
   grid-row-gap: 0px;
   margin-bottom: 2em;
`;

export const HeadViewTable = styled.div`
   grid-area: 1 / 1 / 2 / 7;
   display: grid;
   grid-template-columns: repeat(10, 1fr);
   grid-template-rows: repeat(2, 1fr);
   grid-column-gap: 0px;
   grid-row-gap: 0px;

   .length {
      grid-area: 1 / 1 / 3 / 4;
      display: flex;
      align-items: center;

      font-weight: 300;

      input {
         padding: 0.2em 0.5em;
         margin: 0 0.5em;
         display: block;
         border: 1px solid grey;
         border-radius: 5px;
         font-weight: 400;
         width: 55px;
      }
   }
   .searcher {
      grid-area: 1 / 8 / 3 / 11;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: flex-end;

      input {
         width: 200px;
         margin-left: 0.5em;
         flex-wrap: nowrap;
         font-weight: 300;
      }
   }
`;
export const BodyViewTable = styled.div`
   grid-area: 2 / 1 / 10 / 7;
`;
export const FooterViewTable = styled.div`
   grid-area: 10 / 1 / 11 / 7;
`;

// TableStyled

export const TableStyled = styled.table`
   width: 100%;
   font-weight: 200;
`;

export const THeadStyled = styled.thead`
   .normal {
      text-align: left;
      user-select: none;
      border-bottom: 2px solid #69799e;

      > div {
         width: 90%;
         display: flex;
         justify-content: space-between;
         aling-items: center;
         padding: 0 0.3em;
         cursor: pointer;
      }
   }
   .ghost {
      font-size: 9px;
      text-align: center;
   }
   .idCol {
      min-width: 2.5em;
   }
   .actionBtn {
      width: 1.3em;
   }
`;

export const ThStyled = styled.th`
   padding: 0.5em 0;
   font-weight: 400;
   text-transform: uppercase;
   width: ${props => props.tdWidth};
   max-width: ${props => props.tdMax};
   &.clean {
      background-color: white;
      opacity: 0;
   }
`;

export const TBodyStyled = styled.tbody`
   & tr:nth-child(odd) {
      background: #e9ecf5;
   }
   .actionBtn {
      width: 1.3em;
   }
`;

export const NoDataComponent = styled.div`
   padding: 0.5em;
   font-size: 1.2em;
   text-align: center;
   text-transform: uppercase;
   user-select: none;
   opacity: 0.5em;
   color: ${props => props.theme.back || "red"};
`;

export const TdStyled = styled.td`
   padding: 0.5em;
   border-spacing: 0;
   width: ${props => props.tdWidth};
   max-width: ${props => props.tdMax};

   .actionable {
      cursor: pointer;
   }
   &.clean {
      background: white;
      opacity: 0;
   }
`;

export const DivItem = styled.div`
   white-space: nowrap;
   text-overflow: ellipsis;
   width: ${props => props.tdWidth};
   max-width: ${props => props.tdMax};
   overflow: hidden;
   display: inline-block;
`;
