export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS";
export const GET_MEMBERSHIPS_SUCCESS = "GET_MEMBERSHIPS_SUCCESS";

export const UPDATE_MEMBERSHIP = "UPDATE_MEMBERSHIP";
export const UPDATE_MEMBERSHIP_SUCCESS = "UPDATE_MEMBERSHIP_SUCCESS";

export const GET_MEMBERSHIP_CODES = "GET_MEMBERSHIP_CODES";
export const GET_MEMBERSHIP_CODES_SUCCESS = "GET_MEMBERSHIP_CODES_SUCCESS";

export const GET_MEMBERSHIP_CODE = "GET_MEMBERSHIP_CODE";
export const GET_MEMBERSHIP_CODE_SUCCESS = "GET_MEMBERSHIP_CODE_SUCCESS";
