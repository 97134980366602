/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef} from "react";
import {ReactComponent as ClockIcon} from "../../../icons/clock.svg";
import Color from "../../../constants/Colors";
import {EventStyled, EventImg, EventInfoBox, InfoDate, ActionsEvent, EventButton} from "./EventStyles";

export default function PastEvent({data, type}) {
   const ElementRef = useRef(null);

   return (
      <EventStyled ref={ElementRef}>
         <EventImg className={`imgEvent`}>
            <img src={"http://freeticket.trebolbit.com/" + data.preview_img} alt={data.name} />
         </EventImg>

         <EventInfoBox>
            <InfoDate>
               <div className="section">
                  <ClockIcon fill={Color.primary} width="1em" height="1em" className="IconDate" />
                  <p className="infoDate">{data.time_start}</p>
               </div>
            </InfoDate>
            <p className="titleEvent">{data.title}</p>
            <ActionsEvent>
               <EventButton color="cornflowerblue" to={`/live_events/edit/${data.id}`}>
                  Editar
               </EventButton>
               <EventButton color={Color.primary} to={`/live_events/info/${data.id}`}>
                  Info
               </EventButton>
               <EventButton color="cornflowerblue" to={`/live_events/create_ticket/${data.id}`}>
                  Crear tickets
               </EventButton>
               <EventButton color="fuchsia" to={`/live_events/attendance/${data.id}`}>
                  Lista de asistencia
               </EventButton>
            </ActionsEvent>
         </EventInfoBox>
      </EventStyled>
   );
}

PastEvent.defaultProps = {
   data: {
      idEvent: 1,
      eventName: "Nombre del evento",
      description: "Nombre del evento",
   },
};
