import {combineReducers} from "redux";
import MembershipReducer from "./MembershipReducer";
import AuthReducer from "./AuthReducer";
import UsersReducer from "./UsersReducer";

let rootReducer = combineReducers({
   auth: AuthReducer,
   memberships: MembershipReducer,
   users: UsersReducer,
});

export default rootReducer;
