import React, {Component} from "react";
import {getToken, getCitiesService, postCityService, deleteCityService, updateCityService} from "../helpers/Services";

export const CitiesContext = React.createContext();
export default class CitiesProvider extends Component {
   constructor(props) {
      super(props);
      this.state = {
         citiesData: [],
         // edit
         modalEditIsOpen: false,
         closeModallEdit: this.closeModallEdit,
         openModalEdit: this.openModalEdit,
         toEditCity: [],
         updateCity: this.updateCity,
         // add
         addCity: this.addCity,
         closeModalAdd: this.closeModalAdd,
         modalAddIsOpen: false,
         openModalAdd: this.openModalAdd,
         // delete
         deleteCity: this.deleteCity,
         // gral
         errorsForm: "",
         dataDelete: {},
         confirmIsOpen: false,
         closeConfirm: this.closeConfirm,
         confirmDelete: this.confirmDelete,
      };
   }

   UNSAFE_componentWillMount() {
      this.getCities();
   }
   getCities = () => {
      getToken().then(token => {
         const data = {token};
         getCitiesService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               if (status === "success") {
                  this.setState({
                     citiesData: [...data],
                  });
               }
            })
            .catch(err => console.log(err));
      });
      /*  const cities = [
         {id: 0, status: 1, city: "Ciudad de México"},
         {id: 1, status: 0, city: "Puebla"},
         {id: 2, status: 0, city: "Monterrey"},
      ]; */
   };
   // add
   openModalAdd = () => {
      this.setState({modalAddIsOpen: true});
   };
   closeModalAdd = () => {
      this.setState({modalAddIsOpen: false, errorsForm: ""});
   };
   addCity = e => {
      e.preventDefault();
      const myForm = e.target;
      const {citiesData} = this.state;
      const name = myForm.name.value;
      const status = Number(myForm.status.value);
      const country_id = Number(myForm.country_id.value);
      const data = {
         country_id,
         status,
         name,
      }; 
      // crear item
      const ExistCity = citiesData.find(x => x.name.toLocaleLowerCase() === name.toLocaleLowerCase() && x.country_id === country_id);
      if (ExistCity) {
         return this.setState({
            errorsForm: "Ya hay una ciudad con este nombre",
         });
      }
      getToken(data).then(token => {
         const toSend = {...data, token};
         postCityService(toSend)
            .then(resp => resp.json())
            .then(resp => {
               const {status, data} = resp;
               if (status === "success") {
                  const newCities = [...citiesData, {...data}];
                  this.setState(
                     {
                        citiesData: newCities,
                     },
                     () => {
                        this.closeModalAdd();
                     },
                  );
               } else {
                  console.log(resp);
               }
            })
            .catch(err => console.log(err));
      });

      //
      /*  */
   };
   // edit
   updateCity = e => {
      e.preventDefault();
      const myForm = e.target;
      const name = myForm.name.value;
      const status = Number(myForm.status.value);
      const country_id = Number(myForm.country_id.value);
      const id = Number(e.target.id.value);
      const updatedCity = {
         name,
         id,
         country_id,
         status,
      };
      const {citiesData} = this.state;
      const newCities = citiesData.map(city => {
         if (city.id === id) {
            return {...updatedCity};
         }
         return city;
      });

      getToken().then(token => {
         const data = {...updatedCity, token};
         updateCityService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {status} = resp;
               console.log(resp);
               if (status === "success") {
                  this.setState(
                     {
                        citiesData: newCities,
                     },
                     () => {
                        this.closeModalAdd();
                     },
                  );
               } else {
                  console.log(resp);
               }
            })
            .catch(err => console.log(err));
      });

      this.setState(
         {
            citiesData: newCities,
         },
         () => {
            this.closeModallEdit();
         },
      );
   };
   closeModallEdit = () => {
      this.setState({modalEditIsOpen: false, toEditCity: {}, errorsForm: ""});
   };
   openModalEdit = id => {
      const {citiesData} = this.state;
      let toEditCity = citiesData.find(x => Number(x.id) === Number(id));
      return this.setState({modalEditIsOpen: true, toEditCity: toEditCity});
   };

   confirmDelete = id => {
      const {citiesData} = this.state;
      let deteteCity = citiesData.find(x => Number(x.id) === Number(id));
      const dataDelete = {
         typeDelete: "ciudad",
         nameDelete: deteteCity.city,
         id: id,
      };
      this.setState({
         dataDelete: dataDelete,
         confirmIsOpen: true,
      });
   };
   closeConfirm = () => {
      this.setState({
         dataDelete: [],
         confirmIsOpen: false,
      });
   };
   deleteCity = id => {
      const {citiesData} = this.state;

      getToken().then(token => {
         const data = {id, token};
         deleteCityService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {status} = resp;
               if (status === "success") {
                  let newCities = citiesData.filter(x => Number(x.id) !== Number(id));
                  this.setState(
                     {
                        citiesData: newCities,
                     },
                     () => {
                        this.closeConfirm();
                     },
                  );
               } else {
                  console.log(resp);
               }
            });
      });
   };

   render() {
      return <CitiesContext.Provider value={this.state}>{this.props.children}</CitiesContext.Provider>;
   }
}
