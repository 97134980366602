import React from "react";
import styled from "styled-components";
import propTypes from "prop-types";

export const ResetButton = styled.button`
   outline: none;
   border: none;
   background: transparent;
   cursor: pointer;

   pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
   opacity: ${(props) => (props.disabled ? 0.8 : 1)};
`;

// boton con icono a la izquierda o derecha
// sin bordes y padding a los lados

export const ButtonWithIcon = ({Icon, text, action, disabled, right, classname, as, color}) => {
   return (
      <ResetButton onClick={action} disabled={disabled} as={as}>
         <StyledWithImg className={classname} disabled={disabled} right={right} color={color}>
            <Icon className="icon" />
            <span>{text}</span>
         </StyledWithImg>
      </ResetButton>
   );
};

ButtonWithIcon.propTypes = {
   icon: propTypes.element,
   text: propTypes.string,
   action: propTypes.func,
   disabled: propTypes.bool,
   right: propTypes.bool,
};

const StyledWithImg = styled.div`
   display: flex;
   flex-direction: ${(props) => (props.right ? "row-reverse" : "row")};
   padding: 0.5em 0;
   align-items: center;
   transition: transform 320ms linear;
   pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
   justify-content: center;

   span {
      font-size: 1.3em;
      color: ${(props) => props.color || "#1c355e"};
      font-weight: 800;
      user-select: none;
   }

   .icon {
      fill: ${(props) => props.color || "#1c355e"};
      margin-right: ${(props) => (props.right ? "0" : "1em")};
      margin-left: ${(props) => (props.right ? "1em" : "0")};
      width: 2.5em;
      height: 2.5em;
   }

   &.text {
      & .icon {
         width: 1.8em;
         height: 1.5em;
      }
   }

   &.min {
      & .icon {
         width: 1em;
         height: 1em;
      }
      span {
         font-size: 0.9em;
         font-weight: 300;
      }
   }

   &.grey {
      & .icon {
         fill: grey;
      }
      span {
         color: grey;
      }
   }

   &:active {
      transform: scale(0.9);
      transition: transform 320ms linear;
   }
`;

// botton

export const AppButton = styled(ResetButton)`
   padding: 0.5em 1em;
   background: white;
   color: ${(props) => props.color || "#1c355e"};
   font-size: 1em;
   font-weight: 800;
   border: 2px solid ${(props) => props.color || "#1c355e"};
   user-select: none;
   text-align: center;
`;
