import React from "react";
import DataTable from "react-data-table-component";
import Text from "../../components/Text";
import {useSelector} from "react-redux";
import HeaderView from "../../components/toViews/HeaderView";
import AppLoader from "../../components/loader/AppLoader";

export default function Table({codeName}) {
   const {codeData, fetching} = useSelector((state) => state.memberships);

   const Columns = [
      {
         name: "Id User",
         selector: "id_user",
         sortable: true,
         maxWidth: "120px",
      },
      {
         name: "Name",
         cell: (row) => <Text>{`${row.first_name} ${row.last_name}`}</Text>,
         sortable: true,
      },
      {
         name: "Email",
         selector: "email",
      },
      {
         name: "Fecha de activación",
         selector: "email",
      },
   ];

   if (fetching) return <AppLoader isVisible={true} />;

   return (
      <DataTable
         title="Códigos de membresía"
         header={null}
         columns={Columns}
         data={[...codeData]}
         subHeader
         subHeaderComponent={<HeaderActions codeName={codeName} />}
         pagination
         paginationComponentOptions={{rowsPerPageText: "Filas por página", rangeSeparatorText: "de"}}
         fixedHeader
         noDataComponent="Este código no ha sido usado"
         style={{display: "block", height: "80%", overflowX: "initial"}}
      />
   );
}

const HeaderActions = ({codeName}) => <HeaderView title={`Membresías activadas con: ${codeName}`}></HeaderView>;
