import React from "react";
import styled from "styled-components";
import Colors from "../constants/Colors";

export default function ErrorView() {
   return (
      <Content>
         <Circle>
            <Center>
               <Error>Error 404</Error>
            </Center>
         </Circle>
      </Content>
   );
}

const Content = styled.section`
   with: 100%;
   height: 100%;
   background-color: white;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const Circle = styled.div`
   width: 25vw;
   height: 25vw;
   min-width:  220px;
   max-width:  440px;
   min-height: 220px;
   max-height: 440px;
   // background: #f1f4ff;
   background: ${Colors.secondary}
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   box-shadow: 4px 5px 5px 2px #b8b7b7;
`;

const Center = styled.div`
   width: 70%;
   height: 70%;
   border-radius: 50%;
   background: white;
   box-shadow: -4px -5px 3px 0 #b8b7b7;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const Error = styled.p`
   font-size: 2em;
   color: ${Colors.backColor};
   font-weight: bold;
   user-select: none;
   @media screen and (max-width: 600px) {
      font-size: 1.7em;
   }
`;
