import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {TableAction} from "../../components/TableComponents";
import {ReactComponent as WatchIcon} from "../../icons/eye.svg";
import Colors from "../../constants/Colors";
import {filterByKey} from "../../constants/Filters";
import {getToken, userTicketsService} from "../../helpers/Services";
import HeaderActions from "./tableComponents/HeaderActions";
import {GlobalContext} from "../../providers/GlobalProvider";
import Text from "../../components/Text";
import {Select} from "../../components/FormsConfig";
import {searchByValue} from "../../helpers/Filters";

const WatchButton = ({val, action}) => (
   <TableAction
      as="button"
      iconColor="darkgreen"
      onClick={() => {
         action(val);
      }}
      margin="0"
   >
      <WatchIcon width="20px" height="20px" fill={Colors.primary} className="icon" />
   </TableAction>
);
class TableUsersView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         DataUsers: this.props.usersData,
         filterText: "",
         country_id: 0,
         citiesCountry: [],
         city_id: 0,
      };
   }
   componentDidMount() {
      this.onFilter("country_id", "0");
   }
   UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.usersData === this.state.DataUsers) return false;
      this.setState(
         {
            DataUsers: this.props.usersData,
         },
         () => this.onFilter("country_id", "0"),
      );
   }
   onFilter = (key, val) => {
      if (key === "country_id") {
         this.setCountry(val);
      }
      this.setState(
         {
            [key]: val,
         },
         () => {
            this.fByCountry();
         },
      );
   };
   filterText = (userData) => {
      const {filterText} = this.state;
      let filtered = searchByValue(userData, filterText);
      this.setState({
         filteredItems: filtered,
      });
   };
   filterBy = (key, val) => {
      const {DataUsers, filterText} = this.state;
      let filtered = DataUsers.filter((user) => filterByKey(Number(user[key]), val, "0"));
      this.setState(
         {
            filteredItems: filtered,
         },
         () => {
            /* if (key === "country_id") {
               this.filterBy("city_id", this.state.city_id);
            } */
            this.filterText(filterText);
         },
      );
   };
   fByCountry = () => {
      // this.filterBy("country_id", this.state.country_id);
      const key = "country_id";
      const val = this.state.country_id;
      const {DataUsers} = this.state;
      let filtered = DataUsers.filter((user) => filterByKey(Number(user[key]), val, "0"));
      this.fbCity(filtered);
   };
   fbCity = (userData) => {
      const val = Number(this.state.city_id);
      let filtered = userData.filter((user) => (val > 0 ? Number(user.city_id) === val : true));
      this.filterText(filtered);
   };
   setCountry = (id) => {
      const {citiesData} = this.props;
      if (!citiesData) return false;
      const citiesCountry = citiesData.filter((city) => Number(city.country_id) === Number(id));
      this.setState({
         citiesCountry,
         city_id: 0,
      });
   };
   getUserTikets = (id_user) => {
      getToken().then((token) => {
         const data = {token, id_user};
         userTicketsService(data)
            .then((res) => res.json())
            .then((resp) => {
               console.log(resp);
            });
      });
   };
   Columns = [
      {
         name: "Id",
         selector: "id",
         sortable: true,
         maxWidth: "70px",
      },
      {
         name: "Nombre",
         cell: (row) => (
            <div>
               <Text>{`${row.first_name} ${row.last_name}`}</Text>
            </div>
         ),
         sortable: true,
      },
      {
         name: "Correo",
         selector: "email",
         sortable: true,
      },
      {
         name: "Teléfono",
         selector: "phone",
         sortable: true,
         maxWidth: "150px",
      },
      {
         name: "",
         button: false,
         sortable: false,
         cell: (row) => (
            <Select defaultValue={row.role_id} onChange={(e) => this.props.toggleAdmind(row.id, e.target.value)}>
               <option value={1}>Usuario</option>
               <option value={2}>Admin</option>
               <option value={3}>Checador</option>
            </Select>
         ),
      },
      {
         name: "Membresía",
         button: false,
         cell: (row) => (
            <div>
               <Text>{row.status ? "Activa" : "--"}</Text>
            </div>
         ),
         maxWidth: "90px",
      },
      {
         name: "",
         button: false,
         cell: (row) => <WatchButton val={row.id} action={this.props.openShowUser} />,
         maxWidth: "80px",
      },
   ];

   render() {
      const {filteredItems} = this.state;
      const paginationOptions = {rowsPerPageText: "Filas por página", rangeSeparatorText: "de"};
      return (
         <DataTable
            title="Usuarios"
            header={null}
            columns={this.Columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={
               <HeaderActions
                  onFilter={this.onFilter}
                  countrySelected={this.state.country_id}
                  title="Usuarios"
                  openModal={this.props.openModalAdd}
                  cities={this.state.citiesCountry}
               />
            }
            pagination
            paginationComponentOptions={paginationOptions}
            fixedHeader
            noDataComponent="No hay usuarios registrados"
            style={{display: "block", height: "80%", overflowX: "initial"}}
         />
      );
   }
}

const TableUsers = (props) => (
   <GlobalContext.Consumer>{({citiesData}) => <TableUsersView {...props} citiesData={citiesData} />}</GlobalContext.Consumer>
);
export default TableUsers;
