export const filterSearch = (val, item) => {
   // const objeto = [...AdminsData];
   // recorrero obbjeto y cada elemento pasarlo a una cadena en minuscla
   // buscar que tenga el valor en cualquier lugar
   /* let filtered = objeto.filter(item =>
      JSON.stringify(item)
         .toLocaleLowerCase()
         .indexOf(val.toLocaleLowerCase()),
   ); */

   let valid = JSON.stringify(item).toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1;
   return valid;
};
