import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {Seeker, ItemHeader} from "../../../../components/TableComponents";
import HeaderView from "../../../../components/toViews/HeaderView";
import Text from "../../../../components/Text";
import styled from "styled-components";
import {Select} from "../../../../components/FormsConfig";
import {filterByText, filterByArray} from "../../../../constants/Filters";

/* const EditButton = ({val, action}) => (
   <TableAction
      as="button"
      iconColor="darkgreen"
      onClick={() => {
         action(val);
      }}
   >
      <EditIcon width="20px" height="20px" fill={Colors.primary} className="icon" />
   </TableAction>
); */
const View = styled.div`
   width: 100%;
`;

const HeaderActions = ({onFilter, title, selectFilter}) => (
   <HeaderView title={title}>
      <ItemHeader>
         <Select defaultValue={"all"} onChange={e => selectFilter(e.target.value)}>
            <option value={"all"}>Todos</option>
            <option value={0}>Por entregar</option>
            <option value={1}>Entregados</option>
         </Select>
      </ItemHeader>
      <ItemHeader>
         <Seeker width="250px" onFilter={onFilter} place="Buscar código o usuario" />
      </ItemHeader>
   </HeaderView>
);

export default class TableTickets extends Component {
   constructor(props) {
      super(props);
      this.state = {
         DataTickets: this.props.ticketsData,
         filterText: "",
      };
   }
   componentDidMount() {
      this.onFilter();
   }
   UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.ticketsData === this.state.DataTickets) return false;
      this.setState(
         {
            DataTickets: this.props.ticketsData,
         },
         () => this.onFilter(this.state.filterText),
      );
   }
   onFilter = val => {
      this.setState(
         {
            filterText: val ? val : "",
         },
         this.filterItems(),
      );
   };
   filterItems = () => {
      const {filterText, DataTickets, filter} = this.state;
      var filteredItems = [];
      if (filter === "all" || !filter) {
         filteredItems = DataTickets;
      } else {
         //alert("fkrer");
         filteredItems = DataTickets.filter(item => Number(item.state) === Number(filter));
         this.setState({
            filteredItems: filteredItems,
         });
      }
      if (filterText.length > 2) {
         filteredItems = filteredItems.filter(
            ticket =>
               filterByText(ticket.code + "", filterText) ||
               filterByText(ticket.username + "", filterText) ||
               filterByArray(ticket.first_name, filterText) ||
               filterByArray(ticket.last_name, filterText),
         );
         return this.setState({
            filteredItems,
         });
      } else {
         return this.setState({
            filteredItems: filteredItems,
         });
      }
   };
   Columns = [
      /* {
         name: "Id",
         selector: "id",
         sortable: true,
         maxWidth: "120px",
      }, 
      {
         name: "Archivo",
         selector: "img",
         sortable: true,
         overflowX: "hidden",
         cell: row => (
            <View>
               <Text color="blue" as="a" href={"http://freeticket.trebolbit.com/" + row.file_url} target="_blank">
                  {row.file_url}
               </Text>
            </View>
         ),
      },
       id: 102
file_url: "media/events/external/1576184034352.jpg"
code: "1576363309974s"
id_user: 22
status: 1
id_event: 35
date: "2019-12-14T22:41:50.000Z"
printable: 0
       {
         name: "Usuario",
         selector: "id_user",
         sortable: true,
         overflowX: "hidden",
         maxWidth: "190px",
      }, */
      {
         name: "username",
         selector: "username",
         sortable: true,
         overflowX: "hidden",
         maxWidth: "190px",
      },
      {
         name: "Nombre",
         selector: "first_name",
         sortable: true,
         overflowX: "hidden",
         maxWidth: "190px",
      },
      {
         name: "Apellido",
         selector: "last_name",
         sortable: true,
         overflowX: "hidden",
         maxWidth: "190px",
      },
      {
         name: "Código",
         selector: "code",
         sortable: true,
      },
      {
         name: "Regalo",
         selector: "printable",
         sortable: false,
         overflowX: "hidden",
         maxWidth: "100px",
         cell: row => (
            <View>
               <Text color="grey" align="center">
                  {row.printable === 1 ? "Sí" : "No"}
               </Text>
            </View>
         ),
      },
      {
         name: "Escaneado",
         selector: "used",
         sortable: false,
         maxWidth: "100px",
         cell: row => (
            <View>
               <Text color="grey" align="center">
                  {row.used === 1 ? "Sí" : "No"}
               </Text>
            </View>
         ),
      },
      {
         name: "Fecha de vencimiento",
         selector: "max_date",
         sortable: false,
         maxWidth: "230px",
         cell: row => (
            <View>
               <Text color="grey" align="center">
                  {row.max_date || "Por definir"}
               </Text>
            </View>
         ),
      },
   ];
   setFilters = val => {
      this.setState(
         {
            filter: val,
         },
         () => {
            this.filterItems();
         },
      );
   };
   render() {
      const {filteredItems} = this.state;
      const paginationOptions = {rowsPerPageText: "Filas por página", rangeSeparatorText: "de"};
      return (
         <DataTable
            title="Tickets"
            header={null}
            columns={this.Columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={
               <HeaderActions
                  selectFilter={val => this.setFilters(val)}
                  onFilter={this.onFilter}
                  title="Tickets"
                  openModal={this.props.openModalAdd}
               />
            }
            pagination
            paginationComponentOptions={paginationOptions}
            fixedHeader
            noDataComponent="No hay tickets para el evento"
            style={{display: "block", height: "80%", overflowX: "initial"}}
         />
      );
   }
}
