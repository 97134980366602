import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {Seeker, ItemHeader} from "../../components/TableComponents";
import HeaderView from "../../components/toViews/HeaderView";
import {AddButton} from "../../components/Button";

/* const EditButton = ({val, action}) => (
   <TableAction
      as="button"
      iconColor="darkgreen"
      onClick={() => {
         action(val);
      }}
   >
      <EditIcon width="20px" height="20px" fill={Colors.primary} className="icon" />
   </TableAction>
);
const DeleteButton = ({val, action}) => (
   <TableAction
      as="button"
      iconColor="crimson"
      onClick={() => {
         action(val);
      }}
   >
      <DeleteIcon width="20px" height="20px" fill={Colors.primary} className="icon" />
   </TableAction>
); */

const HeaderActions = ({onFilter, title, openModal}) => (
   <HeaderView title={title}>
      <ItemHeader>
         <AddButton onClick={openModal} />
      </ItemHeader>
      <ItemHeader>
         <Seeker onFilter={onFilter} place="Buscar" />
      </ItemHeader>
   </HeaderView>
);

export default class TableNotifications extends Component {
   constructor(props) {
      super(props);
      this.state = {
         dataNotifications: this.props.data,
         filterText: "",
      };
   }
   componentDidMount() {
      this.filterItems();
   }
   /*  UNSAFE_componentWillReceiveProps() {
      this.setState(
         {
            dataNotifications: this.props.data,
         },
         () => this.onFilter(this.state.filterText),
      );
   } */
   UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.data === this.state.dataNotifications) return false;
      this.setState(
         {
            dataNotifications: this.props.data,
         },
         () => this.onFilter(this.state.filterText),
      );
   }
   onFilter = val => {
      this.filterItems(val);
   };
   filterItems = val => {
      const {dataNotifications} = this.state;
      const filterText = val || "";
      if (filterText.length > 2) {
         let filteredItems = dataNotifications.filter(
            notifications =>
               notifications.title.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
               notifications.description.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
               notifications.date.toLowerCase().indexOf(filterText.toLowerCase()) > -1,
         );
         return this.setState({
            filteredItems: filteredItems,
         });
      }
      return this.setState({
         filteredItems: dataNotifications,
      });
   };
   Columns = [
      {
         name: "Fecha",
         selector: "date",
         sortable: false,
         maxWidth: "200px",
      },
      {
         name: "Título",
         selector: "title",
         sortable: false,
         maxWidth: "330px",
      },
      {
         name: "Descripción",
         selector: "description",
         sortable: false,
         maxWidth: "420px",
      },
   ];

   render() {
      const {filteredItems} = this.state;
      const paginationOptions = {rowsPerPageText: "Filas por página", rangeSeparatorText: "de"};
      return (
         <DataTable
            title="Notificaciones PUSH"
            header={null}
            columns={this.Columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={<HeaderActions onFilter={this.onFilter} title="Notificaciones PUSH" openModal={this.props.openAdd} />}
            pagination
            paginationComponentOptions={paginationOptions}
            fixedHeader
            noDataComponent="No hay notificaciones"
            style={{display: "block", height: "80%", overflowX: "initial"}}         />
         
      );
   }
}
