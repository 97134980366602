import React, {Component} from "react";
import styled from "styled-components";
import Colors from "../constants/Colors";
import Button from "./Button";

const FieldFileContainer = styled.div`
   width: 100%;
   background-color: rgba(180, 180, 180, 0.3);
   padding: 2em;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   cursor: pointer;
   user-select: none;
   box-sizing: border-box;
   margin-top: 1em;
   border: 2px dashed rgba(180, 180, 180, 0.6);
   margin-bottom: 2em;
   & + input {
      display: none;
   }
   &:active {
      transform: scale(0.8);
   }
`;

export class FieldFile extends Component {
   updateImage = e => {
      if (!this.props.onChange) return false;
      this.props.onChange(e.target);
   };
   render() {
      const {fileType, children, name} = this.props;
      return (
         <>
            <FieldFileContainer as="label" htmlFor={name}>
               {children}
            </FieldFileContainer>
            <input type="file" name={name} id={name} accept={fileType} onChange={this.updateImage} />
         </>
      );
   }
}

const SimpleFieldContainer = styled.div`
   display: flex;
   align-items: center;
   & ${Button} {
      width: 100%;
      white-space: nowrap;
   }
   & input {
      display: none;
   }
`;

export class SimpleField extends Component {
   updateImage = e => {
      if (!this.props.onChange) return false;
      this.props.onChange(e.target);
   };
   render() {
      const {fileType, text, name} = this.props;
      return (
         <>
            <SimpleFieldContainer>
               <Button text={Colors.primary} color={Colors.secondary} as="label" htmlFor="inputFile">
                  {text}
               </Button>
               <input type="file" id="inputFile" accept={fileType} onChange={this.updateImage} name={name} />
            </SimpleFieldContainer>
         </>
      );
   }
}
