import React, {useState} from "react";
import {
   listFutureService,
   toggleVisibleservice,
   start_eventService,
   finish_eventService,
   listTodayService,
   listPastService,
} from "../services/LiveServices";
import {getToken} from "../helpers/Services";

export const LiveEventsContext = React.createContext();

export default function LiveEventsProvider({children}) {
   const [listPastEvents, setPastEvents] = useState({isLoading: true, data: []}); // eventos pasados
   const [listTodayEvents, setTodayEvents] = useState({isLoading: true, data: []}); // eventos de hoy
   const [listFutureEvents, setFutureEvents] = useState({isLoading: true, data: []}); // eventos futuros
   const [currentTab, setTab] = useState(2);

   const getFutureEvents = (force = false) => {
      if (listFutureEvents.data.length >= 1 && !force) return false;
      listFutureService()
         .then((res) => res.json())
         .then((resp) => {
            const {status, data} = resp;

            if (status === "error") throw new Error();
            setFutureEvents({isLoading: false, data});
         })
         .catch(() => {
            setFutureEvents({isLoading: false});
         });
   };

   const getPastEvents = (force = false) => {
      if (listFutureEvents.data.length >= 1 && !force) return false;
      listPastService()
         .then((res) => res.json())
         .then((resp) => {
            const {status, data} = resp;
            if (status === "error") throw new Error();
            setPastEvents({isLoading: false, data});
         })
         .catch(() => {
            setPastEvents({isLoading: false});
         });
   };
   const getTodayEvents = (force = false) => {
      if (listTodayEvents.data.length >= 1 && !force) return false;
      listTodayService()
         .then((res) => res.json())
         .then((resp) => {
            const {status, data} = resp;
            if (status === "error") throw new Error();
            setTodayEvents({isLoading: false, data});
         })
         .catch(() => {
            setFutureEvents({isLoading: false});
         });
   };

   const addRecentEvent = (data) => {
      let currentDate = new Date();
      let eventDate = new Date(data.date_event);
      let isToday = currentDate.getDate() === eventDate.getDate();

      if (isToday) {
         if (listTodayEvents.data.length === 0) return false;
         let nData = [...listTodayEvents.data, {...data}];
         setTodayEvents({isLoading: false, data: nData});
      } else {
         if (listFutureEvents.data.length === 0) return false;
         let nData = [...listFutureEvents.data, {...data}];
         setFutureEvents({isLoading: false, data: nData});
      }
   };

   const disableEvent = (id, type) => {
      let nVal = false;
      if (type === "today") {
         if (listTodayEvents.data.length === 0) return false;
         let nData = listTodayEvents.data.map((itemDay) => {
            if (itemDay.id === id) {
               nVal = !itemDay.visibility;
               return {...itemDay, visibility: !itemDay.visibility};
            }
            return itemDay;
         });
         updateData(nVal);
         setTodayEvents({isLoading: false, data: nData});
      } else if (type === "future") {
         if (listFutureEvents.data.length === 0) return false;
         let nData = listFutureEvents.data.map((itemDay) => {
            if (itemDay.id === id) {
               nVal = !itemDay.visibility;
               return {...itemDay, visibility: !itemDay.visibility};
            }
            return itemDay;
         });
         updateData(nVal);
         setFutureEvents({isLoading: false, data: nData});
      }

      function updateData(nVal) {
         getToken().then((token) => {
            toggleVisibleservice({token, live_event_id: id, visible: nVal})
               .then((res) => res.json())
               .then((resp) => {
                  const {status} = resp;
                  if (status === "error") throw new Error();
                  console.log(resp);
               })
               .catch((e) => {
                  console.log(e);
               });
         });
      }
   };

   const initEvent = (id) => {
      // cambiar a lista de hoy
      if (listTodayEvents.data.length === 0) return false;
      let nData = listTodayEvents.data.map((itemDay) => {
         if (itemDay.id === id) {
            return {...itemDay, active: true};
         }
         return itemDay;
      });

      getToken().then((token) => {
         start_eventService({token, live_event_id: id})
            .then((res) => res.json())
            .then((resp) => {
               const {status} = resp;
               if (status === "error") throw new Error();
               // console.log(resp);
               setTodayEvents({isLoading: false, data: nData});
            })
            .catch(() => {
               setTodayEvents({isLoading: false, data: listTodayEvents.data});
            });
      });
   };
   const endEvent = (id) => {
      // cambiar a lista de hoy
      if (listTodayEvents.data.length === 0) return false;
      let nData = listTodayEvents.data.map((itemDay) => {
         if (itemDay.id === id) {
            return {...itemDay, active: false};
         }
         return itemDay;
      });
      setTodayEvents({isLoading: false, data: nData});

      getToken().then((token) => {
         finish_eventService({token, live_event_id: id})
            .then((res) => res.json())
            .then((resp) => {
               const {status} = resp;
               if (status === "error") throw new Error();
               // console.log(resp);
               setFutureEvents({isLoading: false, data: nData});
            })
            .catch(() => {
               setFutureEvents({isLoading: false, data: listFutureEvents.data});
            });
      });
   };

   const updateEvent = (live_event_id) => {
      let isToday = listTodayEvents.data.find((event) => event.id === live_event_id);
      let isFuture = listFutureEvents.data.find((event) => event.id === live_event_id);
      if (isToday) {
         return getTodayEvents(true);
      }
      if (isFuture) {
         return getFutureEvents(true);
      }
   };

   return (
      <LiveEventsContext.Provider
         value={{
            addRecentEvent, // agreagr nuevo a futuros o los de hoy
            getFutureEvents, // eventos futuros
            getTodayEvents, // eventos de hpoy
            listFutureEvents, // evntos futur
            listTodayEvents, // entos de HOY
            currentTab, // tab seleccionado
            setTab, // cambiar tab
            disableEvent, // cambiar  visibility
            initEvent, // iniciar  evento
            endEvent, // terminar evento
            updateEvent,
            getPastEvents, // obtener eventos pasaods
            listPastEvents, // eveto pasados
         }}
      >
         {children}
      </LiveEventsContext.Provider>
   );
}
