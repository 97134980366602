import React from "react";
import GlobalModal from "../../components/GlobalModal";
import {TitleModal, BodyModal, ContentButtons} from "../../components/ModalConfig";
import HeadModal from "../../components/modal/HeadModal";
import {Select, Input} from "../../components/FormsConfig";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import Text from "../../components/Text";
import {FavoritesContext} from "../../providers/FavoritesProvider";

export default AddTheme => {
   return (
      <FavoritesContext.Consumer> 
         {({modalAddIsOpen, closeModalAdd, addFavorite, errorsForm}) => (
            <GlobalModal modalIsOpen={modalAddIsOpen} closeModal={closeModalAdd} widthCard="35%">
               <HeadModal closeModal={closeModalAdd}>
                  <TitleModal>Agregar tema</TitleModal>
               </HeadModal>
               {modalAddIsOpen && (
                  <BodyModal>
                     <form
                        onSubmit={e => {
                           addFavorite(e);
                        }}
                     >
                        <Select name="status">
                           <option value="0">No publicado</option>
                           <option value="1">Publicado</option>
                        </Select>
                        <Input type="text" placeholder="Nombre del tema" name="name" />
                        {errorsForm && <Text color="crimson">{errorsForm}</Text>}
                        <ContentButtons>
                           <Button color="crimson" onClick={closeModalAdd} as="div">
                              CERRAR
                           </Button>
                           <Button text={Colors.secondary}>GUARDAR</Button>
                        </ContentButtons>
                     </form>
                  </BodyModal>
               )}
            </GlobalModal>
         )}
      </FavoritesContext.Consumer>
   );
};
