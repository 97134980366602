import React from "react";
import GlobalModal from "../../components/GlobalModal";
import {TitleModal, BodyModal, ContentButtons} from "../../components/ModalConfig";
import HeadModal from "../../components/modal/HeadModal";
import {Select, Input} from "../../components/FormsConfig";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import {FavoritesContext} from "../../providers/FavoritesProvider";

export default EditFavorite => {
   return (
      <FavoritesContext.Consumer>
         {({toEditFavorite, modalEditIsOpen, closeModallEdit, updateFavorite}) => {
            return (
               <GlobalModal modalIsOpen={modalEditIsOpen} closeModal={closeModallEdit} widthCard="40%">
                  <HeadModal closeModal={closeModallEdit}>
                     <TitleModal>{toEditFavorite ? "Editar" : "Agregar"} ciudad</TitleModal>
                  </HeadModal>
                  {modalEditIsOpen && (
                     <BodyModal>
                        <form
                           onSubmit={e => {
                              updateFavorite(e, toEditFavorite);
                           }}
                        >
                           <Input type="hidden" value={toEditFavorite.id} name="id" />
                           <Select name="status" defaultValue={toEditFavorite.status}>
                              <option value={0}>No publicada</option>
                              <option value={1}>Publicada</option>
                           </Select>
                           <Input
                              type="text"
                              placeholder="Nombre de la ciudad"
                              //value={this.state.cityData.city}
                              name="name"
                              defaultValue={toEditFavorite.name}
                           />
                           <ContentButtons>
                              <Button color="crimson" onClick={closeModallEdit} as="div">
                                 CERRAR
                              </Button>
                              <Button text={Colors.secondary}>GUARDAR</Button>
                           </ContentButtons>
                        </form>
                     </BodyModal>
                  )}
               </GlobalModal>
            );
         }}
      </FavoritesContext.Consumer>
   );
};
