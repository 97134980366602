import React, {useState} from "react";
import {
   liveTicektCreateService,
   liveAssistanceService,
   universalTicketListService,
   universalTicketRemoveService,
} from "../services/LiveServices";
import {getToken} from "../helpers/Services";

export const LiveTicketsContext = React.createContext();

export default function LiveTicketsProvider({children}) {
   const [ticketsList, setTicketsList] = useState([]);
   const [assistenceList, setAssistenceList] = useState({isLoading: false, data: []});
   const [universalList, setUniversalList] = useState({isLoading: false, data: []});

   const createTicketsLive = (id_live_event, total) => {
      getToken().then((token) => {
         liveTicektCreateService({token, id_live_event, total})
            .then((res) => res.json())
            .then((resp) => {
               const {status, data} = resp;
               console.log(data);
               if (status === "error") throw new Error();
               setTicketsList(data);
            })
            .catch((e) => {
               console.log(e);
            });
      });
   };

   const getAssistenceList = (live_event_id) => {
      getToken().then((token) => {
         let toSend = {live_event_id, token};
         liveAssistanceService(toSend)
            .then((res) => res.json())
            .then((resp) => {
               const {status, data} = resp;
               console.log(data);
               if (status === "error") throw new Error();
               setAssistenceList({data: [...data], isLoading: false});
            })
            .catch(() => {
               setAssistenceList({data: [], isLoading: false});
            });
      });
   };

   const getUniversalCodes = (force) => {
      if (universalList.data.length > 0 && !force) return false;
      getToken().then((token) => {
         let toSend = {token};
         universalTicketListService(toSend)
            .then((res) => res.json())
            .then((resp) => {
               const {status, data} = resp;
               if (status === "error") throw new Error();
               setUniversalList({data: [...data], isLoading: false});
            })
            .catch(() => {
               setUniversalList({data: [], isLoading: false});
            });
      });
   };

   const removeNewUniversalCode = (id, code) => {
      getToken().then((token) => {
         let toSend = {token, code};
         universalTicketRemoveService(toSend)
            .then((res) => res.json())
            .then((resp) => {
               const {status} = resp;
               if (status === "error") throw new Error();
               let nextData = universalList.data.filter((item) => item.id !== id);
               setUniversalList({data: [...nextData], isLoading: false});
            })
            .catch(() => {
               return false;
            });
      });
   };
   const addNewUniversalCode = (nexDataCode) => {
      setUniversalList({data: [...universalList.data, {...nexDataCode}], isLoading: false});
   };

   return (
      <LiveTicketsContext.Provider
         value={{
            createTicketsLive, // crear tickes para evento
            ticketsList, // lista de tickets creados
            getAssistenceList, // obtener lista de asistencia
            assistenceList, // lista de asistencia
            getUniversalCodes, // obtener lista de códigos universale
            universalList,
            addNewUniversalCode, // actualizar lista
            removeNewUniversalCode,
         }}
      >
         {children}
      </LiveTicketsContext.Provider>
   );
}
