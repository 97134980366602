import React from "react";
import HeaderView from "../../../components/toViews/HeaderView";
import {ItemHeader, Seeker} from "../../../components/TableComponents";
import {Select} from "../../../components/FormsConfig";

const HeaderActions = ({onFilter, title, countrySelected, cities}) => {
   return (
      <HeaderView title={title}>
         <ItemHeader>
            <Select name="country_id" defaultValue={0} onChange={e => onFilter("country_id", e.target.value)} marginB="0">
               <option value={0}>Todos los países</option>
               <option value={1}>México</option>
               <option value={2}>Colombia</option>
            </Select>
         </ItemHeader>

         <ItemHeader>
            <Select
               name="city_id"
               defaultValue={1}
               onChange={e => onFilter("city_id", e.target.value)}
               disabled={Number(countrySelected) === 0}
               marginB="0"
            >
               <option value={0}>Todas las ciudades</option>
               {cities.map(item => (
                  <option value={item.id}>{item.name}</option>
               ))}
            </Select>
         </ItemHeader>

         <ItemHeader>
            <Seeker onFilter={val => onFilter("filterText", val)} place="Buscar por nombre o correo" width="280px" />
         </ItemHeader>
      </HeaderView>
   );
};

export default HeaderActions;
