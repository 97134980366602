import React from "react";
import {ThemeProvider} from "styled-components";
import Colors from "./constants/Colors";
import styled, {createGlobalStyle} from "styled-components";
import GlobalProvider from "./providers/GlobalProvider";
import AppRouter from "./AppRouter";
import "./constants/customTable.css";
import {Provider} from "react-redux";
import store from "./store";

const theme = {
   primary: Colors.primary,
   secondary: Colors.secondary,
   medium: Colors.medium,
   text: Colors.medium,
   normalText: "1em",
   smallText: "0.85em",
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Mulish', sans-serif;
    font-display: system-ui;
  }
  ul, p {
     padding: 0;
     margin: 0em;
  }
  section, div, article, nav, header, span, input, select, textarea {
     box-sizing: border-box;
     font-family: 'Mulish', sans-serif;
      font-display: system-ui;
  }
  section, div, article, p, a, button, ul, li, :after, :before, :hover {
     transition: all 420ms linear;
  }
  a{
     text-decoration: none;
  }

  ::-webkit-scrollbar {
      width: 10px;
   }
   
   ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(10,10,10,0.3); 
   }
   
   ::-webkit-scrollbar-thumb {
      background: rgba(200,200,200,1);
   }
   
   ::-webkit-scrollbar-thumb:hover {
      background: ${Colors.primary}
   }
`;
const Main = styled.div`
   display: flex;
   align-items: flex-start;
   height: 100vh;
   overflow: hidden;
`;

const App = () => {
   return (
      <Provider store={store}>
         <ThemeProvider theme={theme}>
            <GlobalProvider>
               <Main>
                  <GlobalStyle />
                  <AppRouter />
               </Main>
            </GlobalProvider>
         </ThemeProvider>
      </Provider>
   );
};

export default App;
