import React, {Component} from "react";
import Container from "../../components/Container";
import {GlobalContext} from "../../providers/GlobalProvider";
import {getEventsListService} from "../../helpers/Services";
import TableEvents from "../events/TableEvents";
import AppLoader from "../../components/loader/AppLoader";

class Checker extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isLoading: true,
         events: [],
      };
   }
   componentDidMount() {
      this.getActiveEvents();
   }
   getActiveEvents = () => {
      console.log("active events");
      // const {userData} = this.props;
      // obtener eventos
      getEventsListService()
         .then(res => res.json())
         .then(resp => {
            const {status, data} = resp;
            // filtrar eventos activos y de la ciudad del checador
            if (status !== "success") throw Error("sadsa");
            console.log(data);
            // const events = data.filter(item => item.city_id === userData.city_id && Number(item.visibility) === 1);
            this.setState({
               isLoading: false,
               events: data,
            });
         })
         .catch(err => {
            this.setState({
               isLoading: false,
               events: [],
            });
         });
   };
   render() {
      const {events, isLoading} = this.state;
      return (
         <Container {...this.props}>
            {isLoading && <p>Hsa</p>}
            <TableEvents eventsData={events} {...this.props} checker={true} />
         </Container>
      );
   }
}

const CheckerView = props => (
   <GlobalContext.Consumer>
      {({userData}) => {
         if (!userData) return <AppLoader isVisible={true} />;
         return <Checker {...props} userData={userData} />;
      }}
   </GlobalContext.Consumer>
);

// const CheckerViewComponent = props => <Checker {...props} />;

export default CheckerView;
