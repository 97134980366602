import React, {useState, useEffect} from "react";
import Pager from "./components/Pager";
import {
   ViewTableStyled,
   BodyViewTable,
   FooterViewTable,
   TableStyled,
   THeadStyled,
   TBodyStyled,
   NoDataComponent,
   DivItem,
   ThStyled,
   TdStyled,
} from "./components/TableStyles";
import {ReactComponent as DropIcon} from "./imgs/double_arrow.svg";
import propTypes from "prop-types";
import {filterSearch} from "./components/helpers";

export default function Table({columns, data, theme, showModal, noDataText, actions, actionTitle}) {
   const [currentPage, setCurrentPage] = useState(1);
   const [pageLength] = useState(10);
   const [filteredData, setFilteredData] = useState(data); // los datos a mostrar por página
   const [visibleData, setVisibleData] = useState([]); // los datos a mostrar por página
   const [orderTag, setOrderTag] = useState({reverse: false, order: "id"});
   const [searchVal] = useState("");

   useEffect(() => {
      applyFilters();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [orderTag, data, searchVal]);

   useEffect(() => {
      selectVisibleData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [currentPage, pageLength, orderTag, filteredData, data]);

   /* const updatePage = (e) => {
      e.preventDefault();
      const newVal = e.target.value;
      if (newVal < 10) return false;
      setPageLength(Number(newVal));
      setCurrentPage(1);
   }; */

   const applyFilters = () => {
      // filtrar
      let currentData = [...data];
      let filt = searchVal.length > 2 ? currentData.filter((item) => filterSearch(searchVal, item)) : currentData;
      // ordernar
      let newFiltered = orderDataByTag(filt, orderTag);
      setFilteredData(newFiltered);
   };

   function selectVisibleData() {
      // index inicial
      let start = (currentPage - 1) * pageLength;
      // index final
      let end = start + pageLength - 1;
      if (end > filteredData.length + pageLength) return false;
      const newVisible = filteredData.filter((item, index) => index >= start && index <= end);
      setVisibleData(newVisible);
   }

   const updateCurrent = (num) => {
      setCurrentPage(num);
   };

   const orderBy = (val) => {
      let ordetTagN = {
         reverse: val === orderTag.order ? !orderTag.reverse : false,
         order: val,
      };
      setOrderTag(ordetTagN);
   };

   if (!columns) return null;
   if (!data) return <NoDataComponent theme={theme}>{noDataText || "No se encontraron datos"}</NoDataComponent>;
   if (data.length === 0) return <NoDataComponent theme={theme}>{noDataText || "No se encontraron datos"}</NoDataComponent>;
   return (
      <ViewTableStyled>
         {filteredData.length === 0 ? (
            <NoDataComponent theme={theme}>{"No hay coincidencias con la busqueda"}</NoDataComponent>
         ) : (
            <BodyViewTable>
               <TableStyled>
                  <THeadStyled>
                     <tr>
                        {React.Children.toArray(
                           columns.map((colHead) => {
                              return (
                                 <ThStyled className={colHead.class || "normal"} tdWidth={colHead.width} tdMax={colHead.maxWidth}>
                                    <div
                                       onClick={() => {
                                          if (colHead.accessor) {
                                             orderBy(colHead.accessor);
                                          }
                                       }}
                                    >
                                       {colHead.title}
                                       <span>{!colHead.component && <DropIcon width="0.6em" height="1em" fill="#69799e" />}</span>
                                    </div>
                                 </ThStyled>
                              );
                           }),
                        )}
                     </tr>
                  </THeadStyled>
                  <TBodyStyled>
                     {React.Children.toArray(
                        visibleData.map((itemData) => {
                           return (
                              <tr>
                                 {React.Children.toArray(
                                    columns.map((col) => {
                                       return (
                                          <TdStyled className={col.class || "normal"} tdWidth={col.width} tdMax={col.maxWidth}>
                                             <DivItem
                                                style={col.style}
                                                tdWidth={col.width}
                                                tdMax={col.maxWidth}
                                                className={col.action ? "actionable" : ""}
                                                onClick={() => {
                                                   if (col.action) {
                                                      actions[col.action](itemData);
                                                   }
                                                }}
                                             >
                                                {col.actionTitle || itemData[col.accessor]}
                                             </DivItem>
                                          </TdStyled>
                                       );
                                    }),
                                 )}
                                 {/* <td className="actionBtn ghost">Ed</td> */}
                              </tr>
                           );
                        }),
                     )}
                  </TBodyStyled>
               </TableStyled>
            </BodyViewTable>
         )}

         <FooterViewTable>
            <Pager max={data.length} current={currentPage} pageLength={pageLength} updatePage={updateCurrent} />
         </FooterViewTable>
      </ViewTableStyled>
   );
}

Table.protTypes = {
   deleteModal: propTypes.func,
   editModal: propTypes.func,
   showModal: propTypes.func,
   data: propTypes.object,
   columns: propTypes.object.isRequired,
};

function orderDataByTag(data, order) {
   // filteredData orderTag
   if (order.reverse) {
      return data.reverse();
   }
   function compare(a, b) {
      let itemA = typeof a[order.order] === "string" ? a[order.order].toLowerCase() : a[order.order];
      let itemB = typeof b[order.order] === "string" ? b[order.order].toLowerCase() : b[order.order];
      let comparison = 0;
      if (itemA > itemB) {
         comparison = 1;
      } else if (itemA < itemB) {
         comparison = -1;
      }
      return comparison;
   }

   const toReturn = data.sort(compare);
   return toReturn;
}
