import styled from "styled-components";
import Colors from "../constants/Colors";

export const Select = styled.select`
   outline: none;
   border: none;
   width: 100%;
   border-bottom: 2px solid ${Colors.medium};
   padding: 0.5em;
   font-size: 1.1em;
   margin-bottom: ${props => props.marginB || "1em"};
   & option {
      padding: 0.5em;
   }
`;
export const Input = styled.input`
   outline: none;
   border: none;
   width: 100%;
   border-bottom: 2px solid ${props => props.border || Colors.medium};
   background-color: transparent;
   padding: 0.5em;
   font-size: 1.1em;
   margin-bottom: 1em;
   color: ${props => props.color || Colors.medium};
   &::-webkit-input-placeholder {
      color: grey;
   }
   height: ${props => props.height || null};
`;

export const Label = styled.label`
   font-size: ${props => props.size || "1.1em"};
   margin-bottom: ${props => props.marginBottom || "1em"};
   display: inline-block;
   color: ${props => props.color || Colors.medium};
`;
