import React, {Component} from "react";
import styled from "styled-components";
import {ReactComponent as User} from "./images/user.svg";
import {ReactComponent as Burguer} from "./images/menu.svg";
import {ReactComponent as Logout} from "./images/logout.svg";
import {ReactComponent as BackBtn} from "./images/back.svg";
import Colors from "../../constants/Colors";
import Text from "../Text";
import device from "../../constants/device";
import {GlobalContext} from "../../providers/GlobalProvider";
import Button, {ResetButton} from "../Button";
import propTypes from "prop-types";

const Bar = styled.header`
   padding: 1em;
   background-color: ${props => props.theme.medium};
   box-shadow: -1px 2px 10px 1px rgba(52, 58, 64, 0.3);
   display: flex;
   justify-content: space-between;
   color: white;
   align-items: center;
   heigth: 150px;
`;
const ContentBox = styled.div`
   display: flex;
   align-items: center;

   /* & button {
      
   } */
`;
const IconBox = styled.div`
   width: 33px;
   height: 33px;
   background-color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   margin-left: 0.5em;
`;
const DataBox = styled.div`
   display: flex;
   justify-content: center;
   align-items: flex-end;
   flex-direction: column;
   & ${Text} {
      margin: 0;
   }
`;
const ButtonBurguer = styled.div`
   background: transparent;
   outline: none;
   border: none;
   padding: 1px;
`;
const CloseAction = styled(Button)`
   margin: 0;
   padding: 0.2em 0.5em;
   margin-left: 1em;
   display: flex;
   justify-content: center;
   align-items: center;
   background: transparent;
   & .icon {
      fill: white;
   }
   &:hover .icon {
      fill: ${Colors.secondary};
   }
`;

export default class HeaderBar extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isSmallDevice: device.isSmarthphone,
      };
   }
   render() {
      return (
         <GlobalContext.Consumer>
            {({toggleMenu, logOut, userData}) => (
               <Bar>
                  <ContentBox>
                     {this.props.showBack && (
                        <ResetButton color="transparent" onClick={() => this.props.history.goBack()}>
                           <BackBtn width="20px" height="20px" fill="white" />
                        </ResetButton>
                     )}
                  </ContentBox>
                  <ContentBox>
                     {!this.state.isSmallDevice ? (
                        <>
                           <DataBox
                              onClick={() => {
                                 console.log(true);
                              }}
                           >
                              <Text color="rgba(250,250,250,1)">
                                 {userData ? `${userData.first_name} ${userData.last_name}` : "Nombre de usuario"}
                              </Text>
                              <Text color="rgba(250,250,250,0.7)" size="0.9em">
                                 {userData ? userData.email : "correo@correo.com"}
                              </Text>
                           </DataBox>
                           <IconBox>
                              <User alt="user" height="28px" width="28px" className="icon" fill={Colors.medium} />
                           </IconBox>
                           <CloseAction title="Cerrar sesión" onClick={() => logOut()}>
                              <Logout alt="logout" height="28px" width="28px" className="icon" />
                           </CloseAction>
                        </>
                     ) : (
                        <ButtonBurguer
                           onClick={() => {
                              toggleMenu(true);
                           }}
                        >
                           <Burguer alt="menu" height="28px" width="28px" fill="white" />
                        </ButtonBurguer>
                     )}
                  </ContentBox>
               </Bar>
            )}
         </GlobalContext.Consumer>
      );
   }
}

HeaderBar.propTypes = {
   showBack: propTypes.bool.isRequired,
};
HeaderBar.defaultProps = {
   showBack: false,
};
