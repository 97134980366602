/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from "react";
import {LiveEventsContext} from "../../../providers/LiveEventsProvider";
import {EventsListContainer} from "../Styles";
import EventComponent from "../components/EventCardComponent";
// import TodayEvent from "../components/TodayEvent";
// import EventComponent from "../../components/EventCardComponent";

export default function FutureEvents() {
   const {getFutureEvents, listFutureEvents} = useContext(LiveEventsContext);

   useEffect(() => {
      getFutureEvents();
   }, []);
   return (
      <>
         <EventsListContainer>
            {React.Children.toArray(
               listFutureEvents.data.map((liveEvent) => {
                  return <EventComponent data={liveEvent} type="future" />;
               }),
            )}
         </EventsListContainer>
      </>
   );
}
