import React, {useState, useEffect} from "react";
import GlobalModal from "../../../components/GlobalModal";
import HeadModal from "../../../components/modal/HeadModal";
import {TitleModal, BodyModal} from "../../../components/ModalConfig";
import {Seeker} from "../../../components/TableComponents";
import {useSelector, useDispatch} from "react-redux";
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import {Select, Input} from "../../../components/FormsConfig";
import moment from "moment";
import {createMembershipByTranfer} from "../../../store/actions/MembershipsActions";
import {getUsersAction} from "../../../store/actions/UsersActions";
import AppLoader from "../../../components/loader/AppLoader";

export default function AddMembership({isOpen, closeModal}) {
   const dispatch = useDispatch();
   const {users, fetchingUsers} = useSelector((state) => state.users);
   const [userEmail, setUserEmail] = useState("");
   const [duration, setDuration] = useState("");
   const [user, setUser] = useState({});
   const [initialDate, setInitialDate] = useState("");

   useEffect(() => {
      if (isOpen) {
         setUserEmail("");
         setDuration("");
         setUser({});
         setInitialDate("");
         if (document.getElementById("createMembershipForm")) {
            document.getElementById("createMembershipForm").reset();
         }
      }
   }, [isOpen]);

   useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatch(getUsersAction());
   }, [dispatch]);

   const searchUser = (e) => {
      e.preventDefault();
      let user = users.find((item) => item.email.toLowerCase() === userEmail.toLowerCase());
      setUser(user ?? {});
   };

   const saveMembership = () => {
      let date_renew = createRenew(initialDate, duration);
      let data = {
         id_user: user.id,
         payment_type: 4,
         success_id: duration,
         date: initialDate,
         date_renew,
      };
      dispatch(createMembershipByTranfer(data));
      closeModal();
   };

   return (
      <GlobalModal modalIsOpen={isOpen} closeModal={closeModal} widthCard="45%">
         <HeadModal closeModal={closeModal}>
            <TitleModal>Activar membresía: Transferencia bancaria</TitleModal>
         </HeadModal>
         <BodyModal>
            {fetchingUsers ? (
               <AppLoader isVisible={true} />
            ) : (
               <>
                  <form onSubmit={searchUser} id="createMembershipForm">
                     <Seeker place="Email de usuario" onFilter={setUserEmail} width="70%" />
                     <Button>Buscar</Button>
                  </form>
                  {user.first_name && (
                     <div>
                        <Text>
                           Activar membresía para: <span style={{fontWeight: "bold"}}>{`${user.first_name} ${user.last_name}`}</span> por
                        </Text>
                        <Select onChange={(e) => setDuration(e.target.value)}>
                           <option disable={"true"}>Duración de la membresía:</option>
                           <option value="PAGO1SEMANA">1 semana</option>
                           <option value="PAGO1MES">1 mes</option>
                           <option value="PAGO3MES">3 meses</option>
                           <option value="PAGO1ANIO">1 año</option>
                        </Select>
                     </div>
                  )}
                  {duration && (
                     <div>
                        <Text>desde la fecha:</Text>
                        <Input type="date" min="2020-01-01" onChange={(e) => setInitialDate(e.target.value)} />
                     </div>
                  )}
                  {initialDate && (
                     <Button style={{margin: "0 auto", display: "block"}} onClick={saveMembership}>
                        Activar membresía
                     </Button>
                  )}
               </>
            )}
         </BodyModal>
      </GlobalModal>
   );
}
function createRenew(initialDate, duration) {
   initialDate = moment(initialDate);
   switch (duration) {
      case "PAGO1SEMANA":
         return initialDate.add(1, "week");
      case "PAGO1MES":
         return initialDate.add(1, "month");
      case "PAGO3MES":
         return initialDate.add(3, "month");
      case "PAGO1ANIO":
         return initialDate.add(1, "year");
      default:
         return initialDate.add(1, "week");
   }
}
