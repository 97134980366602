/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import HeaderView from "../../../components/toViews/HeaderView";
import Container from "../../../components/Container";
import {ColDiv, RowDiv} from "../../events/views/editComponents/EditStyled";
import {Label, Input} from "../../../components/FormsConfig";
import {Content} from "../Styles";
import Button from "../../../components/Button";
import {infoLiveService} from "../../../services/LiveServices";
import AppLoader from "../../../components/loader/AppLoader";
import {useHistory, useParams} from "react-router-dom";
import styled from "styled-components";

export default function InfoShowView(props) {
   const [isSaving, setSaving] = useState(false);
   const [infoData, setInfo] = useState({});
   let history = useHistory();
   let {live_event_id} = useParams();

   useEffect(() => {
      getInfoShow();
   }, [live_event_id]);

   const getInfoShow = () => {
      setSaving(true);
      infoLiveService({live_event_id})
         .then((res) => res.json())
         .then((resp) => {
            let {status, data} = resp;
            if (status === "error") throw new Error();
            let time_start = data.time_start.substring(0, 5);
            let time_finish = data.time_finish.substring(0, 5);
            setInfo({...data, time_start, time_finish});
         })
         .then(() => {
            setSaving(false);
         })
         .catch(() => {
            setSaving(false);
         });
   };

   const saveShow = (e) => {
      e.preventDefault();
      history.goBack();
   };

   return (
      <>
         <Container {...props}>
            <Content as="form" onSubmit={saveShow}>
               <HeaderView title="Editar show en vivo"></HeaderView>
               <RowDiv>
                  <ColDiv colsm="12" colmd="8" marginRightMd={1} marginRightSm="0">
                     <Label marginBottom="0.5em">Datos generales</Label>
                  </ColDiv>
                  <ColDiv colsm="12" colmd="6" marginRightMd={1} marginRightSm={1}>
                     <Label marginBottom="0.5em">Título del evento</Label>
                     <Input readOnly={true} placeholder="Nombre del show / evento" name="title" defaultValue={infoData.title} />
                  </ColDiv>

                  <ColDiv colsm="5" colmd="4" marginRightMd={1} marginRightSm={1}>
                     <Label marginBottom="0.5em">Fecha del evento</Label>
                     <Input
                        readOnly={true}
                        placeholder="Nombre del show / evento"
                        name="date_event"
                        defaultValue={infoData.date_event}
                        type="date"
                     />
                  </ColDiv>
                  <ColDiv colsm="5" colmd="2" marginRightMd={1} marginRightSm={1}>
                     <Label marginBottom="0.5em">Hora de inicio</Label>
                     <Input
                        readOnly={true}
                        placeholder="Nombre del show / evento"
                        name="time_start"
                        defaultValue={infoData.time_start}
                        type="time"
                     />
                  </ColDiv>
                  <ColDiv colsm="5" colmd="2" marginRightMd={1} marginRightSm={1}>
                     <Label marginBottom="0.5em">Hora de termino</Label>
                     <Input
                        readOnly={true}
                        placeholder="Nombre del show / evento"
                        name="time_finish"
                        defaultValue={infoData.time_finish}
                        type="time"
                     />
                  </ColDiv>

                  <ColDiv colsm="5" colmd="7">
                     <Label marginBottom="0.5em">Descripción del evento</Label>
                     <Input
                        readOnly={true}
                        placeholder="Nombre del show / evento"
                        name="description"
                        as="textarea"
                        defaultValue={infoData.description}
                        height="150px"
                     />
                  </ColDiv>
               </RowDiv>

               <RowDiv>
                  <ColDiv colsm="12" colmd="8" marginRightMd={1} marginRightSm="0">
                     <Label marginBottom="0.5em">Imágenes</Label>
                  </ColDiv>
                  <Label></Label>

                  <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
                     <Label>Imagen para vista previa</Label>
                     <PreviewImg src={"http://freeticket.trebolbit.com/" + infoData.preview_img} alt="preview" />
                  </ColDiv>
                  <ColDiv colsm="12" colmd="6" padding="0 1em 0 0" />

                  <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
                     <Label>Imagen externa (APP)</Label>
                     <PreviewImg src={"http://freeticket.trebolbit.com/" + infoData.external_img} alt="external" />
                  </ColDiv>

                  <ColDiv colsm="12" colmd="6" padding="0 0 0 1em">
                     <Label>Imagen interna (APP)</Label>
                     <PreviewImg src={"http://freeticket.trebolbit.com/" + infoData.inner_img} alt="interna" />
                  </ColDiv>
               </RowDiv>
               <ColDiv colsm="12" colmd="3" marginLefttMd={3}>
                  <Button>REGRESAR</Button>
               </ColDiv>
            </Content>
         </Container>
         <AppLoader isVisible={isSaving} />
      </>
   );
}

const PreviewImg = styled.img`
   width: 90%;
`;
