import React, {Component} from "react";
import Logo from "../images/logo.png";
import {ReactComponent as Back} from "../icons/back.svg";
import {LoginView, LoginCard, FormContainer} from "./styles";
import {Input} from "../components/FormsConfig";
import Button from "../components/Button";
import Colors from "../constants/Colors";
import Text from "../components/Text";
import {recoverService} from "../helpers/Services";

class RecoverPassword extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showCard: true,
         showError: false,
      };
   }
   sendEmail = e => {
      e.preventDefault();
      const email = e.target.email;
      const data = {email: email.value};
      recoverService(data)
         .then(res => res.json())
         .then(resp => {
            const {status} = resp;
            if (status === "success") {
               this.setState({
                  showCard: false,
               });
            } else {
               this.setState({
                  showError: true,
                  // showCard: false,
               });
            }
         })
         .catch(() => {
            this.setState({
               netWork: true,
            });
         });
   };
   render() {
      const {showCard, showError, netWork} = this.state;
      return (
         <LoginView>
            {!showCard ? (
               <SuccessEmail {...this.props} />
            ) : (
               <LoginCard left={true}>
                  <div
                     className="actions"
                     onClick={() => {
                        this.props.history.goBack();
                     }}
                  >
                     <Back width="20px" height="20px" fill="white" className="icon" style={{marginRight: "0.5em"}} />
                     <Text color="white" size="1em">
                        Volver
                     </Text>
                  </div>
                  <div className="logoContainer">
                     <img src={Logo} width="40%" alt="freeticket" />
                  </div>
                  <FormContainer onSubmit={this.sendEmail}>
                     <Input type="email" name="email" border="white" color="white" placeholder="email" required />
                     {showError && (
                        <Text color="orangered" size="1em">
                           Revisa el correo que ingresaste
                        </Text>
                     )}
                     {netWork && (
                        <Text color="orangered" size="1em">
                           Error de red, intenta más tarde
                        </Text>
                     )}
                     <Button color={Colors.secondary} text={Colors.primary}>
                        Iniciar sesión
                     </Button>
                  </FormContainer>
               </LoginCard>
            )}
         </LoginView>
      );
   }
}

export default RecoverPassword;

const SuccessEmail = props => {
   return (
      <LoginCard left={true}>
         <Text color="white" size="1em" style={{textAlign: "center", marginBottom: "1em"}}>
            Revisa tu correo electronico y sigue las instrucciones para recuperar tu Contraseña
         </Text>
         <div
            className="actions"
            onClick={() => {
               props.history.goBack();
            }}
         >
            <Back width="20px" height="20px" fill="white" className="icon" style={{marginRight: "0.5em"}} />
            <Text color="white" size="1em">
               Volver
            </Text>
         </div>
      </LoginCard>
   );
};
