import React, {Component} from "react";
import {getInterestService, getToken, updateInterestService, deleteInterestService, postInterestService} from "../helpers/Services";

export const FavoritesContext = React.createContext();
export default class FavoritesProvider extends Component {
   constructor(props) {
      super(props);
      this.state = {
         favoritesData: [],
         // edit
         modalEditIsOpen: false,
         closeModallEdit: this.closeModallEdit,
         openModalEdit: this.openModalEdit,
         toEditFavorite: [],
         updateFavorite: this.updateFavorite,
         // add
         addFavorite: this.addFavorite,
         closeModalAdd: this.closeModalAdd,
         modalAddIsOpen: false,
         openModalAdd: this.openModalAdd,
         // delete
         deleteFavorite: this.deleteFavorite,
         // gral
         errorsForm: "",
         dataDelete: {},
         confirmIsOpen: false,
         closeConfirm: this.closeConfirm,
         confirmDelete: this.confirmDelete,
      };
   }

   UNSAFE_componentWillMount() {
      this.getFavorites();
   }

   getFavorites = () => {
      getToken().then(token => {
         const data = {token};
         getInterestService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               console.log(resp);
               if (status === "success") {
                  this.setState({
                     favoritesData: data,
                  });
               }
            })
            .catch(err => console.log(err));
      });
   };
   // add
   openModalAdd = () => {
      this.setState({modalAddIsOpen: true});
   };
   closeModalAdd = () => {
      this.setState({modalAddIsOpen: false, errorsForm: ""});
   };
   addFavorite = e => {
      e.preventDefault();
      const myForm = e.target;
      const {favoritesData} = this.state;
      const name = myForm.name.value;
      if (!name) return false;
      const status = Number(myForm.status.value);
      // crear item
      const newFavorite = {
         status,
         name,
      };
      const ExistFavorite = favoritesData.find(x => x.name.toLocaleLowerCase() === name.toLocaleLowerCase());
      if (ExistFavorite) {
         return this.setState({
            errorsForm: "Ya hay un tema con este nombre",
         });
      }

      getToken().then(token => {
         const data = {...newFavorite, token};
         postInterestService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {status, data} = resp;
               if (status === "success") {
                  const newFavorites = [...favoritesData, {...data}];
                  this.setState(
                     {
                        favoritesData: newFavorites,
                     },
                     () => {
                        this.closeModalAdd();
                     },
                  );
               }
            });
      });
   };
   // edit
   updateFavorite = e => {
      e.preventDefault();
      const myForm = e.target;
      const name = myForm.name.value;
      const status = Number(myForm.status.value);
      const id = Number(myForm.id.value);
      const {favoritesData} = this.state;
      const newItem = {name, status, id};
      const newFavorites = favoritesData.map(x => {
         if (x.id === id) {
            return {...newItem};
         }
         return x;
      });

      getToken().then(token => {
         const data = {...newItem, token};
         updateInterestService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {status} = resp;
               if (status === "success") {
                  this.setState(
                     {
                        favoritesData: newFavorites,
                     },
                     () => {
                        this.closeModallEdit();
                     },
                  );
               }
            })
            .catch(err => console.log(err));
      });
   };
   closeModallEdit = () => {
      this.setState({modalEditIsOpen: false, toEditFavorite: {}, errorsForm: ""});
   };
   openModalEdit = id => {
      const {favoritesData} = this.state;
      let toEditFavorite = favoritesData.find(x => Number(x.id) === Number(id));
      return this.setState({modalEditIsOpen: true, toEditFavorite: toEditFavorite});
   };

   confirmDelete = id => {
      const {favoritesData} = this.state;
      let deteteCity = favoritesData.find(x => Number(x.id) === Number(id));
      const dataDelete = {
         typeDelete: "tema",
         nameDelete: deteteCity.theme,
         id: id,
      };
      this.setState({
         dataDelete: dataDelete,
         confirmIsOpen: true,
      });
   };
   closeConfirm = () => {
      this.setState({
         dataDelete: [],
         confirmIsOpen: false,
      });
   };
   deleteFavorite = id => {
      getToken().then(token => {
         const data = {id, token};
         deleteInterestService(data)
            .then(resp => resp.json())
            .then(resp => {
               console.log(resp);
               const {status} = resp;
               if (status === "success") {
                  const {favoritesData} = this.state;
                  let newFavorites = favoritesData.filter(x => Number(x.id) !== Number(id));
                  this.setState(
                     {
                        favoritesData: newFavorites,
                     },
                     () => {
                        this.closeConfirm();
                     },
                  );
               } else {
                  console.log(resp);
               }
            })
            .catch(err => console.log(err));
      });
   };

   render() {
      return <FavoritesContext.Provider value={this.state}>{this.props.children}</FavoritesContext.Provider>;
   }
}
