/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useState, useEffect} from "react";
import HeaderView from "../../../components/toViews/HeaderView";
import Container from "../../../components/Container";
import {ColDiv, RowDiv} from "../../events/views/editComponents/EditStyled";
import {Label, Input} from "../../../components/FormsConfig";
import {Content} from "../Styles";
import {FieldFile} from "../../../components/Fields";
import Button from "../../../components/Button";
import {updateLiveService, infoLiveService} from "../../../services/LiveServices";
import {getToken} from "../../../helpers/Services";
import {LiveEventsContext} from "../../../providers/LiveEventsProvider";
import AppLoader from "../../../components/loader/AppLoader";
import {useHistory, useParams} from "react-router-dom";

export default function EditShowView(props) {
   const [isSaving, setSaving] = useState(false);
   const [infoData, setInfo] = useState({});
   const [errorsForm, setError] = useState("");
   const {updateEvent} = useContext(LiveEventsContext);
   let history = useHistory();
   let {live_event_id} = useParams();

   useEffect(() => {
      getInfoShow();
   }, [live_event_id]);

   const getInfoShow = () => {
      setSaving(true);
      infoLiveService({live_event_id})
         .then((res) => res.json())
         .then((resp) => {
            let {status, data} = resp;
            if (status === "error") throw new Error();
            let time_start = data.time_start.substring(0, 5);
            let time_finish = data.time_finish.substring(0, 5);
            setInfo({...data, time_start, time_finish});
         })
         .then(() => {
            setSaving(false);
         })
         .catch(() => {
            setSaving(false);
         });
   };

   const saveShow = (e) => {
      e.preventDefault();
      setSaving(true);
      let toSend = new FormData();
      const elements = e.target.elements;

      toSend.append("live_event_id", live_event_id);

      toSend.append("title", elements.title.value);
      toSend.append("streaming_link", elements.streaming_link.value);
      toSend.append("date_event", elements.date_event.value);
      toSend.append("date_created", infoData.date_created);
      toSend.append("time_start", elements.time_start.value);
      toSend.append("time_finish", elements.time_finish.value);
      toSend.append("description", elements.description.value);
      if (elements.external_img.files[0]) {
         toSend.append("external_img", elements.external_img.files[0]);
      }
      if (elements.inner_img.files[0]) {
         toSend.append("inner_img", elements.inner_img.files[0]);
      }
      if (elements.preview_img.files[0]) {
         toSend.append("preview_img", elements.preview_img.files[0]);
      }
      toSend.append("visibility", infoData.visibility);
      toSend.append("active", infoData.active);
      getToken().then((token) => {
         toSend.append("token", token);

         updateLiveService(toSend)
            .then((res) => res.json())
            .then((resp) => {
               let {status} = resp;
               console.log(resp);
               if (status === "error") throw new Error();
               console.log("ACRUAL");
               updateEvent(live_event_id);
            })
            .then(() => {
               setSaving(false);
            })
            .then(() => {
               history.goBack();
            })
            .catch(() => {
               setError("Algo salió mal, intenta más tarde");
               setSaving(false);
            });
      });
   };

   return (
      <>
         <Container {...props}>
            <Content as="form" onSubmit={saveShow}>
               <HeaderView title="Editar show en vivo"></HeaderView>
               <RowDiv>
                  <ColDiv colsm="12" colmd="8" marginRightMd={1} marginRightSm="0">
                     <Label marginBottom="0.5em">Datos generales</Label>
                  </ColDiv>
                  <ColDiv colsm="12" colmd="6" marginRightMd={1} marginRightSm={1}>
                     <Label marginBottom="0.5em">Título del evento</Label>
                     <Input placeholder="Nombre del show / evento" name="title" defaultValue={infoData.title} />
                  </ColDiv>

                  <ColDiv colsm="12" colmd="5">
                     <Label marginBottom="0.5em">Link del evento</Label>
                     <Input
                        placeholder="Link de video o streaming"
                        name="streaming_link"
                        defaultValue={infoData.streaming_link}
                        type="url"
                     />
                  </ColDiv>

                  <ColDiv colsm="5" colmd="4" marginRightMd={1} marginRightSm={1}>
                     <Label marginBottom="0.5em">Fecha del evento</Label>
                     <Input placeholder="Nombre del show / evento" name="date_event" defaultValue={infoData.date_event} type="date" />
                  </ColDiv>
                  <ColDiv colsm="5" colmd="2" marginRightMd={1} marginRightSm={1}>
                     <Label marginBottom="0.5em">Hora de inicio</Label>
                     <Input placeholder="Nombre del show / evento" name="time_start" defaultValue={infoData.time_start} type="time" />
                  </ColDiv>
                  <ColDiv colsm="5" colmd="2" marginRightMd={1} marginRightSm={1}>
                     <Label marginBottom="0.5em">Hora de termino</Label>
                     <Input placeholder="Nombre del show / evento" name="time_finish" defaultValue={infoData.time_finish} type="time" />
                  </ColDiv>

                  <ColDiv colsm="5" colmd="7">
                     <Label marginBottom="0.5em">Descripción del evento</Label>
                     <Input
                        placeholder="Nombre del show / evento"
                        name="description"
                        as="textarea"
                        defaultValue={infoData.description}
                        height="150px"
                     />
                  </ColDiv>
               </RowDiv>

               <RowDiv>
                  <ColDiv colsm="12" colmd="8" marginRightMd={1} marginRightSm="0">
                     <Label marginBottom="0.5em">Imágenes</Label>
                  </ColDiv>
                  <Label></Label>

                  <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
                     <Label>Imagen para vista previa</Label>
                     <FieldFile fileType=".jpg, .jpeg" name="preview_img">
                        <Label marginBottom="0" as="span">
                           Agregar vista previa
                        </Label>
                     </FieldFile>
                  </ColDiv>

                  <ColDiv colsm="12" colmd="6" padding="0 1em 0 0" />

                  <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
                     <Label>Imagen externa (APP)</Label>
                     <FieldFile fileType=".jpg, .jpeg" name="external_img">
                        <Label marginBottom="0" as="span">
                           Agregar nueva imagen externa
                        </Label>
                     </FieldFile>
                  </ColDiv>

                  <ColDiv colsm="12" colmd="6" padding="0 0 0 1em">
                     <Label>Imagen interna (APP)</Label>
                     <FieldFile fileType=".jpg, .jpeg" name="inner_img">
                        <Label marginBottom="0" as="span">
                           Agregar nueva imagen interna
                        </Label>
                     </FieldFile>
                  </ColDiv>
               </RowDiv>
               {errorsForm && <Label color="crimson">{errorsForm}</Label>}
               <ColDiv colsm="12" colmd="3" marginLefttMd={3}>
                  <Button>ACTUALIZAR</Button>
               </ColDiv>
            </Content>
         </Container>
         <AppLoader isVisible={isSaving} />
      </>
   );
}
