import React, {Component} from "react";
import {getEventsListService, getToken, userDataService} from "../helpers/Services";
import {sortMap} from "../helpers/All";

export const EventsContext = React.createContext();
export default class EventsProvider extends Component {
   constructor(props) {
      super(props);
      this.state = {
         eventsData: [],
         // edit
         modalEditIsOpen: false,
         closeModallEdit: this.closeModallEdit,
         openModalEdit: this.openModalEdit,
         toEditFavorite: [],
         updateEvent: this.updateEvent,
         visibilityEdit: this.visibilityEdit,
         // add
         addEvent: this.addEvent,
         closeModalAdd: this.closeModalAdd,
         modalAddIsOpen: false,
         openModalAdd: this.openModalAdd,
         // delete
         deleteEvent: this.deleteEvent,
         // gral
         errorsForm: "",
         dataDelete: {},
         confirmIsOpen: false,
         closeConfirm: this.closeConfirm,
         confirmDelete: this.confirmDelete,
      };
   }

   UNSAFE_componentWillMount() {
      this.getEventList();
   }

   getEventList = () => {
      getToken().then(token => {
         const data = {token};
         userDataService(data)
            .then(res => res.json())
            .then(resp => {
               const userData = resp.data;
               getEventsListService()
                  .then(resp => resp.json())
                  .then(resp => {
                     const {data, status} = resp;
                     //  userData.role_id === 3
                     const events = false ? data.filter(item => item.city_id === userData.city_id && Number(item.visibility) === 1) : data;
                     if (status === "success") {
                        this.setState({
                           eventsData: [...sortMap(events, "id").reverse()],
                        });
                     }
                  })
                  .catch(err => console.log(err));
            });
      });
   };

   openModalAdd = () => {
      this.setState({modalAddIsOpen: true});
   };
   closeModalAdd = () => {
      this.setState({modalAddIsOpen: false, errorsForm: ""});
   };

   addEvent = e => {
      e.preventDefault();
      const myForm = e.target;
      const {eventsData} = this.state;
      const newEvent = myForm.event.value;
      if (!newEvent) return false;
      const newStatus = Number(myForm.status.value);
      // crear item
      const newEvents = [...eventsData, {id: eventsData.length + 1, event: newEvent, status: newStatus}];
      this.setState(
         {
            eventsData: newEvents,
         },
         () => {
            this.closeModalAdd();
         },
      );
   };

   // edit
   updateEvent = (e, lastData) => {
      e.preventDefault();
      const myForm = e.target;
      const newEvent = myForm.event.value;
      const newStatus = Number(myForm.status.value);
      const newVisibility = Number(myForm.visibility.value);
      const currentId = lastData.id;
      const {eventsData} = this.state;
      const newItem = {...eventsData[currentId], event: newEvent, status: newStatus, visibility: newVisibility};
      const newEvents = eventsData.map(x => {
         if (x.id === currentId) {
            return {...newItem};
         }
         return x;
      });
      this.setState(
         {
            eventsData: newEvents,
         },
         () => {
            this.closeModallEdit();
         },
      );
   };
   visibilityEdit = (id, val) => {
      const {eventsData} = this.state;
      const newEvents = Object.keys(eventsData).map(x => {
         if (Number(eventsData[x].id) === Number(id)) {
            return {...eventsData[x], visibility: val};
         }
         return eventsData[x];
      });
      this.setState({
         eventsData: newEvents,
      });
   };
   closeModallEdit = () => {
      this.setState({modalEditIsOpen: false, toEditEvent: {}, errorsForm: ""}, () => {});
   };
   openModalEdit = id => {
      const {eventsData} = this.state;
      let toEditEvent = eventsData.find(x => Number(x.id) === Number(id));
      return this.setState({modalEditIsOpen: true, toEditEvent: toEditEvent});
   };

   confirmDelete = id => {
      const {eventsData} = this.state;
      let deteteCity = eventsData.find(x => Number(x.id) === Number(id));
      const dataDelete = {
         typeDelete: "event",
         nameDelete: deteteCity.event,
         id: id,
      };
      this.setState({
         dataDelete: dataDelete,
         confirmIsOpen: true,
      });
   };
   closeConfirm = () => {
      this.setState({
         dataDelete: [],
         confirmIsOpen: false,
      });
   };
   deleteEvent = id => {
      const {eventsData} = this.state;
      let newEvents = eventsData.filter(x => Number(x.id) !== Number(id));
      this.setState(
         {
            eventsData: newEvents,
         },
         () => {
            this.closeConfirm();
         },
      );
   };

   render() {
      return <EventsContext.Provider value={this.state}>{this.props.children}</EventsContext.Provider>;
   }
}
