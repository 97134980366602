import React from "react";
import styled from "styled-components";
import device from "../constants/device";
import {ReactComponent as Add} from "./images/plus.svg";
import Colors from "../constants/Colors";

const Button = styled.button`
   font-size: ${(props) => props.theme.normalText};
   margin: ${(props) => props.margin || "1em"};
   width: ${(props) => props.width || ""};
   padding: 0.5em 1em;
   border-radius: 4px;
   background-color: ${(props) => props.color || props.theme.primary};
   color: ${(props) => props.text || "white"};
   border: none;
   user-select: none;
   outline: none;
   cursor: pointer;
   font-weight: 500;
   :hover {
      opacity: ${device.isTouch ? 1 : 0.8};
   }
   :active {
      opacity: 0.8;
      transform: scale(0.91);
   }
   @media screen and (max-width: 500px) {
   }
`;

export const ResetButton = styled.button`
   padding: 0.5em;
   border-radius: 3px;
   background-color: ${(props) => props.color || props.theme.primary};
   color: ${(props) => props.text || "white"};
   border: none;
   user-select: none;
   outline: none;
   cursor: pointer;
   :active {
      opacity: 0.8;
      transform: scale(0.91);
   }
   :hover {
      transform: scale(1.09);
   }
`;
const AddButtonStyled = styled(Button)`
   width: 30px;
   height: 30px;
   background-color: ${(props) => props.color || props.theme.secondary};
   box-shadow: 1px 1px 3px 1px rgba(10, 10, 10, 0.4);
   justify-content: center;
   align-items: center;
   text-align: center;
   padding: 0;
   border: none;
   margin: 0;
   box-sizing: border-box;
   :hover {
      opacity: ${device.isTouch ? 1 : 0.8};
   }
   :active {
      opacity: 0.8;
      transform: scale(0.91);
   }
   & .icons {
      line-height: 30px;
   }
`;
export const AddButton = (props) => (
   <AddButtonStyled {...props}>
      <Add width="20px" height="20px" fill={Colors.primary} alt="+" className="icon" />
   </AddButtonStyled>
);

export default Button;

export const AddTextButton = (props) => (
   <AddButtonTextStyled {...props}>
      <span>{props.title}</span>
   </AddButtonTextStyled>
);

const AddButtonTextStyled = styled(AddButtonStyled)`
   width: auto;
   padding-left: 1em;
   padding-right: 1em;
   background-color: ${Colors.primary};
   margin-right: 1em;

   & .span {
      line-height: 20px;
   }
`;
