import React, {Component} from "react";
import Container from "../../../components/Container";
import HeaderView from "../../../components/toViews/HeaderView";
import AppLoader from "../../../components/loader/AppLoader";
import {FormEdit} from "./editComponents/EditStyled";
import {getEventInfoService, getToken, getInterestService, postEventService, updateEventService} from "../../../helpers/Services";
import FormEvent from "./editComponents/FormEvent";
import {GlobalContext} from "../../../providers/GlobalProvider";

const addTheme = (id, interest) => {
   if (interest.indexOf(id) === -1) {
      let newInteres = [...interest, id];
      return newInteres;
   }
   return interest;
};
const removeTheme = (id, interest) => {
   let newInteres = interest.filter(x => Number(x) !== Number(id));
   return newInteres;
};

class EditEventView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         files: [],
         dataEvent: [],
         eventId: this.props.match.params.id,
         AllCities: [],
         // citiesCountry: [],
         interest: [],
         activeTemes: [],
      };
   }
   UNSAFE_componentWillMount() {
      this.getInterest();
   }
   componentDidMount() {
      this.getDataEvent();
   }
   getDataEvent = () => {
      const {eventId} = this.state;
      if (eventId === "add") {
         this.setState(
            {
               dataEvent: {},
            },
            () => {
               this.getCities();
            },
         );
         return false;
      }
      getToken().then(token => {
         let data = {id: Number(eventId)};
         getEventInfoService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               if (status === "success") {
                  let interest = data.event_data.interests.map(item => {
                     return item.id_interest;
                  });
                  data.event_data.time = data.event_data.time ? data.event_data.time.substr(0, 5) : "00:00";
                  this.setState(
                     {
                        dataEvent: data.event_data,
                        interest,
                     },
                     () => {
                        this.getCities();
                     },
                  );
               }
            });
      });
   };
   getCities = () => {
      const {citiesData} = this.props;
      const AllCities = citiesData.filter(item => Number(item.status) === 1);
      const citiesCountry = AllCities.filter(item => Number(item.country_id) === Number(this.state.dataEvent.country_id));
      this.setState({
         AllCities,
         citiesCountry,
         isLoaded: true,
      });
   };
   getInterest = () => {
      const data = {token: sessionStorage.getItem("token")};
      getInterestService(data)
         .then(res => res.json())
         .then(resp => {
            const {data, status} = resp;
            if (status === "success") {
               const activeTemes = data.filter(item => Number(item.status) === 1);
               this.setState({
                  activeTemes,
               });
            }
         });
   };
   createService = e => {
      e.preventDefault();
      this.setState({
         isUpdating: true,
      });
      const elements = e.target.elements;
      const {interest, eventId} = this.state;
      var data = {status: 1};
      for (let ele in elements) {
         data = {...data, [elements[ele].name]: elements[ele].type === "file" ? elements[ele].files[0] : elements[ele].value};
      }
      getToken().then(token => {
         const dataEn = {
            city: data.city,
            country_id: data.country_id,
            date: data.date,
            delivered: data.delivered,
            description: data.description,
            external_img: data.external_img,
            inner_img: data.inner_img,
            location: data.location,
            movie_theater: data.movie_theater,
            cinema_type: data.cinema_type,
            status: data.status,
            time: data.time,
            title: data.title,
            type: data.type,
            visibility: data.visibility,
            interests: JSON.stringify(interest),
         };

         let formData = new FormData();
         formData.append("city", dataEn.city);
         formData.append("country_id", dataEn.country_id);
         formData.append("date", dataEn.date);
         formData.append("delivered", dataEn.delivered);
         formData.append("description", dataEn.description);
         if (dataEn.external_img) {
            console.log("xisis, exte");
            formData.append("external_img", dataEn.external_img);
         }
         if (dataEn.inner_img) {
            formData.append("inner_img", dataEn.inner_img);
         }
         formData.append("location", dataEn.location);
         formData.append("status", dataEn.status);
         formData.append("time", dataEn.time);
         formData.append("title", dataEn.title);
         formData.append("type", dataEn.type);
         formData.append("visibility", dataEn.visibility);
         formData.append("movie_theater", dataEn.movie_theater);
         formData.append("cinema_type", dataEn.cinema_type);
         formData.append("interests", JSON.stringify(interest));
         formData.append("token", token);

         setTimeout(() => {
            if (eventId === "add") {
               this.saveEvent(formData);
            } else {
               formData.append("id", eventId);
               this.updateEvent(formData);
            }
         }, 200);
      });
   };
   saveEvent = data => {
      postEventService(data)
         .then(res => res.json())
         .then(resp => {
            console.log(resp);
            this.setState({
               isUpdating: false,
            });
            if (resp.status === "success") {
               this.props.history.goBack();
            }
         });
   };
   updateEvent = data => {
      updateEventService(data)
         .then(res => res.json())
         .then(resp => {
            console.log(resp);
            this.setState({
               isUpdating: false,
            });
         });
   };
   changeCountry = e => {
      const newCountry = e.target.value;
      this.filterCities(newCountry);
   };
   filterCities = newCountry => {
      const {AllCities} = this.state;
      const citiesCountry = AllCities.filter(item => Number(item.country_id) === Number(newCountry));
      this.setState({
         citiesCountry,
      });
   };
   releaseEvent = () => {
      // hacer visible
      console.log("liberar evento");
   };
   handleDrop = files => {
      let fileList = this.state.files;
      for (var i = 0; i < files.length; i++) {
         if (!files[i].name) return;
         fileList.push(files[i].name);
      }
      this.setState({files: fileList});
   };
   editInterest = id => {
      const {interest} = this.state;
      const exist = interest.indexOf(id) > -1;
      const newInterest = !exist ? addTheme(id, interest) : removeTheme(id, interest);
      this.setState({
         interest: newInterest,
      });
   };
   render() {
      const {dataEvent, eventId, citiesCountry, activeTemes, interest, isLoaded, isUpdating} = this.state;
      return (
         <Container {...this.props} showBack={true}>
            <FormEdit>
               <HeaderView title={dataEvent.title ? `Editar evento: ${dataEvent.title}` : `Crear evento`}></HeaderView>
               {isLoaded && (
                  <FormEvent
                     interest={interest}
                     activeTemes={activeTemes}
                     citiesCountry={citiesCountry}
                     eventId={eventId}
                     dataEvent={dataEvent}
                     changeCountry={this.changeCountry}
                     saveEvent={this.createService}
                     editInterest={this.editInterest}
                     setMovie={val => {
                        console.log("movei", val);
                        this.setState({
                           dataEvent: {...dataEvent, movie_theater: val},
                        });
                     }}
                     updateDesc={val => {
                        const {dataEvent} = this.state;
                        this.setState({
                           dataEvent: {...dataEvent, description: val},
                        });
                     }}
                  />
               )}
               <AppLoader title="evento" isVisible={isUpdating} />
               {/* Evento */}
               {/* <AppLoader title="evento" isVisible={!loaded} /> */}
            </FormEdit>
         </Container>
      );
   }
}

// const EditEvent = props => <EventsContext.Consumer>{() => <EditEventView {...props} />}</EventsContext.Consumer>;

const EditEvent = props => (
   <GlobalContext.Consumer>
      {({citiesData}) => {
         if (!citiesData) return <AppLoader />;
         return <EditEventView {...props} citiesData={citiesData} />;
      }}
   </GlobalContext.Consumer>
);

export default EditEvent;
