import {apiUrl} from "../helpers/Services";

export const listLiveEventsService = () => {
   const service = `${apiUrl}live_event/list`;
   return fetch(service);
};
export const listFutureService = () => {
   const service = `${apiUrl}live_event/list_future`;
   return fetch(service);
};
export const listTodayService = () => {
   const service = `${apiUrl}live_event/list_today`;
   return fetch(service);
};
export const listPastService = () => {
   const service = `${apiUrl}live_event/list_past`;
   return fetch(service);
};
export const createLiveService = (data) => {
   const service = `${apiUrl}live_event/create`;
   const options = {
      method: "POST",
      //mode: "no-cors",
      body: data,
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };
   delete options.headers["Content-Type"];
   return fetch(service, options);
};
export const updateLiveService = (data) => {
   const service = `${apiUrl}live_event/update`;
   const options = {
      method: "POST",
      //mode: "no-cors",
      body: data,
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };
   delete options.headers["Content-Type"];
   return fetch(service, options);
};
export const infoLiveService = (data) => {
   const service = `${apiUrl}live_event/info`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

export const toggleVisibleservice = (data) => {
   const service = `${apiUrl}live_event/visible`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const finish_eventService = (data) => {
   const service = `${apiUrl}live_event/finish_event`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const start_eventService = (data) => {
   const service = `${apiUrl}live_event/start_event`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};

// tiket
export const liveTicektCreateService = (data) => {
   const service = `${apiUrl}live_ticket/create`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
// tickets universlaes
export const universalTicketCreateService = (data) => {
   const service = `${apiUrl}live_ticket/create_universal`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const universalTicketListService = (data) => {
   const service = `${apiUrl}live_ticket/list_universal`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
export const universalTicketRemoveService = (data) => {
   const service = `${apiUrl}live_ticket/remove_universal`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
// asistencia por evento
export const liveAssistanceService = (data) => {
   const service = `${apiUrl}live_attendance/live_attendance_event`;
   return fetch(service, {
      method: "POST",
      body: JSON.stringify({...data}),
      headers: {
         "Content-Type": "application/json",
      },
   });
};
