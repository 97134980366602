import React, {useContext, useEffect} from "react";
import {useParams} from "react-router-dom";
import {LiveTicketsContext} from "../../../providers/LiveTicketsProvider";
import Container from "../../../components/Container";
import {Content} from "../Styles";
import HeaderView from "../../../components/toViews/HeaderView";
import Table from "../../../components/table/Tables";

export default function LiveAttendanceView(props) {
   const {getAssistenceList, assistenceList} = useContext(LiveTicketsContext);
   let {live_event_id} = useParams();

   useEffect(() => { 
      getAssistenceList(live_event_id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [live_event_id]);

   return (
      <Container {...props}>
         <Content>
            <HeaderView title="Lista de asistencia" />
            <Table data={assistenceList.data} noDataText="No hay asistencia de evento" columns={columnsTable} />
         </Content>
      </Container>
   );
}

const columnsTable = [
   {
      title: "id ",
      accessor: "id",
   }, 
   {
      title: "Nombre",
      accessor: "first_name",
   },
   {
      title: "Apellido",
      accessor: "last_name",
   },
   {
      title: "Type",
      accessor: "type",
   },
   {
      title: "Código",
      accessor: "code",
   },
];
