import React from "react";
import {RowDiv, ColDiv} from "../../editComponents/EditStyled";
import {Label, Input} from "../../../../../components/FormsConfig";
import Button from "../../../../../components/Button";

export default function CreateTicketForm({action, error}) {
   return (
      <RowDiv>
         <ColDiv colsm="12" colmd="6">
            <Label>Generar códigos de regalo para este evento</Label>
            <form onSubmit={action} name="formFree">
               <Input placeholder="Cantidad de códigos para generar" type="number" name="num" max="500" />
               <Button>Generar</Button>
               <Label color="crimson">{error}</Label>
            </form>
         </ColDiv>
      </RowDiv>
   );
}
