import React from "react";
import GlobalModal from "../../components/GlobalModal";
import {TitleModal, BodyModal, ContentButtons} from "../../components/ModalConfig";
import HeadModal from "../../components/modal/HeadModal";
import {Select, Input} from "../../components/FormsConfig";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import {CitiesContext} from "../../providers/CititesProvider";
import Text from "../../components/Text";

export default AddCity => {
   return (
      <CitiesContext.Consumer>
         {({modalAddIsOpen, closeModalAdd, addCity, errorsForm}) => (
            <GlobalModal modalIsOpen={modalAddIsOpen} closeModal={closeModalAdd} widthCard="50%">
               <HeadModal closeModal={closeModalAdd}>
                  <TitleModal>Agregar ciudad</TitleModal>
               </HeadModal>
               {modalAddIsOpen && (
                  <BodyModal>
                     <form
                        onSubmit={e => {
                           addCity(e);
                        }}
                     >
                        <Select name="status">
                           <option value={0}>No publicada</option>
                           <option value={1}>Publicada</option>
                        </Select>
                        <Select name="country_id">
                           <option value={1}>México</option>
                           <option value={2}>Colombia</option>
                        </Select>
                        <Input type="text" placeholder="Nombre de la ciudad" name="name" />
                        {errorsForm && <Text color="crimson">{errorsForm}</Text>}
                        <ContentButtons>
                           <Button color="crimson" onClick={closeModalAdd} as="div">
                              CERRAR
                           </Button>
                           <Button text={Colors.secondary}>GUARDAR</Button>
                        </ContentButtons>
                     </form>
                  </BodyModal>
               )}
            </GlobalModal>
         )}
      </CitiesContext.Consumer>
   );
};
