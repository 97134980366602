import React, {Component} from "react";
import styled from "styled-components";
import logo from "../../images/logo.png";
import Complete from "./Complete";
import device from "../../constants/device";
import MobileMenu from "./Mobile";
import {GlobalContext} from "../../providers/GlobalProvider";

const MenuComplete = styled.nav`
   height: 100%;
   background-color: ${props => props.theme.primary};
   padding: 1em 0;
   z-index: ${device.isSmarthphone ? 20 : 0};
`;
const ImageContainer = styled.div`
   user-select: none;
   width: ${props => (props.isComplete ? "70px" : "70px")};
   margin: 0 auto;
`;

export default class Menu extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isComplete: true,
         isSmallDevice: device.isSmarthphone,
         isOpen: true,
      };
   }
   componentDidMount() {
      this.getPreference();
   }
   changeMode = val => {
      this.setState(
         {
            isComplete: val,
         },
         () => {
            localStorage.setItem("isComplete", val);
         },
      );
   };
   getPreference = () => {
      const menuPreference = localStorage.getItem("isComplete");
      this.setState({
         isComplete: menuPreference !== "false",
      });
   };
   render() {
      const {isComplete, isSmallDevice} = this.state;
      return (
         <GlobalContext.Consumer>
            {({isOpen, toggleMenu}) => {
               if (isSmallDevice) {
                  return <MobileMenu isOpen={isOpen} close={() => toggleMenu(false)} />;
               }
               return (
                  <MenuComplete>  
                     <ImageContainer isComplete={isComplete}>
                        <img src={logo} width="100%" alt="freeticket" />
                     </ImageContainer>
                     <Complete changeMode={this.changeMode} iscomplete={isComplete} />
                  </MenuComplete>
               );
            }}
         </GlobalContext.Consumer>
      );
   }
}
