/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from "react";
import {LiveEventsContext} from "../../../providers/LiveEventsProvider";
import {EventsListContainer} from "../Styles";
// import EventComponent from "../components/EventCardComponent";
import TodayEvent from "../components/TodayEvent";
import Text from "../../../components/Text";
// import EventComponent from "../../components/EventCardComponent";

export default function TodayEvents() {
   const {listTodayEvents, getTodayEvents} = useContext(LiveEventsContext);

   useEffect(() => {
      getTodayEvents();
   }, []); 
   return (
      <>
         <EventsListContainer>
            {React.Children.toArray( 
               listTodayEvents.data.map((liveEvent) => {
                  return <TodayEvent data={liveEvent} type="today" />;
               }),
            )} 
            {listTodayEvents.data.length === 0 && <Text>No hay eventos para hoy</Text>}
         </EventsListContainer>
      </>
   );
}
