import React from "react";
import GlobalModal from "./GlobalModal";
import HeadModal from "./modal/HeadModal";
import {ContentButtons, BodyModal, TitleModal} from "./ModalConfig";
import Button from "./Button";
import Colors from "../constants/Colors";

export default ({confirmIsOpen, closeConfirm, dataDelete, action}) => {
   return (
      <GlobalModal modalIsOpen={confirmIsOpen} closeModal={closeConfirm} widthCard="50%">
         <HeadModal closeModal={closeConfirm}>
            <TitleModal>Eliminar {dataDelete.typeDelete}</TitleModal>
         </HeadModal>
         {confirmIsOpen && (
            <BodyModal>
               <TitleModal>¿Quieres eliminar {dataDelete.nameDelete}?</TitleModal>
               <ContentButtons>
                  <Button onClick={closeConfirm} as="div" text={Colors.secondary}>
                     CONSERVAR
                  </Button>
                  <Button
                     color="crimson"
                     text="white"
                     onClick={() => {
                        action(dataDelete.id);
                     }}
                  >
                     ELIMINAR
                  </Button>
               </ContentButtons>
            </BodyModal>
         )}
      </GlobalModal>
   );
};
