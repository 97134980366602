import React, {Component} from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import Text from "../Text";
import {ReactComponent as Cities} from "./images/city.svg";
import {ReactComponent as Events} from "./images/ticket.svg";
import {ReactComponent as Notifications} from "./images/notification.svg";
import {ReactComponent as Users} from "./images/users.svg";
import {ReactComponent as Toggle} from "./images/toggle.svg";
import {ReactComponent as Favorite} from "./images/favorite.svg";
import {ReactComponent as Membership} from "./images/membership.svg";
import {ReactComponent as LiveIcon} from "./images/live.svg";
import Colors from "../../constants/Colors";
import device from "../../constants/device";
import {GlobalContext} from "../../providers/GlobalProvider";
import userPermission from "../../constants/userPermissions";
import AppLoader from "../loader/AppLoader";

const linkGroup = [
   {
      text: "Ciudades",
      iconImage: Cities,
      to: "/ciudades",
   },
   {
      text: "Temas de interés",
      iconImage: Favorite,
      to: "/intereses",
   },
   {
      text: "Usuarios",
      iconImage: Users,
      to: "/usuarios",
   },
   {
      text: "Membresias",
      iconImage: Membership,
      to: "/membresias",
      subroutes: [
         {
            name: "Membresías",
            path: "/membresias/list",
         },
         {
            name: "Códigos",
            path: "/membresias/codes",
         },
      ],
   },
   {
      text: "Eventos",
      iconImage: Events,
      to: "/eventos",
   },
   {
      text: "En Vivo",
      iconImage: LiveIcon,
      to: "/live_events",
   },
   /* {
      text: "Checador",
    
      iconImage: CheckerIcon,
      to: "/checadores",
   }, */
   {
      text: "Notificaciones",
      iconImage: Notifications,
      to: "/notificaciones",
   },

   /*    {
      text: "Planes",
      iconImage: Plans,
      to: "/planes",
   }, */
];

const MenuComplete = styled.div`
   height: 100vh;
   background-color: ${(props) => props.theme.primary};
   padding: 1em 0;
`;
const List = styled.ul`
   padding: 1em 0;
   list-style: none;
`;

const Detail = styled.details`
   padding-left: 0;
   background-image: none;
   -webkit-appearance: none;
   color: white;
   outline: none;

   & summary {
      outline: none;
      cursor: pointer;
      &::-webkit-details-marker {
         display: none;
      }
   }

   ul {
      padding-left: 1em;
   }
`;

const LabelText = styled(Text)`
   font-size: ${(props) => (device.isSmarthphone ? "1.2em" : props.iscomplete ? "1em" : "0")};
   transition: all 120ms linear;
   color: rgba(250, 250, 250, 0.6);
   white-space: nowrap;
   position: relative;
`;
const ListItem = styled(NavLink)`
   display: flex;
   align-items: center;
   margin: 0.3em 0;
   justify-content: flex-start;
   position: relative;
   padding: 10px;
   & span {
      background-color: ${Colors.secondary};
      width: ${(props) => (props.iscomplete ? "0" : "100%")};
      height: ${(props) => (props.iscomplete ? "1px" : "0")};
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 440ms ease;
   }

   &.subRoute {
      border-bottom: 1px solid transparent;
   }

   &.selected {
      &.subRoute {
         border-bottom-color: ${Colors.secondary};
      }
      span {
         width: 100%;
         height: ${(props) => (props.iscomplete ? "1px" : "100%")};
         transition: all 440ms ease;
      }
   }
   & .icon {
      margin: 0 10px;
      padding: 0em;
   }
   &:hover {
      background-color: rgba(250, 250, 250, 0.03);
   }

   & .icon {
      fill: rgba(250, 250, 250, 0.6);
      position: relative;
   }
   &:hover .icon {
      fill: rgba(250, 250, 250, 1);
   }

   &.selected .icon {
      fill: ${(props) => (props.iscomplete ? "rgba(250, 250, 250, 1)" : Colors.primary)};
   }
   &.selected ${LabelText}, &:hover ${LabelText} {
      color: rgba(250, 250, 250, 1);
   }
`;
const IconClose = styled.div`
   transform: ${(props) => (props.iscomplete ? "rotate(90deg)" : "rotate(270deg)")};
`;
const CloseContent = styled(ListItem)`
   background-color: transparent;
   outline: none;
   border: none;
   width: 100%;
`;

class CompleteComponent extends Component {
   constructor(props) {
      super(props);
      this.state = {
         links: linkGroup,
      };
   }
   render() {
      const {iscomplete, userData} = this.props;
      if (!userData) return <AppLoader isVisible={true} />;
      return (
         <MenuComplete>
            <List>
               {Object.keys(this.state.links).map((x) => {
                  const item = this.state.links[x];
                  const IconLink = item.iconImage;
                  const perm = userPermission.find((permi) => permi.path === item.to);
                  const hasPermission = JSON.stringify(perm.permission).indexOf(userData.role_id) > -1;
                  if (item.subroutes && hasPermission) {
                     return (
                        <Detail key={item.text}>
                           <ListItem
                              as="summary"
                              activeClassName="selected"
                              to={item.to || ""}
                              iscomplete={iscomplete.toString()}
                              title={item.text}
                              onClick={() => {
                                 this.props.toggleMenu(false);
                                 this.props.changeMode(true);
                              }}
                           >
                              <span />
                              <IconLink alt={item.text} height="20px" width="20px" className="icon" />
                              <LabelText iscomplete={iscomplete}>{item.text}</LabelText>
                           </ListItem>

                           {iscomplete && (
                              <ul>
                                 {item.subroutes.map((subitem, index) => (
                                    <ListItem
                                       iscomplete={iscomplete.toString()}
                                       to={subitem.path}
                                       activeClassName="selected"
                                       className="subRoute"
                                       key={subitem.name}
                                    >
                                       <LabelText iscomplete={true}>{subitem.name}</LabelText>
                                    </ListItem>
                                 ))}
                              </ul>
                           )}
                        </Detail>
                     );
                  }
                  if (hasPermission) {
                     return (
                        <ListItem
                           activeClassName="selected"
                           to={item.to || ""}
                           iscomplete={iscomplete.toString()}
                           title={item.text}
                           key={x}
                           onClick={() => this.props.toggleMenu(false)}
                        >
                           <span />
                           <IconLink alt={item.text} height="20px" width="20px" className="icon" />
                           <LabelText iscomplete={iscomplete}>{item.text}</LabelText>
                        </ListItem>
                     );
                  }
                  return null;
               })}
               {this.props.changeMode && (
                  <CloseContent
                     to="/"
                     iscomplete={iscomplete}
                     onClick={() => {
                        this.props.changeMode(!iscomplete);
                     }}
                     as="button"
                  >
                     <IconClose iscomplete={iscomplete}>
                        <Toggle alt="eventos" height="20px" width="20px" className="icon" />
                     </IconClose>
                     <LabelText iscomplete={iscomplete}>Cerrar</LabelText>
                  </CloseContent>
               )}
            </List>
         </MenuComplete>
      );
   }
}
const Complete = (props) => (
   <GlobalContext.Consumer>
      {({userData, toggleMenu}) => <CompleteComponent userData={userData} {...props} toggleMenu={toggleMenu} />}
   </GlobalContext.Consumer>
);
export default Complete;
