export function eventOnTime(date, start, end, type) {
   // analiza si el evento es pasado o futuro
   if (type) return "onair";
   const current = new Date();
   let eventStart = new Date(date + " " + start);
   let eventEnd = new Date(date + " " + end);
   // eventDate = eventDate.setTime(start);
   if (current.getTime() < eventStart.getTime()) {
      // la fecha actual es menor a la fecha de inicio
      if (eventStart.getMonth() === current.getMonth()) {
         // mismo mes
         if (eventStart.getDate() === current.getDate()) {
            // mismo día
            return "next";
         }
      }
      return "future";
   } else if (current.getTime() === eventStart.getTime() && current.getTime() <= eventEnd.getTime()) {
      return "onair";
   } else {
      return "past";
   }
   // la fecha actual es mayor a la fecha de inicio

   // console.log(current.getTime(), eventStart.getTime(), eventEnd.getTime());
   // return "onair";
}
