import React, {Component} from "react";
import Container from "../components/Container";
import FavoritesProvider, {FavoritesContext} from "../providers/FavoritesProvider";
import TableFavorites from "./favorites/TableFavorites";
import AddFavoriteModal from "./favorites/AddFavoriteModal";
import ConfirmDelete from "../components/ConfirmDelete";
import EditFavoriteModal from "./favorites/EditFavoriteModal";

export default class Favorites extends Component {
   render() {
      return (
         <Container {...this.props}>
            <FavoritesProvider>
               <FavoritesContext.Consumer>
                  {({
                     favoritesData,
                     deleteFavorite,
                     openModalAdd,
                     closeModallEdit,
                     openModalEdit,
                     confirmDelete,
                     closeConfirm,
                     dataDelete,
                     confirmIsOpen,
                  }) => (
                     <>
                        <TableFavorites
                           favoritesData={favoritesData}
                           closeModallEdit={closeModallEdit}
                           openModalEdit={openModalEdit}
                           openModalAdd={openModalAdd}
                           deleteCity={confirmDelete}
                        />
                        <AddFavoriteModal />
                        <EditFavoriteModal />
                        <ConfirmDelete
                           dataDelete={dataDelete}
                           closeConfirm={closeConfirm}
                           confirmIsOpen={confirmIsOpen}
                           action={deleteFavorite}
                        />
                     </>
                  )}
               </FavoritesContext.Consumer>
            </FavoritesProvider>
         </Container>
      );
   }
}
