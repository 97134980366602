import React, {Component} from "react";
import ModalLoader from "./newPassword/ModalLoader";
import {LoginView, FormContainer, LoginCard} from "../auth/styles";
import {Input, Label} from "../components/FormsConfig";
import Logo from "../images/logo.png";
import Button from "../components/Button";
import Colors from "../constants/Colors";
import {userDataService} from "../helpers/Services";
import base64 from "base-64";

export default class NewPassword extends Component {
   constructor(props) {
      super(props);
      this.state = {
         type: "load",
      };
   }
   UNSAFE_componentWillMount() {
      this.sessionValidate();
   }
   sessionValidate = () => {
      let params = new URLSearchParams(this.props.location.search);
      const token = params.get("token");
      const decoded = base64.decode(token);
      this.setState({
         token: decoded,
      });
      const data = {token: decoded};
      userDataService(data)
         .then(res => res.json())
         .then(resp => {
            const {status, data} = resp;
            if (status === "success") {
               this.setState({
                  type: "success",
                  userData: {...data, password: ""},
               });
            } else {
               this.setState({
                  type: "expired",
               });
            }
         })
         .catch(err => {
            console.log(err);
            this.setState({
               type: "error",
            });
         });
   };
   changePassword = e => {
      e.preventDefault();
      this.setState({
         equal: false,
         minLegth: false,
      });
      const password = e.target.password.value;
      const passwordConfirm = e.target.passwordConfirm.value;
      if (password !== passwordConfirm) {
         this.setState({
            equal: true,
         });
         return false;
      }
      if (password.length < 8) {
         this.setState({
            minLegth: true,
         });
         return false;
      }
      const {token} = this.state;
      if (!token) {
         this.setState({
            type: "error",
         });
         return false;
      }
      const data = {
         token,
         password,
      };
      const service = "http://freeticket.trebolbit.com/api/user/change_password";
      fetch(service, {
         method: "POST",
         body: JSON.stringify({...data}),
         headers: {
            "Content-Type": "application/json",
         },
      })
         .then(res => res.json())
         .then(resp => {
            const {status} = resp;
            if (status === "success") {
               this.setState({
                  type: "change",
                  token: "",
                  userData: "",
               });
            } else {
               this.setState({
                  type: "error",
                  token: "",
               });
            }
         })
         .catch(() => {
            this.setState({
               type: "error",
               userData: "",
            });
         });
   };
   render() {
      const {type, minLegth, equal} = this.state;
      return (
         <>
            <LoginView img="concert.jpg" opacity="0.7">
               <LoginCard marginAction="1em">
                  <div className="logoContainer">
                     <img src={Logo} width="40%" alt="freeticket" />
                  </div>
                  <FormContainer onSubmit={this.changePassword}>
                     <Input type="password" name="password" border="white" color="white" placeholder="Nueva contraseña" required />
                     <Input
                        type="password"
                        name="passwordConfirm"
                        border="white"
                        color="white"
                        placeholder="Repite la contraseña"
                        required
                     />
                     {minLegth && (
                        <Label color="red" size="0.8em">
                           La contraseña debe contener por lo menos 8 letras
                        </Label>
                     )}
                     {equal && (
                        <Label color="red" size="0.8em">
                           La contraseña no coincide
                        </Label>
                     )}
                     <Button color={Colors.secondary} text={Colors.primary}>
                        CAMBIAR
                     </Button>
                  </FormContainer>
               </LoginCard>
               <ModalLoader type={type} />
            </LoginView>
         </>
      );
   }
}
