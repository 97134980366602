import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {ResetButton} from "./Buttons";

export default function Pager({max, current, pageLength, updatePage}) {
   const [visiblePages, setPages] = useState([]);
   const [numPages, setNumPages] = useState(1);

   useEffect(() => {
      const numP = Math.ceil(max / pageLength); // + (max % pageLength > 0 ? 1 : 0);
      setNumPages(numP);
   }, [pageLength, max]);

   useEffect(() => {
      const list = returnArray(numPages, current);
      setPages(list);
   }, [current, numPages, pageLength, max]);

   const changePage = (num) => {
      const newPages = returnArray(numPages, num);
      setPages(newPages);
      updatePage(num);
   };

   return (
      <PagerStyled>
         <ActionBtn
            className={current === 1 ? "disabled" : ""}
            onClick={() => {
               changePage(current - 1);
            }}
         >
            Previous
         </ActionBtn>
         {React.Children.toArray(
            visiblePages.map((item) => (
               <NumBtn
                  onClick={() => {
                     changePage(item.num);
                  }}
                  className={item.isActive ? "current" : ""}
               >
                  {item.num}
               </NumBtn>
            )),
         )}
         <ActionBtn
            className={current === numPages ? "disabled" : ""}
            onClick={() => {
               let newP = numPages === current ? current : current + 1;
               changePage(newP);
            }}
         >
            Next
         </ActionBtn>
      </PagerStyled>
   );
}

function returnArray(numP, current) {
   let DotStart = current - 2;
   DotStart = DotStart <= 0 ? 1 : DotStart;
   DotStart = current + 2 >= numP ? numP - 4 : DotStart;
   DotStart = DotStart <= 0 ? 1 : DotStart;
   let DotEnd = numP >= 5 ? 5 : numP;
   current = current >= numP ? numP : current;
   const dotPagesarray = new Array(DotEnd).fill("x");
   const createDots = dotPagesarray.map((item, index) => {
      return {
         isActive: DotStart + index === current,
         num: DotStart + index,
      };
   });
   return createDots;
}

const PagerStyled = styled.div`
   display: flex;
   justify-content: flex-end;
   align-items: center;
   padding: 0.5em 1em;
`;

const ActionBtn = styled(ResetButton)`
   color: #767676;
   user-select: none;

   &.disabled {
      pointer-events: none;
      opacity: 0.8;
      cursor: initial;
   }
`;

const NumBtn = styled(ResetButton)`
   padding: 0.2em 0.7em;
   border-radius: 1px;
   margin: 0 0.3em;
   color: grey;
   user-select: none;

   &:hover:not(.current) {
      background: rgba(10, 10, 10, 0.2);
   }

   &.current {
      background: #cfd9ef;
      pointer-events: none;
   }
`;
