/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from "react";
import {LiveEventsContext} from "../../../providers/LiveEventsProvider";
import {EventsListContainer} from "../Styles";
import Text from "../../../components/Text";
import PastEvent from "../components/PastEvent";

export default function PastEvents() {
   const {listPastEvents, getPastEvents} = useContext(LiveEventsContext);

   useEffect(() => {
      getPastEvents();
   }, []);
   return (
      <>
         <EventsListContainer>
            {React.Children.toArray(
               listPastEvents.data.map((liveEvent) => {
                  return <PastEvent data={liveEvent} type="past" />;
               }),
            )}
            {listPastEvents.data.length === 0 && <Text>No hay eventos pasados</Text>}
         </EventsListContainer>
      </>
   );
}
