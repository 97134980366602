import styled from "styled-components";

const Text = styled.p`
   user-select: ${props => (props.selectable ? "auto" : "none")};
   font-size: ${props => props.size || props.theme.normalText};
   color: ${props => props.color || props.theme.primary};
   text-align: ${props => props.align || "left"};
`;

export default Text;
