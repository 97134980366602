import React, {Component} from "react";
import {membershipListService, getToken} from "../helpers/Services";

export const MembershipContext = React.createContext();

export default class MembershipProvider extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isLoding: true,
         addMembershipOpen: this.addMembershipOpen,
         addMembershipClose: this.addMembershipClose,
         openModalEdit: this.openModalEdit,
         openModalAdd: this.openModalAdd,
         confirmDelete: this.confirmDelete,
         membershipData: [],
         dataDelete: {},
         deleteUser: this.deleteUser,
         openShowUser: this.openShowUser,
         closeShowModal: this.closeShowModal,
         membershipList: [],
      };
   }
   UNSAFE_componentWillMount() {
      this.getMemberships();
   }
   getMemberships = () => {
      getToken().then(token => {
         let data = {token};
         membershipListService(data)
            .then(res => res.json())
            .then(resp => {
               const {status, data} = resp;
               if (status === "success") {
                  const widthName = data.map(item => {
                     const newItem = {...item, name: item.first_name + " " + item.last_name};
                     return newItem;
                  });
                  const onlyActive = widthName.filter(item => item.status === 1);
                  this.setState({
                     isLoding: false,
                     membershipList: [...onlyActive],
                  });
               }
            });
      });
   };
   addMembershipOpen = x => {
      this.setState({
         addMembershipModal: true,
      });
   };
   addMembershipClose = () => {
      this.setState({addMembershipModal: false});
   };
   openModalEdit = id => {
      const {membershipData} = this.state;
      let toEditUser = membershipData.find(x => Number(x.id) === Number(id));
      return this.setState({modalEditIsOpen: true, toEditUser: toEditUser});
   };
   openModalAdd = () => {
      this.setState({modalAddIsOpen: true});
   };
   confirmDelete = id => {
      const {membershipData} = this.state;
      let deleteUser = membershipData.find(x => Number(x.id) === Number(id));
      const dataDelete = {
         typeDelete: "usuario",
         nameDelete: deleteUser.name,
         id: id,
      };
      this.setState({
         dataDelete: dataDelete,
         confirmIsOpen: true,
      });
   };
   closeConfirm = () => {
      this.setState({
         dataDelete: [],
         confirmIsOpen: false,
      });
   };
   deleteUser = id => {
      const {membershipData} = this.state;
      let newUsers = membershipData.filter(x => Number(x.id) !== Number(id));
      this.setState(
         {
            membershipData: newUsers,
         },
         () => {
            this.closeConfirm();
         },
      );
   };
   addUser = e => {
      e.preventDefault();
      const myForm = e.target;
      const {membershipData} = this.state;
      const newUser = myForm.name.value;
      const newEmail = Number(myForm.email.value);
      // crear item
      const newUsers = [...membershipData, {id: membershipData.length + 1, city: newUser, status: "active", email: newEmail}];
      const ExistCity = membershipData.find(x => x.city.toLocaleLowerCase() === newUser.toLocaleLowerCase());
      if (ExistCity) {
         return this.setState({
            errorsForm: "Ya hay una ciudad con este nombre",
         });
      }
      this.setState(
         {
            membershipData: newUsers,
         },
         () => {
            this.closeModalAdd();
         },
      );
   };
   closeModalAdd = () => {
      this.setState({modalAddIsOpen: false, errorsForm: ""});
   };

   // modal show user
   openShowUser = id => {
      this.setState({
         modalShowIsOpen: true,
      });
   };
   closeShowModal = () => {
      this.setState({modalShowIsOpen: false});
   };
   render() {
      return <MembershipContext.Provider value={this.state}>{this.props.children}</MembershipContext.Provider>;
   }
}
