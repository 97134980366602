import React, {Component} from "react";
import Container from "../components/Container";
import EventsProvider, {EventsContext} from "../providers/EventsProvider";
import TableEvents from "./events/TableEvents";
import ConfirmDelete from "../components/ConfirmDelete";

export default class Events extends Component {
   render() {
      return (
         <Container {...this.props}>
            <EventsProvider>
               <EventsContext.Consumer>
                  {({
                     eventsData,
                     deleteEvent,
                     openModalEdit,
                     closeConfirm,
                     dataDelete,
                     confirmIsOpen,
                  }) => (
                     <>
                        <TableEvents eventsData={eventsData} openModalEdit={openModalEdit} {...this.props} />
                        <ConfirmDelete
                           dataDelete={dataDelete}
                           closeConfirm={closeConfirm}
                           confirmIsOpen={confirmIsOpen}
                           action={deleteEvent}
                        />
                     </>
                  )}
               </EventsContext.Consumer>
            </EventsProvider>
         </Container>
      );
   }
}
