export const filterByText = (itemVal, text) => {
   // minLength 2
   if (text.length <= 2) return true;
   if (!itemVal) return false;
   return itemVal ? itemVal.toLowerCase().indexOf(text.toLowerCase()) > -1 : false;
};
export const filterByArray = (itemVal, text) => {
   const strings = text.split(" ");
   if (strings[0].length <= 2) return true;
   if (!itemVal) return false;
   let exist = false;
   for (let val in strings) {
      if (itemVal.toLowerCase().indexOf(strings[val].toLowerCase()) > -1) {
         return true;
      }
      return false;
   }
   return exist;
};
export const filterByKey = (itemVal, val, toNull) => {
   if (val === toNull) return true;
   return itemVal ? itemVal + "" === val + "" : false;
};
