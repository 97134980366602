import styled from "styled-components";
import Back from "../images/desk.jpg";
import Concert from "../images/concert.jpg";

export const LoginCard = styled.div`
   padding: 1em;
   background-color: black;
   width: 25%;
   border-radius: 5px;
   box-shadow: 1px 2px 2px 0px rgb(125, 112, 112);
   max-width: 350px;
   display: ${props => (props.visible ? "block" : "none")};
   @media screen and (max-width: 1000px) {
      width: 50%;
   }
   @media screen and (max-width: 560px) {
      width: 75%;
   }
   & .actions {
      display: flex;
      justify-content: ${props => (props.left ? "flex-start" : "flex-end")};
      width: ${props => props.width || "100%"};
      cursor: pointer;
      margin: ${props => props.marginAction || ""};
   }
`;

export const LoginView = styled.section`
   width: 100%;
   height: 100%;
   background-image: ${props => `url(${props.img ? Concert : Back})`};
   background-size: cover;
   background-position: bottom center;
   display: flex;
   justify-content: center;
   align-items: center;
   & .logoContainer {
      width: 100%;
      margin-bottom: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
   }
`;

export const FormContainer = styled.form`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
`;
