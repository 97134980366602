import React, {useEffect, useState} from "react";
import Container from "../../components/Container";
import {useDispatch, useSelector} from "react-redux";
import {getMembershipTicketsAction} from "../../store/actions/MembershipsActions";
import {getLocalToken} from "../../store/actions/AuthActions";
import Table from "./Table";
import AddCode from "./AddCode";

export default function MembersipCodes(props) {
   const [addModal, setAddModal] = useState(false);
   const dispatch = useDispatch();
   const {token} = useSelector((state) => state.auth);

   useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatch(getLocalToken());
   }, [dispatch]);

   useEffect(() => {
      dispatch(getMembershipTicketsAction());
   }, [dispatch, token]);

   return (
      <Container {...props}>
         <Table
            openModal={() => {
               setAddModal(true);
            }}
         />
         <AddCode
            isOpen={addModal}
            closeModal={() => {
               setAddModal(false);
            }}
         />
      </Container>
   );
}
