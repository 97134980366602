import React, {useEffect, useState} from "react";
import Container from "../../components/Container";
import TableUsers from "./TableUsers";
import {useSelector, useDispatch} from "react-redux";
import {getUsersAction, updateRollAction} from "../../store/actions/UsersActions";
import AppLoader from "../../components/loader/AppLoader";
import {getLocalToken} from "../../store/actions/AuthActions";
import ShowUser from "./ShowUser";
import {useHistory} from "react-router-dom";

export default function UsersPage(props) {
   const dispatch = useDispatch();
   const {token} = useSelector((state) => state.auth);
   const {users, fetchingUsers} = useSelector((state) => state.users);
   const [showUser, setShowUser] = useState(false);
   const history = useHistory();

   useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatch(getLocalToken());
   }, [dispatch]);

   useEffect(() => {
      dispatch(getUsersAction());
   }, [dispatch]);

   const showUserAction = (id) => {
      history.push(`${history.location.pathname}?user=${id}`);
      setShowUser(true);
   };
   const hideUserAction = () => {
      history.goBack();
      setShowUser(false);
   };

   const handleChange = (id, val) => {
      dispatch(updateRollAction(id, Number(val)));
   };

   if (!token) history.replace("/");

   if (fetchingUsers) return <AppLoader isVisible />;

   return (
      <Container {...props}>
         <TableUsers
            usersData={users}
            deleteCity={() => {
               console.log("CONFIRM DELETE");
            }}
            openShowUser={showUserAction}
            toggleAdmind={handleChange}
            closeModallEdit={() => {
               console.log("CLOSE EDIT");
            }}
         />
         <ShowUser isOpen={showUser} closeModal={hideUserAction} />
      </Container>
   );
}
