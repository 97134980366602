import React from "react";
import {RowDiv, ColDiv, CurrentImage} from "./EditStyled";
import {Label, Input, Select} from "../../../../components/FormsConfig";
import {FieldFile} from "../../../../components/Fields";
import Button from "../../../../components/Button";
import ListComponent from "./ListComponent";

export default function FormEvent({
   dataEvent,
   editInterest,
   saveEvent,
   citiesCountry,
   interest,
   activeTemes,
   changeCountry,
   updateDesc,
   setMovie,
}) {
   return (
      <form onSubmit={saveEvent}>
         <RowDiv>
            <ColDiv colsm="12" colmd="6">
               <Label>Título del evento</Label>
               <Input placeholder="Asigna un nombre o identificador a tu evento" defaultValue={dataEvent.title} name="title" />
            </ColDiv>
            <ColDiv colsm="12" colmd="4" padding="0 0 0 1em">
               <Label>Boletas</Label>
               <Input placeholder="Boletas disponibles" defaultValue={dataEvent.delivered} name="delivered" />
            </ColDiv>
            <ColDiv colsm="12" colmd="12">
               <Label>Descripción</Label>
               <Input
                  placeholder="Descripción del evento"
                  as="textarea"
                  height="150px"
                  value={dataEvent.description}
                  onChange={e => {
                     updateDesc(e.target.value);
                  }}
                  name="description"
               />
            </ColDiv>
         </RowDiv>
         <RowDiv>
            <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
               <Label>Fecha del evento</Label>
               <Input type="date" defaultValue={dataEvent.date} name="date" />
            </ColDiv>
            <ColDiv colsm="12" colmd="6" padding="0 0 0 1em">
               <Label>Hora</Label>
               <Input
                  type="time"
                  defaultValue={dataEvent.time}
                  name="time"
                  onChange={e => {
                     console.log(e.target.value);
                  }}
               />
            </ColDiv>
            <ColDiv colsm="12" colmd="12">
               <Label>Ubicación </Label>
               <Input placeholder="Ubicación o dirección del evento" defaultValue={dataEvent.location} name="location" />
            </ColDiv>
            <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
               <Label>País del evento</Label>
               <Select name="country_id" onChange={changeCountry} defaultValue={dataEvent.country_id}>
                  <option value={1}>México</option>
                  <option value={2}>Colombia</option>
               </Select>
            </ColDiv>
            {citiesCountry && (
               <ColDiv colsm="12" colmd="6" padding="0 0 0 1em">
                  <Label>Ciudad</Label>
                  <Select name="city" defaultValue={dataEvent.city}>
                     <option value={0} disabled>
                        Ciudad
                     </option>
                     {citiesCountry.map(item => {
                        return (
                           <option value={item.id} key={item.id}>
                              {item.name}
                           </option>
                        );
                     })}
                  </Select>
               </ColDiv>
            )}
            <ColDiv colsm="12" colmd="12">
               <Label>Temas</Label>
               <ListComponent data={activeTemes} list={interest} change={editInterest} />
            </ColDiv>
         </RowDiv>
         <RowDiv>
            <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
               <Label>Tipo</Label>
               <Select defaultValue={dataEvent.type} name="type">
                  <option value="Propio">Propio</option>
                  <option value="Externo">Externo</option>
               </Select>
            </ColDiv>
            <ColDiv colsm="12" colmd="6" padding="0 0 0 1em">
               <Label>Estado</Label>
               <Select defaultValue={dataEvent.status} name="status">
                  <option value={0}>Expectativa</option>
                  <option value={1}>Activo</option>
               </Select>
            </ColDiv>
            <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
               <Label>Estado</Label>
               <Select defaultValue={dataEvent.visibility} name="visibility">
                  <option value={0}>No publicado</option>
                  <option value={1}>Publicado</option>
               </Select>
            </ColDiv>
         </RowDiv>
         <RowDiv>
            <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
               <Label>Evento de cine:</Label>
               <Select
                  defaultValue={dataEvent.movie_theater}
                  name="movie_theater"
                  onChange={e => {
                     setMovie(e.target.value);
                  }}
               >
                  <option value={0}>No</option>
                  <option value={1}>Sí</option>
               </Select>
            </ColDiv>
            {Number(dataEvent.movie_theater) === 1 && (
               <ColDiv colsm="12" colmd="6" padding="0 0 0 1em">
                  <Label>Cine:</Label>
                  <Select defaultValue={dataEvent.cineName} name="cinema_type">
                     <option value="cinemark">Cinemark</option>
                     <option value="cinemex">Cinemex</option>
                  </Select>
               </ColDiv>
            )}
         </RowDiv>
         <RowDiv>
            <ColDiv colsm="12" colmd="6" padding="0 1em 0 0">
               <Label>Imagen externa</Label>
               <FieldFile fileType=".jpg, .jpeg" name="external_img">
                  <Label marginBottom="0" as="span">
                     Agregar nueva imagen externa
                  </Label>
               </FieldFile>
               {dataEvent.external_img && (
                  <CurrentImage src={`http://freeticket.trebolbit.com/${dataEvent.external_img}`} alt="imagen externa" />
               )}
            </ColDiv>
            <ColDiv colsm="12" colmd="6" padding="0 0 0 1em">
               <Label>Imagen interna</Label>
               <FieldFile fileType=".jpg, .jpeg" name="inner_img">
                  <Label marginBottom="0" as="span">
                     Agregar nueva imagen interna
                  </Label>
               </FieldFile>
               {dataEvent.external_img && (
                  <CurrentImage src={`http://freeticket.trebolbit.com/${dataEvent.inner_img}`} alt="imagen interna" />
               )}
            </ColDiv>
         </RowDiv>
         <RowDiv margin="1em 0">
            <ColDiv colsm="12" colmd="3" />
            <ColDiv colsm="12" colmd="6">
               <Button margin="0 auto" width="100%">
                  GUARDAR CAMBIOS
               </Button>
            </ColDiv>
            <ColDiv colsm="12" colmd="3" />
         </RowDiv>
      </form>
   );
}
