import styled from "styled-components";
import Color from "../../../constants/Colors";
import {Link} from "react-router-dom";

export const EventStyled = styled.article`
   width: 25%;
   padding: 0.5em;
   opacity: 1;
   transition: opacity 420ms linear;

   &.ghost {
      opacity: 0.5;
      transition: opacity 420ms linear;
   }

   &:first-child {
      padding-left: 0;
   }
   & .markersType {
      display: none;
   }

   @media screen and (max-width: 900px) {
      width: 48%;
   }
   @media screen and (max-width: 600px) {
      width: 100%;
   }
`;

export const EventInfoBox = styled.div`
   display: flex;
   flex-direction: column;
   text-decoration: none;
   color: ${Color.primary};

   & .titleEvent {
      font-size: 1.5em;
      margin-bottom: 0.5em;
      user-select: none;
      color: ${Color.primary};
   }
   & .divdesc {
      max-lines: 3;
   }
   & .descriptionEvent {
      font-size: 1em;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      user-select: none;
   }

   @media screen and (max-width: 600px) {
      & .titleEvent {
         font-size: 1.3em;
      }
      & .descriptionEvent {
         font-size: 0.9em;
      }
   }
`;

export const InfoDate = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0.5em 0;
   color: ${Color.primary};

   & .section {
      display: flex;

      & .IconDate {
         margin-right: 0.5em;
      }

      & p {
         font-size: 0.9em;
      }
   }

   @media screen and (max-width: 600px) {
      flex-wrap: wrap;
      margin-bottom: 0;

      & .section {
         margin-bottom: 0.5em;
      }
   }
`;

export const EventImg = styled.div`
   display: block;
   width: 100%;

   & img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      // filter: brightness(0.9);
      transition: filter 420ms ease;
   }

   & .markersType {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 0.5em 0;
      background: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.background};
      text-align: center;
      user-select: none;
   }

   &.past {
      position: relative;

      & .markersType.pastEvent {
         display: block;
         color: white;
         font-size: 1em;
      }
   }

   &.next,
   &.onair {
      position: relative;

      & .markersType.next {
         display: block;
         color: white;
         font-size: 1em;

         &.barAction {
            padding: 0;
         }
      }
   }

   &.onair {
      position: relative;

      & .isOnlineBar {
         content: "EN VIVO";
         background: ${(props) => props.theme.secondary};
         display: block;
      }
   }

   &:hover {
      & img {
         filter: brightness(1);
         transition: filter 420ms ease;
      }
   }

   @media screen and (max-width: 1000px) {
      & img {
         height: 175px;
      }
   }
   @media screen and (max-width: 600px) {
      & img {
         height: 150px;
      }
   }
`;

export const ActionsEvent = styled.div`
   width: 100%;
   height: 100%;
`;

export const EventButton = styled(Link)`
   background: transparent;
   border: 1px inset ${(props) => props.color};
   color: ${(props) => props.color};
   cursor: pointer;
   padding: 0 1.5em;
   height: 1.8em;
   font-size: 1em;
   margin-right: 0.5em;
   margin-bottom: 0.5em;
   outline: none;
   display: inline-block;
   display: inline-flex;
   align-items: center;
   text-align: center;

   &.barAction {
      display: block;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      padding: 0.7em 0;
      border-color: ${Color.primary};
   }

   &.invert {
      background-color: ${(props) => props.color};
      color: white;
   }
`;
