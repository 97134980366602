import styled from "styled-components";

export const Content = styled.div`
   width: 80%;
   margin: 0 auto;
   padding: 1em;
   @media screen and (max-width: 1000px) {
      width: 85%;
   }
   @media screen and (max-width: 800px) {
      width: 90%;
   }
   @media screen and (max-width: 500px) {
      width: 100%;
   }
`;

export const EventsListContainer = styled.div`
   display: flex;
   /* justify-content: space-between; */
   flex-wrap: wrap;
   width: 100%;
   padding: 1em;
`;
