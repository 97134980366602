/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext} from "react";
import Container from "../../components/Container";
import {LiveEventsContext} from "../../providers/LiveEventsProvider";
import HeaderView from "../../components/toViews/HeaderView";
import {ItemHeader} from "../../components/TableComponents";
import {AddTextButton} from "../../components/Button";
import styled from "styled-components";
import Color from "../../constants/Colors";
import FutureEvents from "./filterEvents/FutureEvents";
import TodayEvents from "./filterEvents/TodayEvents";
import PastEvents from "./filterEvents/PastEvents";

function LiveView(props) {
   const {currentTab, setTab} = useContext(LiveEventsContext);

   return (
      <Container {...props}>
         <HeaderView title="Shows en vivo">
            <ItemHeader>
               <AddTextButton title="Códigos universales" onClick={() => props.history.push("/live_events/ticket_global")} />
               <AddTextButton title="Crear evento en vivo" onClick={() => props.history.push("/live_events/add")} />
            </ItemHeader>
         </HeaderView>
         <Head>
            {React.Children.toArray(
               TabList.map((tabItem) => {
                  return (
                     <TabItem
                        className={currentTab === tabItem.id ? "isActive" : ""}
                        onClick={() => {
                           setTab(tabItem.id);
                        }}
                     >
                        {tabItem.name}
                     </TabItem>
                  );
               }),
            )}
         </Head>
         {currentTab === 1 && (
            <ContentEvent>
               <PastEvents />
            </ContentEvent>
         )}
         {currentTab === 2 && (
            <ContentEvent>
               <TodayEvents />
            </ContentEvent>
         )}
         {currentTab === 3 && (
            <ContentEvent>
               <FutureEvents />
            </ContentEvent>
         )}
      </Container>
   );
}

export default LiveView;

const TabList = [
   {id: 1, name: "Pasados"},
   {id: 2, name: "Hoy"},
   {id: 3, name: "Próximos"},
];

const Head = styled.section`
   width: 100%;
   display: flex;
   padding: 0 0.5em;
   align-items: center;
`;

const TabItem = styled.button`
   padding: 0.5em 1.5em;
   color: ${Color.primary};
   margin-right: 0.5em;
   border: none;
   cursor: pointer;
   outline: none;
   &.isActive {
      background: ${Color.secondary};
   }
`;

const ContentEvent = styled.div`
   width: 100%;
`;
