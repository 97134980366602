import React from "react";
import styled from "styled-components";
import {ReactComponent as BlockIcon} from "../icons/block.svg";

const View = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   & .text {
      font-size: 1em;
      margin-top: 20px;
      text-align: center;
   }
`;

export default function NoPermission() {
   return (
      <View>
         <BlockIcon height="2.5em" width="2.5em" />
         <p className="text">
            La página que solicitas no existe <br /> o no tienes acceso a ella
         </p>
      </View>
   );
}
