const isTouch = "ontouchstart" in document.documentElement;
const width = window.screen.width;
const height = window.screen.height;
const relation = height / width;

export default {
   height,
   isSmarthphone: width < 600 && isTouch,
   isTablet: width < 1400 && width > 600 && isTouch && relation > 0.6,
   isTouch,
   width,
};
