import React, {Component} from "react";
import Container from "../components/Container";
import AddCity from "./cities/AddCityModal";
import TableCities from "./cities/TablesCities";
import CitiesProvider, {CitiesContext} from "../providers/CititesProvider";
import EditCity from "./cities/EditCityModal";
import ConfirmDelete from "../components/ConfirmDelete";

export default class Cities extends Component {
   constructor(props) {
      super(props);
      this.state = {
         modalIsOpen: false,
      };
   }
   render() {
      return (
         <Container {...this.props}>
            <CitiesProvider {...this.props}>
               <CitiesContext.Consumer>
                  {({
                     citiesData,
                     openModalEdit,
                     closeModallEdit,
                     openModalAdd,
                     deleteCity,
                     dataDelete,
                     closeConfirm,
                     confirmIsOpen,
                     confirmDelete,
                  }) => (
                     <>
                        <TableCities
                           citiesData={citiesData}
                           closeModallEdit={closeModallEdit}
                           openModalEdit={openModalEdit}
                           openModalAdd={openModalAdd}
                           deleteCity={confirmDelete}
                        />
                        <AddCity />
                        <EditCity />
                        <ConfirmDelete
                           dataDelete={dataDelete}
                           closeConfirm={closeConfirm}
                           confirmIsOpen={confirmIsOpen}
                           action={deleteCity}
                        />
                     </>
                  )}
               </CitiesContext.Consumer>
            </CitiesProvider>
         </Container>
      );
   }
}
