import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {Seeker, TableAction, ItemHeader} from "../../components/TableComponents";
import {ReactComponent as EditIcon} from "../../icons/edit.svg";
import {ReactComponent as DeleteIcon} from "../../icons/delete.svg";
import Colors from "../../constants/Colors";
import HeaderView from "../../components/toViews/HeaderView";
import Text from "../../components/Text";
import {AddButton} from "../../components/Button";
import {Select} from "../../components/FormsConfig";
import {filterByText, filterByKey} from "../../constants/Filters";

const EditButton = ({val, action}) => (
   <TableAction
      as="button"
      iconColor="darkgreen"
      onClick={() => {
         action(val);
      }}
      margin="0 0.3em"
   >
      <EditIcon width="20px" height="20px" fill={Colors.primary} className="icon" />
   </TableAction>
);
const DeleteButton = ({val, action}) => (
   <TableAction
      as="button"
      iconColor="crimson"
      onClick={() => {
         action(val);
      }}
      margin="0 0.3em"
   >
      <DeleteIcon width="20px" height="20px" fill={Colors.primary} className="icon" />
   </TableAction>
);

const HeaderActions = ({onFilter, title, openModal, fByCountry}) => (
   <HeaderView title={title}>
      <ItemHeader></ItemHeader>
      <ItemHeader>
         <AddButton onClick={openModal} />
      </ItemHeader>
      <ItemHeader>
         <Select name="country_id" defaultValue={0} onChange={fByCountry} marginB="0">
            <option value={0}>Todos los países</option>
            <option value={1}>México</option>
            <option value={2}>Colombia</option>
         </Select>
      </ItemHeader>
      <ItemHeader>
         <Seeker onFilter={onFilter} place="Buscar por ciudad" />
      </ItemHeader>
   </HeaderView>
);

export default class TableCities extends Component {
   constructor(props) {
      super(props);
      this.state = {
         DataCities: this.props.citiesData,
         filterText: "",
         countrySelected: 0,
      };
   }
   componentDidMount() {
      this.onFilter();
   }
   UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.citiesData === this.state.DataCities) return false;

      this.setState(
         {
            DataCities: this.props.citiesData,
         },
         () => this.onFilter(this.state.filterText),
      );
   }
   onFilter = val => {
      this.setState({
         filterText: val,
      });
      this.filterItems(val);
   };
   fByCountry = e => {
      const newValue = e.target.value;
      this.setState(
         {
            countrySelected: Number(newValue),
         },
         () => {
            this.filterItems(this.state.filterText);
         },
      );
   };
   filterItems = val => {
      const {DataCities, countrySelected} = this.state;
      const filterText = val || "";
      let filteredItems = DataCities.filter(
         city => filterByText(city.name, filterText) && filterByKey(Number(city.country_id), countrySelected, 0),
      );
      return this.setState({
         filteredItems: filteredItems,
      });
   };
   Columns = [
      {
         name: "Id",
         selector: "id",
         sortable: true,
         maxWidth: "120px",
      },
      {
         name: "Status",
         selector: "status",
         maxWidth: "220px",
         cell: row => (
            <Text color={Number(row.status) === 0 ? "red" : "green"}>{Number(row.status) === 0 ? "No publicada" : "Publicada"}</Text>
         ),
         sortable: true,
      },
      {
         name: "Ciudad",
         selector: "name",
         sortable: true,
      },
      {
         name: "País",
         selector: "country_city",
         cell: row => <Text>{Number(row.country_id) === 1 ? "México" : "Colombia"}</Text>,
         sortable: false,
      },
      {
         name: "Editar",
         button: false,
         cell: row => <EditButton val={row.id} action={this.props.openModalEdit} />,
         maxWidth: "80px",
      },
      {
         name: "Eliminar",
         button: false,
         cell: row => <DeleteButton val={row.id} action={this.props.deleteCity} />,
         maxWidth: "80px",
      },
   ];

   render() {
      const {filteredItems} = this.state;
      const paginationOptions = {rowsPerPageText: "Filas por página", rangeSeparatorText: "de"};
      return (
         <DataTable
            title="Ciudades"
            header={null}
            columns={this.Columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={
               <HeaderActions onFilter={this.onFilter} title="Ciudades" openModal={this.props.openModalAdd} fByCountry={this.fByCountry} />
            }
            pagination
            paginationComponentOptions={paginationOptions}
            fixedHeader
            noDataComponent="No hay ciudades registradas"
            style={{display: "block", height: "80%", overflowX: "initial"}}
         />
      );
   }
}
