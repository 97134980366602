import React, {Component} from "react";
import styled from "styled-components";
import Text from "../Text";

const Head = styled.section`
   width: 100%;
   display: flex;
   padding: 0 0.5em;
   justify-content: space-between;
   align-items: center;
   & .headtitle {
      text-align: center;
   }
   & ${Text} {
      font-size: 1.4em;
      margin-bottom: 0.5em;
   }
   /* border-bottom: 1px solid rgba(100, 100, 100, 0.2); */
   @media screen and (max-width: 600px) {
      flex-wrap: wrap;
   }
`;
const ActionsSection = styled.div`
   display: flex;
   justify-content: flex-end;
   align-items: center;
   padding-top: 1em;
   @media screen and (max-width: 600px) {
      width: 100%;
      flex-wrap: wrap;
   }
`;

export default class HeaderView extends Component {
   render() {
      const {title, children} = this.props;
      return (
         <Head>
            <Text>{title}</Text>
            <ActionsSection>{children}</ActionsSection>
         </Head>
      );
   }
}

HeaderView.defaultProps = {
   title: "Tìtulo de la pantalla",
};
