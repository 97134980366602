import React, {useContext, useEffect} from "react";
import Container from "../../../components/Container";
import {Content} from "../Styles";
import HeaderView from "../../../components/toViews/HeaderView";
import {Label, Input} from "../../../components/FormsConfig";
import Button from "../../../components/Button";
import {RowDiv, ColDiv} from "../../events/views/editComponents/EditStyled";
import {useParams} from "react-router-dom";
import {LiveTicketsContext} from "../../../providers/LiveTicketsProvider";
import CsvDownload from "react-json-to-csv";
import styled from "styled-components";
import Color from "../../../constants/Colors";

export default function CreateTicket(props) {
   const {createTicketsLive, ticketsList} = useContext(LiveTicketsContext);
   let {live_event_id} = useParams();

   useEffect(() => {
      console.log("ticketsList", ticketsList);
   }, [ticketsList]);

   const createTickets = (e) => {
      e.preventDefault();
      let num = e.target.total.value;
      createTicketsLive(live_event_id, num);
   };
   return (
      <Container {...props}>
         <Content>
            <HeaderView title="Crear tickets para un evento" />
            <Label>¿Cuántos tickets quieres crear?</Label>
            <RowDiv>
               <ColDiv colmd={4} colsm={4}>
                  <form onSubmit={createTickets}>
                     <Input type="number" placeholder="" name="total" />
                     <Button>Crear</Button>
                  </form>
               </ColDiv>
               <ColDiv colmd={12} colsm={12}>
                  <ButtonDo>
                     {ticketsList.length > 0 && (
                        <CsvDownload data={ticketsList} value="Descargar" title="Descargar">
                           Descargar
                        </CsvDownload>
                     )}
                  </ButtonDo>
               </ColDiv>
            </RowDiv>
         </Content>
      </Container>
   );
}

const ButtonDo = styled.div`
   display: block;

   & button {
      background: ${Color.primary};
      padding: 0.5em 2em;
      color: white;
   }
`;
