import React, {Component} from "react";
import Container from "../../../components/Container";
import HeaderView from "../../../components/toViews/HeaderView";
import {SimpleField} from "../../../components/Fields";
import {HeadContainer} from "./TicketsStyles";
import TableTickets from "./tikets/TableTickets";
import Button from "../../../components/Button";
import styled from "styled-components";
import {eventTicketsService, getToken, getEventInfoService, createGiftTickets, uploadCSVService} from "../../../helpers/Services";
import Colors from "../../../constants/Colors";
import CreateTicketForm from "./tikets/components/CreateTiketsForm";
import AppLoader from "../../../components/loader/AppLoader";
import {JsonToExcel} from "react-json-excel";

export default class CreateTickets extends Component {
   constructor(props) {
      super(props);
      this.inputRef = React.createRef();
      this.state = {
         generateIsOpen: false,
         ticketsData: [],
         id_event: this.props.match.params.id,
         movieEvent: false,
         isLoading: true,
         createError: "",
         printableTickets: [],
         fields: [],
      };
      this.downLoadBtn = React.createRef();
   }
   componentDidMount() {
      this.getEventData();
      this.getTicketsData();
   }
   getEventData = () => {
      getToken().then(token => {
         let data = {id: Number(this.state.id_event)};
         getEventInfoService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               if (status === "success") {
                  if (data.event_data.movie_theater) {
                     this.setState({
                        movieEvent: true,
                     });
                  }
                  this.setState({
                     dataEvent: data.event_data,
                  });
               }
            });
      });
   };
   getTicketsData = () => {
      getToken().then(token => {
         const data = {token, id_event: this.state.id_event};
         eventTicketsService(data)
            .then(res => res.json())
            .then(resp => {
               this.setState({
                  isLoading: false,
               });
               const {status, data} = resp;
               if (status === "success") {
                  this.setState(
                     {
                        ticketsData: [...data],
                     },
                     () => {
                        setTimeout(() => {
                           this.downLoadAction();
                        }, 100);
                     },
                  );
               } else {
                  this.setState({
                     ticketsData: [],
                  });
               }
            })
            .catch(() => {
               this.setState({
                  isLoading: false,
               });
            });
      });
   };
   generate = event => {
      event.preventDefault();
      this.setState({
         isLoading: true,
         successDown: false,
      });
      const total = event.target.num.value;
      if (!total || total > 500) return false;
      const {id_event} = this.state;
      getToken().then(token => {
         const data = {
            token,
            id_event,
            total,
         };
         createGiftTickets(data)
            .then(res => res.json())
            .then(resp => {
               const {status} = resp;
               this.setState({
                  isLoading: false,
               });
               if (status === "success") {
                  this.setState({
                     generateIsOpen: false,
                     createError: "",
                  });
                  this.getTicketsData();
               } else {
                  this.setState({
                     createError: "Ocurrió un error, intente más tarde",
                  });
               }
            })
            .catch(() => {
               this.setState({
                  createError: "Ocurrió un erro, intente más tarde",
               });
            });
      });
   };
   uploadCSV = e => {
      e.preventDefault();
      this.setState({
         isLoading: true,
      });
      const {id_event} = this.state;
      const fileToSend = e.target.csv.files[0];
      let formData = new FormData();
      formData.append("csv", fileToSend);
      formData.append("id_event", id_event);
      getToken().then(token => {
         formData.append("token", token);
         console.log("listso para enviar", formData);
         uploadCSVService(formData)
            .then(res => res.json())
            .then(resp => {
               const {status} = resp;
               this.setState({
                  isLoading: false,
               });
               if (status === "success") {
                  this.getTicketsData();
               }
            })
            .catch(() => {
               this.setState({
                  isLoading: false,
               });
            });
      });
   };

   downLoadAction = () => {
      const {ticketsData, id_event} = this.state;
      const tickets = ticketsData.filter(tiket => Number(tiket.printable) === 1 && tiket.id_user === 0);
      const printableTickets = tickets.map(ticket => {
         const {printable, username, first_name, last_name, email, phone, id_user, file_url, ...data} = ticket;
         return {...data};
      });
      const fields = {
         id: "id",
         code: "Codigo",
         status: "Status",
         id_event: "Evento",
         date: "Fecha",
      };
      if (printableTickets.length > 0) {
         this.setState({
            printableTickets,
            fileName: `tickets_evento_${id_event}`,
            fields,
         });
         setTimeout(() => {
            this.setState({
               successDown: true,
            });
         }, 1000);
      }
   };

   render() {
      const {ticketsData, generateIsOpen, movieEvent, isLoading, createError, printableTickets, fileName, fields, successDown} = this.state;
      return (
         <>
            <Container {...this.props} showBack={true}>
               <HeadContainer>
                  <HeaderView title="Tickets de evento"></HeaderView>
                  {movieEvent && (
                     <form onSubmit={this.uploadCSV}>
                        <SimpleField
                           text="Agregar archivo excel"
                           fileType=".csv"
                           name="csv"
                           onChange={() => {
                              this.buttonRef.click();
                           }}
                        />
                        <button style={{display: "none"}} ref={button => (this.buttonRef = button)}>
                           Enviar
                        </button>
                     </form>
                  )}
                  {!movieEvent && (
                     <>
                        <Button
                           color={Colors.secondary}
                           text={Colors.primary}
                           onClick={() => {
                              this.setState({
                                 generateIsOpen: !generateIsOpen,
                              });
                           }}
                           style={{minWidth: "150px"}}
                        >
                           Códigos regalo
                        </Button>
                        {successDown && (
                           <DownBtn as="div">
                              Exportar tickets de regalo
                              <JsonToExcel
                                 data={printableTickets}
                                 filename={fileName}
                                 fields={fields}
                                 ref={button => (this.btnDownloadRef = button)}
                                 className="descargar"
                              />
                           </DownBtn>
                        )}
                     </>
                  )}
               </HeadContainer>
               {generateIsOpen && (
                  <Content>
                     <CreateTicketForm action={this.generate} error={createError} />
                  </Content>
               )}
               <TableTickets ticketsData={ticketsData} />
            </Container>
            <AppLoader isVisible={isLoading} />
         </>
      );
   }
}

const Content = styled.div`
   padding: 1em;
`;
const DownBtn = styled(Button)`
   position: relative;
   overflow: hidden;
   min-width: 250px;
   display: flex;
   justify-content: center;
   align-items: center;
   & .descargar {
      position: absolute;
      background: transparent;
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      color: transparent;
   }
`;

/*const ContentButton = styled(Button)`
   /*  width: 10px;
   opacity: 0; 
   width: 300px;
   display: flex;
   justify-content: center;
   align-items: center;
   & .styledButton {
      background: transparent;
      color: white;
      outline: none;
      border: none;
   }
`;*/
