import React, {Component} from "react";
import styled from "styled-components";
import HeaderBar from "./head/HeaderBar";
import {GlobalContext} from "../providers/GlobalProvider";
import Login from "../auth/Login";
import userPermission from "../constants/userPermissions";
import NoPermission from "./NoPermission";
import AppLoader from "./loader/AppLoader";
import propTypes from "prop-types";

const Content = styled.section`
   width: 100%;
   height: 100vh;
   display: flex;
   flex-direction: column;
   overflow-y: scroll;
   overflow-x: hidden;
   justify-content: flex-start;
   & .allContent {
      height: 90vh;
      width: 100%;
   }
`;
class ContainerComponent extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }
   hasPermission = () => {
      const {path} = this.props.match;
      if (!this.props.userData) return <AppLoader />;
      const {role_id} = this.props.userData;
      const perm = userPermission.find(permi => permi.path === path);
      const hasPermissions = JSON.stringify(perm.permission).indexOf(role_id) > -1;
      if (!hasPermissions) {
         return <NoPermission />;
      }
      return <div className="allContent">{this.props.children}</div>;
   };

   render() {
      const {isLogin, isLoading} = this.props;
      if (isLoading) return <AppLoader />;
      if (!isLogin) return <Login {...this.props} />;
      return (
         <Content>
            <HeaderBar {...this.props} />
            {this.hasPermission()}
         </Content>
      );
   }
}

const Container = props => (
   <GlobalContext.Consumer>
      {({isLogin, userData, isLoading}) => <ContainerComponent userData={userData} isLogin={isLogin} {...props} isLoading={isLoading} />}
   </GlobalContext.Consumer>
);

export default Container;

Container.propTypes = {
   match: propTypes.any.isRequired,
};
