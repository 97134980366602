import React, {Component} from "react";
import styled from "styled-components";
import propTypes from "prop-types";

const ModalContainer = styled.section`
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   transform: ${props => (props.modalIsOpen ? "translateX(0)" : "translateX(-102%)")};
   display: flex;
   justify-content: center;
   align-items: center;
`;
const BackModal = styled.div`
   width: 100%;
   height: 100%;
   background-color: rgba(250, 250, 250, 0.2);
   z-index: 0;
   position: absolute;
   top: 0;
`;
const ModalCard = styled.div`
   width: ${props => props.widthCard || "40%"}
   position: relative;
   border-radius: 0.5em;
   box-shadow: 1px 1px 5px 1px rgba(52, 58, 64, 0.3);
   background-color: white;
   box-sizing: border-box;
   @media screen and (max-width: 90px){
      width: 60%
   }
   @media screen and (max-width: 600px) {
      width: 90%
   }
`;

export default class GlobalModal extends Component {
   constructor(props) {
      super(props);
      this.escFunction = this.escFunction.bind(this);
   }
   escFunction(event) {
      if (event.keyCode === 27) {
         if (!this.props.closeModal) return false;
         this.props.closeModal();
      }
   }
   componentDidMount() {
      document.addEventListener("keydown", this.escFunction, false);
   }
   componentWillUnmount() {
      document.removeEventListener("keydown", this.escFunction, false);
   }
   render() {
      const {modalIsOpen, children, widthCard, closeModal} = this.props;
      return (
         <ModalContainer modalIsOpen={modalIsOpen}>
            <BackModal
               onDoubleClick={() => {
                  closeModal();
               }}
            />
            <ModalCard widthCard={widthCard}>{children}</ModalCard>
         </ModalContainer>
      );
   }
}

GlobalModal.propTypes = {
   modalIsOpen: propTypes.bool,
   children: propTypes.any,
   widthCard: propTypes.string,
   closeModal: propTypes.func,
};

GlobalModal.defaultProps = {
   modalIsOpen: false,
   widthCard: "60%",
};
