import React from "react";
import styled from "styled-components";
/* import {ReactComponent as Check} from "../../../icons/tick.svg";
import {ReactComponent as AddIcon} from "../../../icons/add.svg"; */

const Rect = styled.div`
   width: 100%;
   margin-bottom: 2em;
   @media screen and (max-width: 600px) {
      width: 100%;
   }
`;
const AddBread = styled.div`
   border: none;
   outline: none;
   display: inline-block;
   border-radius: 2em;
   background: ${props => (props.selected ? "rgba(15,151,219, 0.9)" : "rgba(180, 180, 180, 0.5)")};
   color: ${props => (props.selected ? "white" : "rgba(100,100,100,0.8)")};
   padding: 0.5em 1em;
   cursor: pointer;
   margin-left: 0.5em;
   margin-bottom: 0.5em;
   text-transform: capitalize;
   &:active {
      transform: scale(0.8);
   }
`;

export default function ListComponent(props) {
   return (
      <div>
         <Rect>
            <>
               {props.data.map(item => {
                  return (
                     <AddBread selected={props.list.indexOf(item.id) > -1} key={item.id} onClick={() => props.change(item.id)}>
                        {item.name}
                     </AddBread>
                  );
               })}
            </>
         </Rect>
      </div>
   );
}

/* +

<ItemSelectable selected={true}>
               <ContentSelect>
                  <p>Nombre de tema</p>
                  <Check width="20px" height="20px" fill="green" />
               </ContentSelect>
            </ItemSelectable>
 */

/* 
<ItemSelectable selected={true}>
            <ContentSelect>
               <p>Nombre de tema</p>
               <AddIcon width="20px" height="20px" fill="grey" />
            </ContentSelect>
         </ItemSelectable>
         
         */
/* 

<ItemSelectable selected={true}>
            <ContentSelect>
               <p>Nombre de tema</p>
               <Check width="20px" height="20px" fill="green" />
            </ContentSelect>
         </ItemSelectable>*/
