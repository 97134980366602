import {
   GET_MEMBERSHIPS,
   UPDATE_MEMBERSHIP,
   GET_MEMBERSHIP_CODES,
   GET_MEMBERSHIP_CODES_SUCCESS,
   GET_MEMBERSHIP_CODE,
   GET_MEMBERSHIP_CODE_SUCCESS,
} from "../types/MembershipsTypes";

const initialState = {
   fetching: false,
   membershipsList: [],
   codes: [],
   codeData: [],
};

function MembershipReducer(state = initialState, action) {
   switch (action.type) {
      case GET_MEMBERSHIPS:
         return {...state, ...action.payload};
      case UPDATE_MEMBERSHIP:
         return {...state, ...action.payload};
      case GET_MEMBERSHIP_CODES:
         return {...state, fetching: true};
      case GET_MEMBERSHIP_CODES_SUCCESS:
         return {...state, ...action.payload};
      case GET_MEMBERSHIP_CODE:
         return {...state, fetching: true};
      case GET_MEMBERSHIP_CODE_SUCCESS:
         return {...state, ...action.payload};
      default:
         return state;
   }
}

export default MembershipReducer;
