import {getLocalToken} from "./AuthActions";
import Axios from "axios";
import {
   GET_MEMBERSHIPS,
   UPDATE_MEMBERSHIP,
   GET_MEMBERSHIP_CODES,
   GET_MEMBERSHIP_CODES_SUCCESS,
   GET_MEMBERSHIP_CODE_SUCCESS,
   GET_MEMBERSHIP_CODE,
} from "../types/MembershipsTypes";

export const getMembershipsAction = (force = false) => (dispatch, getState) => {
   const {token} = getState().auth;
   const {membershipList} = getState().memberships;

   if (membershipList?.length > 0 && !force) return false;

   if (!token) {
      getLocalToken()(dispatch, getState);
   }
   dispatch({type: GET_MEMBERSHIPS, payload: {fetching: true}});

   Axios.post("membership/list", {token}).then((resp) => {
      dispatch({type: GET_MEMBERSHIPS, payload: {fetching: false, membershipList: resp.data}});
   });
};

export const createMembershipByTranfer = (data) => (dispatch, getState) => {
   const {token} = getState().auth;
   Axios.post("membership/activate_membership", {...data, token}).then(() => {
      getMembershipsAction()(dispatch, getState);
   });
};

export const deactivateMembershipAction = (id_user) => (dispatch, getState) => {
   const {token} = getState().auth;
   const {membershipList} = getState().memberships;

   Axios.post("membership/deactivate_membership", {id_user, token}).then((res) => {
      if (res.status === "success") {
         let nextMembership = membershipList.map((item) => {
            if (item.id_user === id_user) {
               return {...item, status: 0};
            }
            return item;
         });
         dispatch({type: UPDATE_MEMBERSHIP, payload: {membershipList: nextMembership}});
      }
   });
};

export const getMembershipTicketsAction = (force = false) => (dispatch, getState) => {
   const {token} = getState().auth;
   const {codes} = getState().memberships;

   if (codes?.length > 0 && !force) return false;

   if (!token) {
      getLocalToken()(dispatch, getState);
   }
   dispatch({type: GET_MEMBERSHIP_CODES});

   Axios.post("membership/list_membership_tickets", {token}).then((resp) => {
      dispatch({type: GET_MEMBERSHIP_CODES_SUCCESS, payload: {fetching: false, codes: resp.data}});
   });
};

export const createMembershipTicketAction = (code) => (dispatch, getState) => {
   const {token} = getState().auth;
   const {codes} = getState().memberships;

   let toSend = {
      token,
      code,
      type: "universal",
      active: 1,
   };

   Axios.post("membership/create_membership_ticket", {...toSend}).then((resp) => {
      let nextCodes = [...codes, {...toSend, id: resp.data.id}];
      dispatch({type: GET_MEMBERSHIP_CODES_SUCCESS, payload: {codes: nextCodes}});
   });
};

export const getMembershipCodeDetail = (id_ticket) => (dispatch, getState) => {
   const {token} = getState().auth;
   dispatch({type: GET_MEMBERSHIP_CODE, payload: {}});
   Axios.post("membership/list_redeemed_membership_tickets", {id_ticket, token}).then((resp) => {
      dispatch({type: GET_MEMBERSHIP_CODE_SUCCESS, payload: {codeData: resp.data, fetching: false}});
   });
};
