import React from "react";
import DataTable from "react-data-table-component";
import Text from "../../components/Text";
import {useSelector} from "react-redux";
import {AddButton} from "../../components/Button";
import {ItemHeader, ButtonDeactivate} from "../../components/TableComponents";
import HeaderView from "../../components/toViews/HeaderView";
import {Link} from "react-router-dom";
import Color from "../../constants/Colors";

export default function Table({openModal}) {
   const {codes} = useSelector((state) => state.memberships);

   const Columns = [
      {
         name: "Id",
         selector: "id",
         sortable: true,
         maxWidth: "120px",
      },
      {
         name: "Código",
         selector: "code",
         sortable: true,
      },
      {
         name: "",
         cell: (row) => {
            if (row.active) {
               return (
                  <Text align="center" color="green" size="0.9em">
                     Activo
                  </Text>
               );
            }
            return (
               <Text align="center" color="grey" size="0.9em">
                  Desactivado
               </Text>
            );
         },
         sortable: true,
      },
      {
         name: "",
         cell: (row) => {
            return (
               <ButtonDeactivate as={Link} to={`codes/${row.id}`} color={Color.medium}>
                  Lista de usuarios
               </ButtonDeactivate>
            );
         },
         sortable: true,
      },
   ];

   if (codes?.length === 0) return null;

   return (
      <DataTable
         title="Códigos de membresía"
         header={null}
         columns={Columns}
         data={[...codes]}
         subHeader
         subHeaderComponent={<HeaderActions openModal={openModal} />}
         pagination
         paginationComponentOptions={{rowsPerPageText: "Filas por página", rangeSeparatorText: "de"}}
         fixedHeader
         noDataComponent="No hay usuarios registrados"
         style={{display: "block", height: "80%", overflowX: "initial"}}
      />
   );
}

const HeaderActions = ({onFilter, openModal}) => (
   <HeaderView title="Membresías">
      <ItemHeader>
         <AddButton onClick={openModal} />
      </ItemHeader>
   </HeaderView>
);
